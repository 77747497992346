<template>
    <div class="h-screen w-full  ">
    
        <div class="h-screen w-full  ">
            <header class="absolute bg-white left-0 top-0 z-50 w-full">
                <div class="container">
                    <div class="relative -mx-4 flex items-center justify-between">
                        <div class="w-60 max-w-full px-4">
                            <a href="javascript:void(0)" class="block w-full py-5">
    
                                <div v-if="getU().hasOwnProperty('logo_favicon')" @click="$router.push('/')" class="w-full text-center flex flex-wrap content-center justify-start px-10 h-full">
                                    <img class="w-10 h-10 rounded bg-pink-300" :class="getU().color_logo" :src="getU().logo_favicon" :alt="getU().nombre" />
                                </div>
                            </a>
                        </div>
                        
                    </div>
                </div>
            </header>
          
            <div class="relative  justify-between flex flex-wrap content-center h-full w-full  overflow-y-scroll bg-gray-50">
                <div class=" h-full w-full  ">
                  
    
                    <section class="relative flex flex-wrap lg:h-screen lg:items-center w-full bg-gray-50 pt-24">
                        <div class="w-full px-4 py-12 sm:px-6 sm:py-16 lg:w-1/2  lg:px-8 lg:py-24">
                            <div class="mx-auto max-w-lg text-left">
                                <h1 class="px-10 text-2xl md:text-3xl xl:text-5xl lg:text-3xl font-bold sm:text-3xl">Escriba su contraseña!</h1>
    
                                <p class="px-10 mt-4 text-gray-500">
    
                                    Escribe tu apodo para que tus seguidores te encuentren
    
                                </p>
                            </div>
    
                            <form action="" class="mx-auto mt-8 mb-0 max-w-md space-y-4">
                                <div>
                                    <label for="email" class="sr-only">@Usuario</label>
    
                                    <div class="relative">
                                        <input v-model="username" type="text" class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm" placeholder="Nombre de usuario" />
    
                                        <span class="absolute inset-y-0 right-4 inline-flex items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                                 
    
                                <div>
                                    <label for="password" class="sr-only">Correo</label>
                                    <div class="relative">
                                        <input v-model="correo" type="email" class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm" placeholder="Correo" />
    
                                        <span class="absolute inset-y-0 right-4 inline-flex items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                                <div class="flex items-start justify-between">
                                    <p class="text-sm text-left text-gray-500">
    
                                        Al hacer clic en Crear cuenta, aceptas las condiciones de poplink.space <br>
    
                                        <a href="/terminos" class="underline">Términos y condiciones</a>
                                        , confirme que ha leído nuestro
    
                                        <a href="/privacy" class="underline">Aviso de Privacidad</a>
                                        .
    
                                    </p>
    
                                </div>
                                <div class="flex items-center justify-between">
    
                                    <button @click="crearCuenta()" type="button" class=" inline-block w-full rounded-full bg-purple-700 px-5 py-3 text-sm font-medium text-white">
                                        Crear cuenta
                                    </button>
                                </div>
                            </form>
                        </div>
    
                        <div class="relative h-96 w-full sm:h-96 lg:h-full xl:h-full lg:w-1/2 bg-red-100">
                            <img alt="Welcome" src="https://res.cloudinary.com/dgzea9m7q/image/upload/q_auto:low/v1667982138/software/links/pexels-lady-escabia-2299390.jpg" class="absolute inset-0 h-full w-full object-cover" />
                        </div>
                    </section>
    
                </div>
            </div>
    
        </div>
    
    </div>
    </template>
    
    <script>
    import {
        emitter
    } from "@/mitt.js";
    import {
        useConfigStore
    } from '../stores/config'
    
    import generales from "@/functions/generales";
    import infosistem from "@/Services/infosistem";
    import user_s from "@/Services/user";
    import {
        useInfoStore
    } from '../stores/info'
    
    import {
        useUserStore
    } from '../stores/user'
    import {
        useRouter,
        useRoute
    } from 'vue-router'
    export default {
        components: {
    
        },
        mixins: [generales, infosistem],
        data() {
            const user = useUserStore()
            const info = useInfoStore()
            return {
    
                id: 0,
                nombre: '',
                username: '',
                correo: '',
                contrasena: '',
                user:user,
                descripcion: '',
                precio: '',
                boletos: '',
                activo: true,
              
                info,
                data: []
    
            }
    
        },
        setup() {
            const router = useRouter()
            const route = useRoute()
        
    
            const config = useConfigStore();
    
            const salir = () => {
                user.limpiar([]);
                router.push('/');
            }
            return {
                active: 0,
                config,
    
              
    
            }
    
        },
        mounted() {
    
        },
        created() {
            //this.getData()
           // this.get_info();
        },
        methods: {
          getU(){
           let dat = this.info.getAll();
    console.log(dat)
    return dat;
          },
            async crearCuenta() {
                emitter.emit("loading", true)
                let dat = {
                    username: this.username,
                  
                    email: this.correo
                }
                let data = await user_s.REGISTRO_USER(dat).then((response) => {
                    if (response != null && !response.hasOwnProperty('error')) {
    
                        this.user.set(response);
                        this.$router.push('/c/cuenta/secure')
    
                    } else {
                        emitter.emit("alerta_show", response.error);
                    }
                    emitter.emit("loading", false)
                });
            },
            
    
        }
    }
    </script>
    