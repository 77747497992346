



import axios from "axios";

import { useConfigStore } from '../stores/config'
import {
  useUserStore
} from '../stores/user'
const serviceInstance = axios.create({
  //baseURL: "http://127.0.0.1:8000/api",
  //baseURL: "http://192.168.1.5:8000/api",
  baseURL: "https://api-cinp-links.herokuapp.com/api",
  Headers: { Accept: "application/json" },
  timeout: 10000
});
var  _axios_config={
  headers: {
    "Content-Type": "application/json",
    'Authorization': ''

  }
}
const validation=(data) => {
  let text = '';
  for (var [key, value] of Object.entries(data.errors)) {
    text += '<div class="w-full flex flex-wrap text-center justify-center content-center"><span>' + value + '</span> </div><br>' // "a 5", "b 7", "c 9"
  }
  return text;
  //emitter.emit("alerta_show",text);
}
export default {
  data() {
    const config = useConfigStore()
    
    
    
    return { config,  user,
       }

  },

 
   formatrespuesta: (method, data, url, auth,file) => {
    let result =null
    let config = _axios_config;
    console.log(auth)
    if (auth) {
      const user = useUserStore()
     let tok=user.getToken()
     //let us=user.getAll();
       config.headers.Authorization = 'Bearer ' + tok
    }
    switch (method) {
      case 'post':

      if(file){
        config.headers["Content-Type"]='multipart/form-data';
      }else{
        config.headers["Content-Type"]='application/json';
      
      }

        result = serviceInstance.post(url, data, config)
          .then(
            response => {

              return response.data;
            }

          ).catch(function (error) {

            if (error.response) {

              //console.log(error.response.status);


              switch (error.response.status) {
                case 422:
                  return { error: validation(error.response.data) }

                  break;

                case 500:

                  return { error: error.response.data.message }
                  break;
                  case 405:

                    return { error: 'ruta no existente #405' }
                    break;
              }

            } else {

              return null
            }

          });

        return result;

        break;
        case 'getGeneric':
          const instance2 = axios.create({
           // baseURL: "http://127.0.0.1:8000/api",
            //baseURL: "http://192.168.1.5:8000/api",
           // baseURL: "https://rifas-popcing.herokuapp.com/api",
            Headers: { Accept: "application/json" },
            timeout: 10000
          });
        
          result = instance2.get(url)
            .then(
              response => {
  
                return response.data;
              }
  
            ).catch(function (error) {
  
              if (error.response) {
  
                //console.log(error.response.status);
  
  
                switch (error.response.status) {
                  case 422:
                    return { error: validation(error.response.data) }
  
                    break;
  
                  case 500:
  
                    return { error: error.response.data.message }
                    break;
                }
  
              } else {
  
                return null
              }
  
            });
  
          return result;
  
          break;
        
      case 'get':

        result = serviceInstance.get(url, config)
          .then(
            response => {

              return response.data;
            }

          ).catch(function (error) {

            if (error.response) {

              //console.log(error.response.status);


              switch (error.response.status) {
                case 422:
                  return { error: validation(error.response.data) }

                  break;

                case 500:

                  return { error: error.response.data.message }
                  break;
              }

            } else {

              return null
            }

          });

        return result;

        break;
      case 'put':
      
        result = serviceInstance.put(url, data, config)
          .then(
            response => {

              return response.data;
            }
            

          ).catch(function (error) {

            if (error.response) {

              //console.log(error.response.status);


              switch (error.response.status) {
                case 422:
                  return { error: validation(error.response.data) }

                  break;

                case 500:

                  return { error: error.response.data.message }
                  break;
              }

            } else {

              return null
            }

          });

        return result;


        break;
      case 'delete':

        result = serviceInstance.delete(url, config)
          .then(
            response => {

              return response.data;
            }

          ).catch(function (error) {

            if (error.response) {

              //console.log(error.response.status);


              switch (error.response.status) {
                case 422:
                  return { error: validation(error.response.data) }

                  break;

                case 500:

                  return { error: error.response.data.message }
                  break;
              }

            } else {

              return null
            }

          });

        return result;

        break;

      default:
        break;
    }





    return {
      formatrespuesta, validation
      , config, user,
      _axios_config: {
        headers: {
          "Content-Type": "application/json",
          'Authorization': ''

        }
      }
    }

  }












}