<template>
    <div class="w-full flex flex-wrap h-full overflow-y-scroll ">
        <div class="w-full sm:w-full  md:w-full xl:w-3/6 lg:w-3/6 bg-white xs:pl-16 pt-16  sm:pl-0 xs:pt-16 sm:pt-6 md:pt-6 md:pl-0 xl:pl-16 lg:pl-16 lg:pt-0 xl:pt-0   h-full flex flex-wrap text-black">
            <div class="bg-white w-full h-16 border-t-2 border-b-2 border-gray-200">
                <nav class="flex flex-row w-full">
                
                    <a v-for="(row,index) in menu_opciones" v-bind:key="index" @click="$router.push(row.ruta)" :class="activo==row.key?'border-black font-extrabold border-b-2':'font-medium '" class="h-16 text-gray-600 py-4 px-2 block hover:text-black focus:outline-none text-gray-700   ">
                       {{row.nombre}}
                    </a>
                    
                   
                </nav>
            </div>
        </div>
        <div class="sm:w-full w-full md:w-full xl:w-3/6 lg:w-3/6 bg-green-100 h-full flex flex-wrap">
    aaa
        </div>
    
    </div>
    </template>
    
    <script>
    import {
        emitter
    } from "@/mitt.js";
    import {
        useConfigStore
    } from '@/stores/config'
    
    import generales from "@/functions/generales";
    import infosistem from "@/Services/infosistem";
    import user_s from "@/Services/user";
    import {
        useInfoStore
    } from '@/stores/info'
    
    import {
        useUserStore
    } from '@/stores/user'
    import {
        useRouter,
        useRoute
    } from 'vue-router'
    export default {
        components: {
    
        },
        mixins: [generales, infosistem],
        data() {
            const user = useUserStore()
            const info = useInfoStore()
            return {
    
                id: 0,
                activo:0,
                showpass: false,
                user: user,
    
                info,
                data: []
    
            }
    
        },
        setup() {
            const router = useRouter()
            const route = useRoute()
    
            const config = useConfigStore();
    
           
            return {
               
                config,
                menu_opciones:[
                    {key:'links',nombre:'Links',ruta:'/admin'},
                    {key:'apariencia',nombre:'Apariencia',ruta:'/admin/apariencia'},
                    {key:'paquetes',nombre:'paquetes',ruta:'/admin/mejoras'}
                ]
    
            }
    
        },
        mounted() {
            this.activo=this.$route.name;
            if (this.$route.query.hasOwnProperty('poplink')) {
                this.username = this.$route.query.poplink;
            }
            
    
        },
        created() {
            //this.getData()
            // this.get_info();
        },
        methods: {
    
        }
    }
    </script>
    