<template>
<div class="w-full flex flex-wrap h-full  " v-if="getU().hasOwnProperty('foto')">
    <div class="w-full sm:w-full  md:w-full xl:w-4/6 lg:w-4/6 bg-gray-200 xs:pl-16   sm:pl-0    md:pl-16 xl:pl-16 lg:pl-16    h-full flex flex-wrap text-black">
        <div class=" w-full  border-t-2 border-b-2 border-gray-200 h-full">

            <menuTop></menuTop>

            <input type="file" style="display: none" ref="inputPerfil" accept="image/*" @change="onFileChange" />
            
            <div :key="indicelinks" class="w-full h-full xs:px-1 sm:px-1 md:px-1 lg:px-2 xl:px-5 pt-5 pb-20 justify-center flex flex-wrap overflow-y-scroll">

                <div class="w-full px-10 flex flex-wrap content-start ">
                    
                    <p class="text-xl font-extrabold text-gray-600 text-left px-2 mt-4">Foto de perfil</p>
                    <div class=" w-full bg-white px-2 py-2 rounded-lg ">
                        <div class="w-full  flex flex-wrap justify-center px-2 py-2">
                            <div class="w-full flex flex-wrap justify-start">
                                <div class="w-3/6 sm:w-3/6 md:w-2/6 lg:w-2/6 xl:w-2/6  px-2 my-2 ">
                                    <button type="button" @click="initTour()" class="motion-safe:animate-pulse px-2 py-2 w-full text-xs sm:text-xs  md:text-base xl:text-base lg:text-base rounded-full bg-black text-white font-extrabold text-center">Iniciar recorrido
                                        <i class="las la-info-circle motion-safe:animate-ping text-white"></i> </button>
                                </div>
                            </div>
                          
                            <img :ref="'fotoperfil'" @click="onPickFile()" class="h-24 hover:opacity-40 bg-black cursor-pointer  w-24 border-2 ml-1 mb-1 rounded-full border-black  object-cover " :src="getU().foto">

                            <div class="w-full text-center font-extrabold content-center flex flex-wrap justify-center">

                                <div class="flex flex-wrap justify-center content-center relative w-3/6">
                                    <div v-if="editname" class="w-8 h-8 flex flex-wrap justify-center content-center z-30  right-2 absolute">
                                        <i class="las la-pen text-base  "></i>
                                    </div>
                                    <input  :ref="'editnameperfil'" placeholder="@username" v-model="query" @focusin="editname=false" @focusout="editname=true;editarUsername($event)" class="w-full  text-center bg-transparent h-8  text-base h-auto py-1 px-2 text-purple-700 focus:outline-none focus:bg-white focus:border-0 " />

                                </div>

                            </div>
                            <div class="w-full text-center font-extrabold content-center flex flex-wrap justify-center my-3">

                                <div class="flex flex-wrap justify-center content-center relative w-full px-10">
                                    
                                    <div :ref="'copiarlinkperfil'"  @click="compartir('https://toplink.app/'+query)"  class="cursor-pointer w-full align-middle text-center    text-base h-auto py-1 px-2 text-black focus:outline-none  focus:border-0 rounded-full bg-purple-200 flex flex-wrap justify-center content-center text-xl " >
                                        {{'https://toplink.app/'+query}}<i class="las la-share-square text-2xl ml-2"></i></div>

                                </div>

                            </div>
                            <div class="flex flex-wrap justify-center content-center relative w-full">

                                <textarea placeholder="Descripcion" v-model="descripcion" v-on:focusout="editarDescripcion($event)" class="w-full h-36   bg-gray-100 text-left   h-auto py-1 px-2 text-purple-700 focus:outline-none  focus:border-0 " />

                                </div>
                                <div class="flex hidden flex-wrap justify-center content-center relative w-full">

                                    <input placeholder="Mercado secret" v-model="mercado" v-on:focusout="editarMercado($event)" class="w-full h-12   bg-gray-100 text-left   h-auto py-1 px-2 text-purple-700 focus:outline-none  focus:border-0 " />
    
                                    </div>

                            <p  class="text-base text-gray-600 w-full text-left my-2 mt-6 mx-1 font-extrabold">Links sociales.</p>

                            <div :ref="'linksredes'" class="flex flex-wrap mt-2 justify-center content-center relative w-full">
                                   
                                <i class="lab la-instagram absolute  left-3 text-2xl top-1"></i>
                                <input  placeholder="Instagram url" v-model="user_data.instagram" v-on:focusout="editarPreferencias($event)"  class="w-full pl-10 h-26 rounded-lg   bg-gray-100 text-left    py-2  text-purple-700 focus:outline-none  focus:border-0 "/>

                            </div>
                            <div class="flex flex-wrap mt-2 justify-center content-center relative w-full">
                                   
                                <i class="lab la-facebook absolute  left-3 text-2xl top-1"></i>
                                <input  placeholder="Instagram url" v-model="user_data.facebook" v-on:focusout="editarPreferencias($event)"  class="w-full pl-10 h-26 rounded-lg   bg-gray-100 text-left    py-2  text-purple-700 focus:outline-none  focus:border-0 "/>

                            </div>
                            <div class="flex flex-wrap mt-2 justify-center content-center relative w-full">
                                   
                                <i class="lab la-whatsapp absolute  left-3 text-2xl top-1"></i>
                                <input  placeholder="Instagram url" v-model="user_data.whatsapp" v-on:focusout="editarPreferencias($event)"  class="w-full pl-10 h-26 rounded-lg   bg-gray-100 text-left    py-2  text-purple-700 focus:outline-none  focus:border-0 "/>

                            </div>
                           
                            <div class="flex flex-wrap mt-2 justify-center content-center relative w-full">
                                   
                                <i class="lab la-twitter absolute  left-3 text-2xl top-1"></i>
                                <input  placeholder="Twiter url"  v-model="user_data.twiter" v-on:focusout="editarPreferencias($event)"  class="w-full pl-10 h-26 rounded-lg   bg-gray-100 text-left    py-2  text-purple-700 focus:outline-none  focus:border-0 "/>

                            </div>
                            <div class="flex flex-wrap mt-2 justify-center content-center relative w-full">
                                <i class="lab la-snapchat absolute  left-3 text-2xl top-1"></i>
                                <input  placeholder="Snapchat url" v-model="user_data.snapchat" v-on:focusout="editarPreferencias($event)"  class="w-full h-26 rounded-lg   bg-gray-100 text-left    py-2 pl-10 text-purple-700 focus:outline-none  focus:border-0 "/>

                            </div>
                            <div class="flex flex-wrap mt-2 justify-center content-center relative w-full">
                                <i class="lni lni-tiktok absolute  left-3 text-2xl top-1"></i>
                                <input  placeholder="Tiktok url" v-model="user_data.tiktok" v-on:focusout="editarPreferencias($event)"  class="w-full h-26 rounded-lg   bg-gray-100 text-left    py-2 pl-10 text-purple-700 focus:outline-none  focus:border-0 "/>

                            </div>
                            <div class="flex flex-wrap mt-2 justify-center content-center relative w-full">
                                <i class="lab la-telegram absolute  left-3 text-2xl top-1"></i>
                                <input  placeholder="Telegram url" v-model="user_data.telegram" v-on:focusout="editarPreferencias($event)"  class="w-full h-26 rounded-lg   bg-gray-100 text-left    py-2 pl-10 text-purple-700 focus:outline-none  focus:border-0 "/>

                            </div>
                            <div class="flex flex-wrap mt-2 justify-center content-center relative w-full">
                                <i class="lab la-github absolute  left-3 text-2xl top-1"></i>
                                <input  placeholder="Github url" v-model="user_data.github" v-on:focusout="editarPreferencias($event)"  class="w-full h-26 rounded-lg   bg-gray-100 text-left    py-2 pl-10 text-purple-700 focus:outline-none  focus:border-0 "/>

                            </div>

                            <p class="hidden text-base text-gray-600 w-full text-left my-2 mt-6 mx-1 font-extrabold">Marketing.</p>

                            <div class="hidden flex flex-wrap mt-2 justify-center content-center relative w-full">
                                <p class="text-xs text-gray-400 w-full text-left my-2 mx-1"> Agregar Google Analytics para rastrear a sus visitantes.</p>
                             <input  placeholder="Google analitics" v-model="user_data.google_analitics" v-on:focusout="editarPreferencias($event)"  class="w-full h-26 rounded-lg   bg-gray-100 text-left    py-2 px-2 text-purple-700 focus:outline-none  focus:border-0 "/>

                         </div>

                         <div class="hidden flex flex-wrap mt-2 justify-center content-center relative w-full">
                             <p class="text-xs text-gray-400 w-full text-left my-2 mx-1">Agregar Facebook pixel para rastrear a sus visitantes.</p>
                           
                             <input  placeholder="Facebook pixel" v-model="user_data.pixel_facebook" v-on:focusout="editarPreferencias($event)"  class="w-full h-26 rounded-lg   bg-gray-100 text-left    py-2 px-2 text-purple-700 focus:outline-none  focus:border-0 "/>

                         </div>

                         <div class="hidden flex flex-wrap mt-2 justify-center content-center relative w-full">
                             <p class="text-xs text-gray-400 w-full text-left my-2 mx-1">Agregar Tiktok pixel para rastrear a sus visitantes.</p>
                           
                             <input  placeholder="Tiktok pixel" v-model="user_data.pixel_tiktok" v-on:focusout="editarPreferencias($event)"  class="w-full h-26 rounded-lg   bg-gray-100 text-left    py-2 px-2 text-purple-700 focus:outline-none  focus:border-0 "/>

                         </div>

                        </div>
                        
                    </div>

                    <!-- <div class="" v-for="(row,index) in colores_array" v-bind:key="index">
                        <div class="h-10 w-10  mx-1 " :class="row.color+''+(getU().color_fondo_botton==row.color?'border-purple-500 border-3':'border-0 border-black')"></div>
                    </div>-->
                </div>

            </div>

        </div>
        <div class="w-full flex flex-wrap justify-center content-center fixed right-2 bottom-2 z-30">
            <button @click="previewShow=!previewShow" class="block sm:block md:hidden lg:hidden px-6 text-white xl:hidden h-12 w-auto  bg-pink-600   rounded-full flex flex-wrap justify-center contnet-center text-xl">
                <div class="h-full w-full flex flex-wrap justify-center content-center">
                    <i v-if="previewShow" class="las la-times"></i>
                    <i v-else class="las la-eye text-xl mr-1"></i><p  v-if="!previewShow" class="text-base">Preview</p> 
                </div>
    
            </button>
        </div>
    </div>
    <div class="sm:w-full w-full md:w-full xl:w-2/6 lg:w-2/6 bg-gray-100 h-full hidden sm:hidden md:hidden  lg:flex xl:flex flex flex-wrap content-center justify-center ">
        <div style="border-radius: 41px;width:360px!important;height:720px!important" class="shadow  overflow-hidden  h-5/6 border-8 border-black">
            <perfilContent :user="getU()"></perfilContent>

        </div>
    </div>
    <div :class="previewShow?'h-full':'h-0'" class="z-20 fixed top-0 left-0 w-full bg-black bg-opacity-50  flex flex-wrap content-center justify-center ">
        <div style="border-radius: 41px;width:360px!important;" class="shadow  overflow-hidden  h-5/6 -mt-8 border-8 border-black">
            <perfilContent :user="getU()"></perfilContent>

        </div>
    </div>

</div>
</template>

<script>
import {
    emitter
} from "@/mitt.js";
import {
    useConfigStore
} from '@/stores/config'
import generales from "@/functions/generales";
import infosistem from "@/Services/infosistem";
import preferencias from "@/Services/preferencias";
import links from "@/Services/links";
import fotos from "@/Services/fotos";
import users from "@/Services/user";

import menuTop from "@/components/menuTop.vue";
import perfilContent from "@/components/perfilContent.vue";
import {
    createClient
} from 'pexels';
import {
    useInfoStore
} from '@/stores/info'

import {
    useUserStore
} from '@/stores/user'
import {
    useRouter,
    useRoute
} from 'vue-router'
export default {
    components: {
        perfilContent,
        menuTop
    },
    mixins: [generales, infosistem],
    data() {
        const user = useUserStore()
      
        const info = useInfoStore()
        return {
            previewShow:false,
            id: 0,
           
            activo: 0,
            user:user,
            query: '',
            page: 1,
            editname: true,
            refresh:0,
            descripcion: '',
            data_file:null,
            imageUrl:'',
            mercado:'',
            user_data: {
                instagram: '',
                facebook: '',
                telegram: '',
                whatsapp: '',
                tiktok: '',
                github: '',
                snapchat: '',
                twiter: '',
                twitch: '',
                posicion_social_links: '',
                pixel_facebook: '',
                pixel_tiktok: '',
                google_analitics: '',
                favicon: '',
                descripcion_meta: '',
                titulo_meta: '',

            }

        }

    },
    setup() {
      
        const route = useRoute()

        const config = useConfigStore();

        return {

            config,

        }

    },
    mounted() {

        this.query = this.getU().username
        this.mercado = this.getU().tokenmercado
        this.descripcion = this.getU().descripcion
        //this.photos_data = photos;
        this.user_data = this.getUPreferencias();

        this.activo = this.$route.name;

    },
    created() {
        if(!this.user.getAll().hasOwnProperty('name')){
            this.salir()
        }
        //this.getData()
        // this.get_info();
    },
    methods: {
         salir(){
            emitter.emit("loading", false)
            this.user.limpiar();
            this.$router.push('/');
        },
        initTour() {

this.tour = this.$shepherd({
    useModalOverlay: true
});
let th = this;
this.tour.addStep({
    attachTo: {
        element: this.$refs.fotoperfil,
        on: 'top'
    },
    buttons: [{
            action: function () {
                return this.cancel();
            },
            secondary: true,
            text: 'Cancelar',
        },
        {
            action: function () {
              
                    return this.next();
               

            },
            text: 'Siguiente',
        },
    ],
    text: 'Da click aqui para subir tu imagen de perfil'
});
this.tour.addStep({
    attachTo: {
        element: this.$refs.editnameperfil,
        on: 'top'
    },
    buttons: [{
            action: function () {
                return this.back();
            },
            secondary: true,
            text: 'Anterior',
        },
        {
            action: function () {

                return this.next();
            },
            text: 'Siguiente',
        },
    ],
    text: 'Da click aqui para editar tu nombre de usuario'

});
this.tour.addStep({
    attachTo: {
        element: this.$refs.copiarlinkperfil,
        on: 'top'
    },
    buttons: [{
            action: function () {
                return this.back();
            },
            secondary: true,
            text: 'Anterior',
        },
        {
            action: function () {

                return this.next();
            },
            text: 'Siguiente',
        },
    ],
    text: 'Da click aqui para copiar el link de tu perfil, para ingresarlo en tus redes sociales '

});
this.tour.addStep({
    attachTo: {
        element: this.$refs.linksredes,
        on: 'top'
    },
    buttons: [{
            action: function () {
                return this.back();
            },
            secondary: true,
            text: 'Anterior',
        },
        {
            action: function () {

                return this.next();
            },
            text: 'Siguiente',
        },
    ],
    text: 'Ingresa los links de tus redes sociales, al llenarlos aparecera el icono de la red social '

});


this.tour.start();
},
        compartir(url){
           
   if (navigator.share) { 
      navigator.share({
         title: "Toplink",
         text: "",
         url: url, 
      }) 
   }
   return false;

        },

        ismobile() {
            let check = false;
            (function (a) {
                if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
            })(navigator.userAgent || navigator.vendor || window.opera);
            return check;
        },
        getUPreferencias() {
            return this.user.getPreferencias();
        },
        getU() {
            return this.user.getAll();
        },

        isURLValid: function (inputUrl) {

            let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return regex.test(inputUrl)
        },

        async editarPreferencias($event) {
            let el = this.getU();
          
            let dat=this.user_data;
            //dat.id=el.id;

            emitter.emit("loading", true)
           
            let data = await preferencias.EDITAR(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {

                    this.user.setPreferencias(response)
                    this.user_data = this.getUPreferencias();

                } else {
                    emitter.emit("alerta_show", response.error);
                }
                emitter.emit("loading", false)
            });
        },

        async editarUsername($event) {
            let el = this.getU();
          
            let dat={
                username:this.query,
        id:el.id}

            emitter.emit("loading", true)
           
            let data = await users.USERNAME(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {

                    this.user.setUsername(response.username)
                    this.query=response.username;
                    //this.user_data = this.getUPreferencias();

                } else {
                    this.query=this.getU().username;
                    emitter.emit("alerta_show", response.error);
                }
                emitter.emit("loading", false)
            });
        },
        
        async editarMercado($event) {
            let el = this.getU();
          
            let dat={
                mpt:this.mercado,
       }

            emitter.emit("loading", true)
           
            let data = await users.MERCADO(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {

                    this.user.setMercado(response.tokenmercado)
                    this.mercado=response.tokenmercado;
                    //this.user_data = this.getUPreferencias();

                } else {
                    emitter.emit("alerta_show", response.error);
                }
                emitter.emit("loading", false)
            });
        },
        async editarDescripcion($event) {
            let el = this.getU();
          
            let dat={
                descripcion:this.descripcion,
        id:el.id}

            emitter.emit("loading", true)
           
            let data = await users.DESCRIPCION(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {

                    this.user.setDescripcion(response.descripcion)
                    this.descripcion=response.descripcion;
                    //this.user_data = this.getUPreferencias();

                } else {
                    emitter.emit("alerta_show", response.error);
                }
                emitter.emit("loading", false)
            });
        },
        onPickFile() {

            this.$refs.inputPerfil.click();

        },
        async onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;

            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.imageUrl = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.data_file = files[0];
            this.subirarchivo();
            console.log(e)

        },
        async subirarchivo() {
            this.loader = true;
            this.$root.$emit("loading", true);

            emitter.emit("loading", true)
            var formData = new FormData();

            formData.append("doc", this.data_file, );
            let dat = {
                id: this.getU().id,
                data: formData
            }
            await users.IMAGEN(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    this.data_file = null;
                    this.user.setFoto(response.foto);
                    this.refresh=this.refresh+1;

                    //this.links.push(response)

                    //this.productos_data = response
                    //this.$router.push('/app/'); 
                } else {
                    this.data_file = null;
                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });

        },

    }
}
</script>

<style>
.flip-list-move {
    transition: transform 0.5s;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
</style>
