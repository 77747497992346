<template>

    <div id="content_pp" class="w-full h-full">
<img src="src/assets/logo.png">
    </div>

</template>
<script>

import {
    emitter
} from "@/mitt.js";
import {
    useConfigStore
} from '@/stores/config'

import generales from "@/functions/generales";
import infosistem from "@/Services/infosistem";
import user_s from "@/Services/user";
import {
    useInfoStore
} from '@/stores/info'

import {
    useUserStore
} from '@/stores/user'
import {
    useRouter,
    useRoute
} from 'vue-router'
import logo from '@/assets/face2.png'
export default {
    components: {

    },
    mixins: [generales, infosistem],
    data() {
        const user = useUserStore()
        const info = useInfoStore()
        return {

            id: 0,
           
            showpass: false,
            user: user,
           

            info,
            data: []

        }

    },
    setup() {
        const router = useRouter()
        const route = useRoute()

        const config = useConfigStore();

        const salir = () => {
            user.limpiar([]);
            router.push('/');
        }
        return {
            active: 0,
            config,

        }

    },
    mounted() {

      if(this.$route.query.hasOwnProperty('poplink')){
        this.username=this.$route.query.poplink;
      }
        if (this.user.issetToken()) {
            if (this.user.issetCategoria()) {
                this.$router.push('/admin');
            } else {
                this.$router.push('/c/cuenta/categorias');
            }

        }

      this.init();

    },
    created() {
        //this.getData()
        // this.get_info();
    },
    methods: {
       init(){
        const app = new PIXI.Application({ background: '#ffffff' });

var div = document.getElementById('content_pp');

div.appendChild(app.view);

// holder to store the aliens
const aliens = [];

const totalDudes = 20;

for (let i = 0; i < totalDudes; i++) {
    // create a new Sprite that uses the image name that we just generated as its source
    const dude = PIXI.Sprite.from(logo);

    // set the anchor point so the texture is centered on the sprite
    dude.anchor.set(0.5);

    // set a random scale for the dude - no point them all being the same size!
    dude.scale.set(0.4 + Math.random() * 0.1);

    // finally lets set the dude to be at a random position..
    dude.x = Math.random() * app.screen.width;
    dude.y = Math.random() * app.screen.height;

    dude.tint = Math.random() * 0xFeeffe;

    // create some extra properties that will control movement :
    // create a random direction in radians. This is a number between 0 and PI*2 which is the equivalent of 0 - 360 degrees
    dude.direction = Math.random() * Math.PI * 2;

    // this number will be used to modify the direction of the dude over time
    dude.turningSpeed = Math.random() - 0.8;

    // create a random speed for the dude between 2 - 4
    dude.speed = 2 + Math.random() * 2;

    // finally we push the dude into the aliens array so it it can be easily accessed later
    aliens.push(dude);

    app.stage.addChild(dude);
}

// create a bounding box for the little dudes
const dudeBoundsPadding = 100;
const dudeBounds = new PIXI.Rectangle(-dudeBoundsPadding,
    -dudeBoundsPadding,
    app.screen.width + dudeBoundsPadding * 2,
    app.screen.height + dudeBoundsPadding * 2);

app.ticker.add(() => {
    // iterate through the dudes and update their position
    for (let i = 0; i < aliens.length; i++) {
        const dude = aliens[i];
        dude.direction += dude.turningSpeed * 0.01;
        dude.x += Math.sin(dude.direction) * dude.speed;
        dude.y += Math.cos(dude.direction) * dude.speed;
        dude.rotation = -dude.direction - Math.PI / 2;

        // wrap the dudes by testing their bounds...
        if (dude.x < dudeBounds.x) {
            dude.x += dudeBounds.width;
        } else if (dude.x > dudeBounds.x + dudeBounds.width) {
            dude.x -= dudeBounds.width;
        }

        if (dude.y < dudeBounds.y) {
            dude.y += dudeBounds.height;
        } else if (dude.y > dudeBounds.y + dudeBounds.height) {
            dude.y -= dudeBounds.height;
        }
    }
});
       }

    }
}
</script>
