<template>
<div class="w-full flex flex-wrap h-full overflow-hidden " v-if="getU().hasOwnProperty('foto')">
    <div class="w-full sm:w-full  md:w-full xl:w-4/6 lg:w-4/6 bg-gray-200 xs:pl-16   sm:pl-0    md:pl-16 xl:pl-16 lg:pl-16    h-full flex flex-wrap text-black">
        <div class=" w-full  border-t-2 border-b-2 border-gray-200 h-full">

            <menuTop></menuTop>

            <input type="file" style="display: none" ref="fileInput" accept="image/*" @change="onFileChange" />
            <div :key="getU()" class="w-full h-full px-5 justify-center flex flex-wrap overflow-y-scroll content-start py-5 pb-20">

                <draggable @change="logs" class="flex flex-wrap w-full justify-center  " v-model="links_array" item-key="posicion" handle=".handle" :component-data="{ tag: 'div', name: 'flip-list', type: 'transition' }" @end="finalizoCambio">

                    <template #item="{ element }">
                        <div :key="element.id" class="h-36 w-10/12  sm:w-10/12 md:w-full lg:w-8/12 xl:w-8/12  my-2 handle">

                            <div class="w-full h-36 rounded-xl overflow-hidden bg-white flex flex-wrap" :class="element.tipo=='url'?'':'border-2 border-blue-500'">

                                <div class="w-1/12 bg-gray-100 h-full border-r-1 cursor-pointer border-gray-100 handle flex flex-wrap justify-center content-center text-gray-300" :class="element.tipo=='url'?'bg-gray-100':'bg-blue-500'">
                                    <i v-if="element.tipo=='grupo'" class="lab la-telegram text-2xl"></i>

                                    <i v-if="element.tipo=='url'" class="las la-braille text-2xl"></i>
                                </div>
                                <div class="w-11/12  border-gray-100 handle flex flex-wrap justify-center content-center text-gray-300">

                                    <div class="w-3/12 py-2">
                                        <div v-if="(element.icono==''&&element.imagen=='')||(element.icono==null&&element.imagen==null)" class="w-full flex flex-wrap justify-center content-center h-full">
                                            <div @click="onPickFile(element)" class="cursor-pointer bg-blue-200 h-10 w-10 rounded-full flex flex-wrap justify-center content-center">
                                                <i class="las la-image text-2xl text-black"></i>
                                            </div>
                                            <div class="cursor-pointer ml-1 bg-green-200 h-10 w-10 rounded-full flex flex-wrap justify-center content-center">
                                                <el-popover placement="top-start" title="Icono de link" :width="200" trigger="hover" popper-class="bg-black" content="Seleccione un icono para aparecer al inicio de su link"><template #reference>
                                                        <i @click="iconsvisible=true;id=element" class="las la-icons text-2xl text-black"></i>
                                                    </template>
                                                </el-popover>
                                            </div>

                                        </div>
                                        <div v-if="element.icono!=''&&element.icono!=null" class="w-full  flex flex-wrap justify-center content-center h-full">
                                            <div class="cursor-pointer ml-1 bg-green-200 h-12 w-12 rounded-full text-black flex flex-wrap justify-center content-center relative">
                                                <div @click="element.imagen='';element.icono='';editarEnlace($event,element)" class=" absolute  w-full flex flex-wrap justify-end">
                                                    <div class="w-4 h-4 rounded-full sticky static bg-red-500  text-white cursor-pointer text-xs font-extrabold">x</div>
                                                </div>
                                                <i :class="element.icono" class="text-2xl"></i>
                                            </div>
                                        </div>
                                        <div v-if="element.imagen!=''&&element.imagen!=null" class="w-full flex h-full flex-wrap justify-center content-center">
                                            <div class="w-12 h-12 relative">
                                                <div @click="element.imagen='';element.icono='';editarEnlace($event,element)" class="absolute  w-full flex flex-wrap justify-end">
                                                    <div class="w-4 h-4 rounded-full sticky static bg-red-500  text-white cursor-pointer text-xs font-extrabold">x</div>
                                                </div>
                                                <img v-bind:key="element.imagen" @click="onPickFile(element)" class="rounded-full h-full w-full border-2 border-black h-10 w-10 object-cover " :src="element.imagen">

                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-7/12 flex flex-wrap justify-start px-2 content-center">
                                        <div class="w-full flex flex-wrap content-center">
                                            <i class="las la-pen text-base  w-1/12"></i>
                                            <input :ref="'titulolink'+element.posicion" placeholder="Titulo" v-model="element.nombre" class="w-11/12 text-xs h-auto py-1 px-2 text-purple-700 focus:outline-none focus:bg-white focus:border-0 ">

                                        </div>
                                        <div v-if="element.tipo=='url'||element.tipo=='producto'" class="w-full flex flex-wrap content-center">
                                            <i class="las la-pen text-base w-1/12"></i>
                                            <input :ref="'urllink'+element.posicion" placeholder="URL" v-model="element.url" @focusin="element.edit_url=false" class="w-11/12 text-xs  h-auto text-purple-400 py-1 px-2  focus:outline-none focus:bg-white focus:border-0 ">

                                        </div>
                                        <div v-if="element.tipo=='grupo'" class="w-full flex flex-wrap content-center">
                                            <i class="las la-pen text-base w-1/12"></i>
                                            <input :ref="'urllink'+element.posicion" placeholder="URL INVITACIÓN" v-model="element.url" @focusin="element.edit_url=false" class="w-11/12 text-xs  h-auto text-purple-400 py-1 px-2  focus:outline-none focus:bg-white focus:border-0 ">

                                        </div>
                                        <div v-if="element.tipo=='grupo'" class="w-full flex flex-wrap content-center">
                                            <i class="las la-pen text-base w-1/12"></i>
                                            <input placeholder="Id grupo telegram" v-model="element.grupoid" class="w-11/12 text-xs  h-auto text-purple-400 py-1 px-2  focus:outline-none focus:bg-white focus:border-0 ">

                                        </div>
                                        <div v-if="element.tipo=='grupo'||element.tipo=='producto'" class="w-full flex flex-wrap content-center ">
                                            <i class="las la-pen text-base w-1/12"></i>
                                            <input placeholder="Precio" type="number" v-model="element.precio" class="w-11/12 text-xs  h-auto text-green-400 font-extrabold py-1 px-2  border-0 focus:outline-none focus:bg-white focus:border-0 ">

                                        </div>
                                        <!--<div class="error text-red-500 text-xs" v-if="!isURLValid(element.url)">La url no tiene el formato correcto</div>-->

                                    </div>
                                    <div class="w-2/12 hidden content-center  flex flex-wrap justify-center flex flex-wrap justify-center content-center h-full">

                                    </div>
                                    <div class="w-2/12 content-center  flex flex-wrap justify-center flex flex-wrap justify-center content-center h-full">

                                        <!-- <div class="cursor-pointer  h-6 w-full rounded-full flex flex-wrap justify-center content-center text-red-500">
                                           <el-popover v-if="element.tipo=='url'" placement="top-start" title="Contenido explicito" :width="200" trigger="hover" popper-class="bg-black" content="Al activar, a los usuarios les aparecera una alerta de contendo sensible"><template #reference>
                                                     <i class="las la-fire-alt text-xl" @click="element.explicito=!element.explicito;editarEnlace($event,element)" :class="element.explicito?'text-red-500':'text-gray-400'"></i>
                                                </template>
                                            </el-popover>
                                            <el-popover v-if="element.tipo=='grupo'&&element.idpago!=''&&element.idpago!=null" placement="top-start" title="Grupo activo" :width="200" trigger="hover" popper-class="bg-black" content="Pago al corriente este grupo esta activo"><template #reference>
                                                <i class="las la-money-bill-wave-alt  text-green-500 text-xl"></i>  
                                            </template>
                                        </el-popover>
                                        <el-popover v-if="element.tipo=='grupo'&&(element.idpago==''||element.idpago==null)" placement="top-start" title="Grupo inactivo" :width="200" trigger="hover" popper-class="bg-black" content="Pagar para hacer visible este grupo al publico"><template #reference>
                                            
                                            <i   class="las la-dollar-sign text-red-500 text-xl" ></i>
                                        </template>
                                    </el-popover>

                                            
                                        </div>-->
                                        <div v-if="element.tipo=='grupo'&&(element.idpago!=''||element.idpago!=null)" class="cursor-pointer  h-6 w-full rounded-full flex flex-wrap justify-center content-center text-red-500">
                                            <i class="las la-users text-2xl text-black"></i>
                                        </div>
                                        <div v-if="element.tipo=='grupo'&&(element.idpago!=''||element.idpago!=null)" class="cursor-pointer  h-6 w-full rounded-full flex flex-wrap justify-center content-center text-red-500">
                                            <i class="las la-paper-plane text-2xl text-black"></i>
                                        </div>
                                        <div :ref="'guardarlink'+element.posicion" @click="editarEnlace($event,element)" class="cursor-pointer  h-12 w-full rounded-full flex flex-wrap justify-center content-center text-red-500">

                                            <i class="las la-save text-2xl text-blue-500"></i>
                                        </div>
                                        <div :ref="'eliminarlink'+element.posicion" v-if="element.tipo=='url'||element.tipo=='producto'" @click="eliminarEnlace($event,element)" class="cursor-pointer  h-12 w-full rounded-full flex flex-wrap justify-center content-center text-red-500">
                                            <i class="las la-trash-alt text-2xl text-red-500"></i>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </template>

                    <template #header>
                        <div class="w-full sm:w-full md:w-full lg:w-10/12 xl:w-10/12 flex flex-wrap mb-4 content-start justify-start">
                            <div class="w-3/6 sm:w-3/6 md:w-2/6 lg:w-2/6 xl:w-2/6  px-2 ">
                                <button type="button" @click="initTour()" class="motion-safe:animate-pulse px-2 py-2 w-full text-xs sm:text-xs  md:text-base xl:text-base lg:text-base rounded-full bg-black text-white font-extrabold text-center">Iniciar recorrido
                                    <i class="las la-info-circle motion-safe:animate-ping text-white"></i> </button>
                            </div>
                            <div class="w-3/6 sm:w-3/6 md:w-2/6 lg:w-2/6 xl:w-2/6 px-2">
                                <button @click="agregarEnlace('url')" type="button" ref="addlink" class="  px-2 py-2 w-full text-xs sm:text-xs  md:text-base xl:text-base lg:text-base rounded-full bg-pink-600 text-white font-extrabold text-center">Agregar enlace</button>
                            </div>
                            <div class="w-3/6 sm:w-3/6 md:w-2/6 lg:w-2/6 xl:w-2/6 px-2 my-1 hidden">
                                <button type="button" @click="agregarEnlace('producto')" class="px-2 py-2 w-full text-xs sm:text-xs  md:text-base xl:text-base lg:text-base rounded-full bg-green-500 text-black font-extrabold text-center">Agregar producto</button>
                            </div>
                            <div class="w-3/6 px-2 hidden">
                                <button type="button" @click="agregarEnlace('grupo')" class="px-2 py-2 w-full text-xs rounded-full bg-green-500 text-black font-extrabold text-center">Agregar grupo</button>
                            </div>
                        </div>

                    </template>
                </draggable>
            </div>
            <div :key="getU()" v-if="false" class="w-full  h-full px-5 justify-center flex flex-wrap overflow-y-scroll content-start py-5 pb-20">

                <template v-for="(element,index) in links_array" :key="index">
                    <div class="h-36 xs:w-full sm:w-full md:w-full lg:w-8/12 xl:w-8/12  my-2 ">

                        <div class="w-full h-36 rounded-xl overflow-hidden bg-white flex flex-wrap" :class="element.tipo=='url'?'':'border-2 border-blue-500'">

                            <div class="w-1/12 bg-gray-100 h-full border-r-1 cursor-pointer border-gray-100 handle flex flex-wrap justify-center content-center text-gray-300" :class="element.tipo=='url'?'bg-gray-100':'bg-blue-500'">
                                <i v-if="element.tipo=='grupo'" class="lab la-telegram text-2xl"></i>

                                <i v-if="element.tipo=='url'" class="las la-braille text-2xl"></i>
                            </div>
                            <div class="w-11/12  border-gray-100 handle flex flex-wrap justify-center content-center text-gray-300">

                                <div class="w-3/12 py-2">
                                    <div v-if="(element.icono==''&&element.imagen=='')||(element.icono==null&&element.imagen==null)" class="w-full flex flex-wrap justify-center content-center h-full">
                                        <div @click="onPickFile(element)" class="cursor-pointer bg-blue-200 h-10 w-10 rounded-full flex flex-wrap justify-center content-center">
                                            <i class="las la-image text-2xl text-black"></i>
                                        </div>
                                        <div class="cursor-pointer ml-1 bg-green-200 h-10 w-10 rounded-full flex flex-wrap justify-center content-center">
                                            <el-popover placement="top-start" title="Icono de link" :width="200" trigger="hover" popper-class="bg-black" content="Seleccione un icono para aparecer al inicio de su link"><template #reference>
                                                    <i @click="iconsvisible=true;id=element" class="las la-icons text-2xl text-black"></i>
                                                </template>
                                            </el-popover>
                                        </div>

                                    </div>
                                    <div v-if="element.icono!=''&&element.icono!=null" class="w-full  flex flex-wrap justify-center content-center h-full">
                                        <div class="cursor-pointer ml-1 bg-green-200 h-12 w-12 rounded-full text-black flex flex-wrap justify-center content-center relative">
                                            <div @click="element.imagen='';element.icono='';editarEnlace($event,element)" class=" absolute  w-full flex flex-wrap justify-end">
                                                <div class="w-4 h-4 rounded-full sticky static bg-red-500  text-white cursor-pointer text-xs font-extrabold">x</div>
                                            </div>
                                            <i :class="element.icono" class="text-2xl"></i>
                                        </div>
                                    </div>
                                    <div v-if="element.imagen!=''&&element.imagen!=null" class="w-full flex h-full flex-wrap justify-center content-center">
                                        <div class="w-12 h-12 relative">
                                            <div @click="element.imagen='';element.icono='';editarEnlace($event,element)" class="absolute  w-full flex flex-wrap justify-end">
                                                <div class="w-4 h-4 rounded-full sticky static bg-red-500  text-white cursor-pointer text-xs font-extrabold">x</div>
                                            </div>
                                            <img v-bind:key="element.imagen" @click="onPickFile(element)" class="rounded-full h-full w-full border-2 border-black h-10 w-10 object-cover " :src="element.imagen">

                                        </div>
                                    </div>
                                </div>
                                <div class="w-5/12 flex flex-wrap justify-start px-2 content-center">
                                    <div class="w-full flex flex-wrap content-center">
                                        <i class="las la-pen text-base  w-1/12"></i>
                                        <input placeholder="Titulo" v-model="element.nombre" class="w-11/12 text-xs h-auto py-1 px-2 text-purple-700 focus:outline-none focus:bg-white focus:border-0 ">

                                    </div>
                                    <div v-if="element.tipo=='url'" class="w-full flex flex-wrap content-center">
                                        <i class="las la-pen text-base w-1/12"></i>
                                        <input placeholder="URL" v-model="element.url" @focusin="element.edit_url=false" class="w-11/12 text-xs  h-auto text-purple-400 py-1 px-2  focus:outline-none focus:bg-white focus:border-0 ">

                                    </div>
                                    <div v-if="element.tipo=='grupo'" class="w-full flex flex-wrap content-center">
                                        <i class="las la-pen text-base w-1/12"></i>
                                        <input placeholder="URL INVITACIÓN" v-model="element.url" @focusin="element.edit_url=false" class="w-11/12 text-xs  h-auto text-purple-400 py-1 px-2  focus:outline-none focus:bg-white focus:border-0 ">

                                    </div>
                                    <div v-if="element.tipo=='grupo'" class="w-full flex flex-wrap content-center">
                                        <i class="las la-pen text-base w-1/12"></i>
                                        <input placeholder="Id grupo telegram" v-model="element.grupoid" class="w-11/12 text-xs  h-auto text-purple-400 py-1 px-2  focus:outline-none focus:bg-white focus:border-0 ">

                                    </div>
                                    <div v-if="element.tipo=='grupo'" class="w-full flex flex-wrap content-center ">
                                        <i class="las la-pen text-base w-1/12"></i>
                                        <input placeholder="Costo de acceso al grupo" type="number" v-model="element.precio" class="w-11/12 text-xs  h-auto text-green-400 font-extrabold py-1 px-2  border-0 focus:outline-none focus:bg-white focus:border-0 ">

                                    </div>
                                    <!--<div class="error text-red-500 text-xs" v-if="!isURLValid(element.url)">La url no tiene el formato correcto</div>-->

                                </div>
                                <div class="w-2/12  content-center  flex flex-wrap justify-center flex flex-wrap justify-center content-center h-full">

                                    <div @click="editarEnlace($event,element)" class="cursor-pointer  h-12 w-full rounded-full flex flex-wrap justify-center content-center text-red-500">

                                        <i class="las la-save text-2xl text-black"></i>
                                    </div>
                                </div>
                                <div class="w-2/12 content-center  flex flex-wrap justify-center flex flex-wrap justify-center content-center h-full">

                                    <div class="cursor-pointer  h-6 w-full rounded-full flex flex-wrap justify-center content-center text-red-500">
                                        <el-popover v-if="element.tipo=='url'" placement="top-start" title="Contenido explicito" :width="200" trigger="hover" popper-class="bg-black" content="Al activar, a los usuarios les aparecera una alerta de contendo sensible"><template #reference>
                                                <!-- <el-switch v-model="element.explicito" class="ml-2" @change="" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #eeeeee" />-->
                                                <i class="las la-fire-alt text-xl" @click="element.explicito=!element.explicito;editarEnlace($event,element)" :class="element.explicito?'text-red-500':'text-gray-400'"></i>
                                            </template>
                                        </el-popover>
                                        <el-popover v-if="element.tipo=='grupo'&&element.idpago!=''&&element.idpago!=null" placement="top-start" title="Grupo activo" :width="200" trigger="hover" popper-class="bg-black" content="Pago al corriente este grupo esta activo"><template #reference>
                                                <i class="las la-money-bill-wave-alt  text-green-500 text-xl"></i>
                                            </template>
                                        </el-popover>
                                        <el-popover v-if="element.tipo=='grupo'&&(element.idpago==''||element.idpago==null)" placement="top-start" title="Grupo inactivo" :width="200" trigger="hover" popper-class="bg-black" content="Pagar para hacer visible este grupo al publico"><template #reference>

                                                <i class="las la-dollar-sign text-red-500 text-xl"></i>
                                            </template>
                                        </el-popover>

                                    </div>
                                    <div v-if="element.tipo=='grupo'&&(element.idpago!=''||element.idpago!=null)" class="cursor-pointer  h-6 w-full rounded-full flex flex-wrap justify-center content-center text-red-500">
                                        <i class="las la-users text-2xl text-black"></i>
                                    </div>
                                    <div v-if="element.tipo=='grupo'&&(element.idpago!=''||element.idpago!=null)" class="cursor-pointer  h-6 w-full rounded-full flex flex-wrap justify-center content-center text-red-500">
                                        <i class="las la-paper-plane text-2xl text-black"></i>
                                    </div>
                                    <div v-if="element.tipo=='url'||element.tipo=='producto'" @click="eliminarEnlace($event,element)" class="cursor-pointer  h-12 w-full rounded-full flex flex-wrap justify-center content-center text-red-500">
                                        <i class="las la-trash-alt text-2xl text-black"></i>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </template>
            </div>

        </div>

        <div class="w-full flex flex-wrap justify-center content-center fixed right-2 bottom-2 z-30">
            <button @click="previewShow=!previewShow" class="block sm:block md:hidden lg:hidden px-6 text-white xl:hidden h-12 w-auto  bg-pink-600   rounded-full flex flex-wrap justify-center contnet-center text-xl">
                <div class="h-full w-full flex flex-wrap justify-center content-center">
                    <i v-if="previewShow" class="las la-times"></i>
                    <i v-else class="las la-eye text-xl mr-1"></i>
                    <p v-if="!previewShow" class="text-base">Preview</p>
                </div>

            </button>
        </div>

    </div>
    <div class="sm:w-full w-full md:w-full xl:w-2/6 lg:w-2/6 bg-gray-100 h-full hidden sm:hidden md:hidden lg:flex xl:flex    flex-wrap content-center justify-center ">
        <div style="border-radius: 41px;width:390px!important;height:720px!important" class="flex shadow  overflow-hidden  h-5/6 border-8 border-black">
            <perfilContent :user="getU()"></perfilContent>

        </div>
    </div>

    <div :class="previewShow?'h-full':'h-0'" class="z-20 fixed top-0 left-0 w-full bg-black bg-opacity-50  flex flex-wrap content-center justify-center ">
        <div style="border-radius: 41px;width:360px!important;" class="shadow  overflow-hidden  h-5/6 border-8 -mt-8 border-black">
            <perfilContent :user="getU()"></perfilContent>

        </div>
    </div>

    <el-dialog v-model="iconsvisible" title="Seleccione un icono" width="50%">
        <input v-model="searchicon" class="w-full h-12 bg-gray-100 px-2" placeholder="Buscar icono">
        <div class="w-full h-48 overflow-y-scroll flex flex-wrap justify-center content-start">

            <template v-for="(row,index) in iconos" v-bind:key="index">
                <div class="w-10 h-10 flex flex-wrap justify-center content-center cursor-pointer hover:bg-gray-200 rounded " v-if="verifiedIcon(row)" @click="id.icono=row.icon;id.imagen='';editarEnlace($event,id);">
                    <i class="text-2xl" :class="row.icon"></i>
                </div>
            </template>

        </div>

        <template #footer>
            <span class="dialog-footer">

                <el-button type="primary" @click="iconsvisible = false">
                    Cancelar
                </el-button>
            </span>
        </template>
    </el-dialog>

    <el-dialog v-model="iconsvisible" title="Seleccione un icono" width="50%">
        <input v-model="searchicon" class="w-full h-12 bg-gray-100 px-2" placeholder="Buscar icono">
        <div class="w-full h-48 overflow-y-scroll flex flex-wrap justify-center content-start">

            <template v-for="(row,index) in iconos" v-bind:key="index">
                <div class="w-10 h-10 flex flex-wrap justify-center content-center cursor-pointer hover:bg-gray-200 rounded " v-if="verifiedIcon(row)" @click="id.icono=row.icon;id.imagen='';editarEnlace($event,id);">
                    <i class="text-2xl" :class="row.icon"></i>
                </div>
            </template>

        </div>

        <template #footer>
            <span class="dialog-footer">

                <el-button type="primary" @click="iconsvisible = false">
                    Cancelar
                </el-button>
            </span>
        </template>
    </el-dialog>

</div>
</template>

<script>
import {
    emitter
} from "@/mitt.js";
import {
    useConfigStore
} from '@/stores/config'
import draggable from 'vuedraggable'
import generales from "@/functions/generales";
import icons from "@/functions/icons";
import infosistem from "@/Services/infosistem";
import links from "@/Services/links";

import menuTop from "@/components/menuTop.vue";
import perfilContent from "@/components/perfilContent.vue";
import {
    useInfoStore
} from '@/stores/info'

import {
    useUserStore
} from '@/stores/user'
import {
    useRouter,
    useRoute
} from 'vue-router'
export default {
    components: {
        draggable,
        perfilContent,
        menuTop
    },
    mixins: [generales, infosistem, icons],
    data() {
        const user = useUserStore()
        const info = useInfoStore()
        return {
            searchicon: '',
            iconsvisible: false,
            id: 0,
            previewShow: false,
            showpass: false,
            user: user,
            indicelinks: false,
            info,
            data: [],
            drag: false,

            link: {
                id: 0
            },
            data_file: null,

            links_array: []

        }

    },
    setup() {
        const router = useRouter()
        const route = useRoute()

        const config = useConfigStore();

        const salir = () => {
            user.limpiar([]);
            router.push('/');
        }
        return {

            config,

        }

    },
    mounted() {

        this.activo = this.$route.name;
        if (this.$route.query.hasOwnProperty('poplink')) {
            this.username = this.$route.query.poplink;
        }
        if (this.user.issetToken()) {
            if (this.user.issetCategoria()) {
                this.$router.push('/admin');
            } else {
                this.$router.push('/c/cuenta/categorias');
            }

        }
        this.getEnlaces();

    },
    created() {
        if(!this.user.getAll().hasOwnProperty('name')){
            this.salir()
        }
        //this.getData()
        // this.get_info();
    },
    methods: {
        salir(){
            emitter.emit("loading", false)
            this.user.limpiar();
            this.$router.push('/');
        },
        initTour() {

            this.tour = this.$shepherd({
                useModalOverlay: true
            });
            let th = this;
            this.tour.addStep({
                attachTo: {
                    element: this.$refs.addlink,
                    on: 'top'
                },
                buttons: [{
                        action: function () {
                            return this.cancel();
                        },
                        secondary: true,
                        text: 'Cancelar',
                    },
                    {
                        action: function () {
                            if (th.links_array.length > 0) {
                                return this.next();
                            }

                        },
                        text: 'Siguiente',
                    },
                ],
                text: 'Da click aqui para agregar un nuevo link'
            });
            this.tour.addStep({
                attachTo: {
                    element: this.$refs.titulolink1,
                    on: 'top'
                },
                buttons: [{
                        action: function () {
                            return this.back();
                        },
                        secondary: true,
                        text: 'Anterior',
                    },
                    {
                        action: function () {

                            return this.next();
                        },
                        text: 'Siguiente',
                    },
                ],
                text: 'Da click aqui para agregar un titulo a tu link'

            });
            this.tour.addStep({
                attachTo: {
                    element: this.$refs.urllink1,
                    on: 'top'
                },
                buttons: [{
                        action: function () {
                            return this.back();
                        },
                        secondary: true,
                        text: 'Anterior',
                    },
                    {
                        action: function () {

                            return this.next();
                        },
                        text: 'Siguiente',
                    },
                ],
                text: 'da click aqui para agregar el enlace a tu sitio, red social, whatsapp '

            });
            this.tour.addStep({
                attachTo: {
                    element: this.$refs.guardarlink1,
                    on: 'top'
                },
                buttons: [{
                        action: function () {
                            return this.back();
                        },
                        secondary: true,
                        text: 'Anterior',
                    },
                    {
                        action: function () {

                            return this.next();
                        },
                        text: 'Siguiente',
                    },
                ],
                text: 'Da click aqui para guardar los cambios de tu link '

            });
            this.tour.addStep({
                attachTo: {
                    element: this.$refs.eliminarlink1,
                    on: 'top'
                },
                buttons: [{
                        action: function () {
                            return this.back();
                        },
                        secondary: true,
                        text: 'Anterior',
                    },
                    {
                        action: function () {

                            return this.cancel();
                        },
                        text: 'Finalizar',
                    },
                ],
                text: 'Da click aqui para eliminar el link '

            });

            this.tour.start();
        },
        verifiedIcon(row) {
            return String(row.icon).search(this.searchicon) != -1 || this.searchicon == '';
        },
        logs(dat) {
            console.log(dat, '----p---')
        },
        async finalizoCambio(event) {
            this.drag = false
            console.log(event, '-----orden---')
            console.log(this.links_array, '----array--')
            //console.log(event.item._underlying_vm_,'---tem---')
            //console.log(event.newIndex,'-----index old---')
            //console.log(event.oldIndex,'-----new index---')

            if (event.newIndex != event.oldIndex) {

            }

            emitter.emit("loading", true)
            let dat = {
                links: this.links_array
            }

            let data = await links.REORDENAR(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    this.user.setLinks(response);
                    /* this.links_array.forEach(element => {
                         if (element.id == response.id) {
                             element.icono = response.icono;
                             element.imagen = response.imagen;
                             element.url = response.url;
                             element.nombre = response.nombre;
                             element.explicito = response.explicito;
                             //element = response

                         }
                     });*/
                    this.links_array = response;

                } else {
                    emitter.emit("alerta_show", response.error);
                }
                emitter.emit("loading", false)
            });
        },
        checkMove(evt) {
            return (evt.draggedContext.element.name !== 'apple');
        },
        getULinks() {
            return this.user.getAllLinks();
        },
        getU() {
           let dat= this.user.getAll();
           
                return dat;
            
            
        },
        log(id) {

            console.log(id)
            this.$router.push(id)
        },
        ismobile() {
            let check = false;
            (function (a) {
                if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
            })(navigator.userAgent || navigator.vendor || window.opera);
            return check;
        },
        getImage(url) {
            if (!this.ismobile()) {
                return url + '?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800'
            } else {
                return url + '?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200'
            }

        },
        isURLValid: function (inputUrl) {
            let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return regex.test(inputUrl)
        },

        async eliminarEnlace($event, el) {
            console.log(el, '--editar entre-')

            emitter.emit("loading", true)
            let dat = {
                id: el.id,

            }
            let data = await links.ELIMINAR(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    this.user.eliminarLink(el);

                    this.links_array = this.getULinks();

                } else {
                    emitter.emit("alerta_show", response.error);
                }
                emitter.emit("loading", false)
            });
        },

        async editarEnlace($event, el) {
            console.log(el, '--editar entre-')

            emitter.emit("loading", true)
            let dat = {
                id: el.id,
                nombre: el.nombre,
                descripcion: el.descripcion,
                url: el.url,
                grupoid: el.grupoid,
                precio: el.precio,
                imagen: el.imagen,
                icono: el.icono,
                explicito: el.explicito

            }
            let data = await links.EDITAR(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    this.user.setLinks(response);
                    /* this.links_array.forEach(element => {
                         if (element.id == response.id) {
                             element.icono = response.icono;
                             element.imagen = response.imagen;
                             element.url = response.url;
                             element.nombre = response.nombre;
                             element.explicito = response.explicito;
                             //element = response

                         }
                     });*/
                    this.links_array = response;
                    this.iconsvisible = false;

                } else {
                    emitter.emit("alerta_show", response.error);
                }
                emitter.emit("loading", false)
            });
        },
        async agregarEnlace(tipo) {
            console.log('crearclick---')
            emitter.emit("loading", true)
            let dat = {
                tipo: tipo

            }
            let data = await links.CREAR(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    this.user.setLinks(response)
                    this.links_array = response;
                    //this.links_array.push(response)

                } else {
                    emitter.emit("alerta_show", response.error);
                }
                emitter.emit("loading", false)
            });
        },
        async getEnlaces() {

            emitter.emit("loading", true)

            let data = await links.GET().then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    this.user.setLinks(response)
                    this.links_array = response;

                } else {
                    emitter.emit("alerta_show", response.error);
                }
                emitter.emit("loading", false)
            });
        },

        onPickFile(link) {
            this.link = link
            this.$refs.fileInput.click();
            //this.$refs.fileInput.click()
        },
        async onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;

            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.imageUrl = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.data_file = files[0];
            this.subirarchivo();
            console.log(e)

        },
        async subirarchivo() {
            this.loader = true;
            this.$root.$emit("loading", true);

            emitter.emit("loading", true)
            var formData = new FormData();

            formData.append("doc", this.data_file, );
            let dat = {
                id: this.link.id,
                data: formData
            }
            await links.IMAGEN(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    this.data_file = null;

                    this.links_array.forEach(element => {
                        if (element.id == response.id) {
                            element.imagen = null;
                            element.imagen = response.imagen;

                        }
                    });

                    //this.links.push(response)

                    //this.productos_data = response
                    //this.$router.push('/app/'); 
                } else {
                    this.data_file = null;
                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });

        },

    }
}
</script>

<style>
.flip-list-move {
    transition: transform 0.5s;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
</style>
