// stores/counter.js
import { defineStore } from 'pinia'

export const useConfigStore = defineStore('config', {
  state: () => {
    return { 
        //baseapi: 'https://bentech-popcing.herokuapp.com/api/',
       //baseapi: 'http://127.0.0.1:8000/api/',
        //baseapi: 'http://192.168.1.18:8000/api/',
        token:'',
    
    }
  },
  // could also be defined as
  // state: () => ({ count: 0 })
  actions: {
    set(data) {
      this.baseapi=data;
    },
  },
})