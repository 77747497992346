import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

//import "tailwindcss/tailwind.css";
import VueFeather from 'vue-feather';
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VideoBg from 'vue-videobg'
import VuePictureSwipe from 'vue-picture-swipe';
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'
import VueApexCharts from "vue3-apexcharts";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import LazyTube from "vue-lazytube";
import VueMobileDetection from "vue-mobile-detection";


import VueShepherdPlugin from 'vue-shepherd';
import 'shepherd.js/dist/css/shepherd.css';


//import Buefy from 'buefy'
//import 'buefy/dist/buefy.css'
import VueImageZoomer from 'vue-image-zoomer'
import 'vue-image-zoomer/dist/style.css';

import 'animate.css';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBj10r5biyxv_YgqxMvDwtIvsE8O9Po6UI",
  authDomain: "popcing-links.firebaseapp.com",
  projectId: "popcing-links",
  storageBucket: "popcing-links.appspot.com",
  messagingSenderId: "949881621456",
  appId: "1:949881621456:web:36a036be96b5186a7907d5",
  measurementId: "G-9NBZP5NRTD"
};

// Initialize Firebase
const appf = initializeApp(firebaseConfig);
const analytics = getAnalytics(appf);
import VueDraggable from 'vuedraggable'


const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
const app=createApp(App)
  .use(VueShepherdPlugin)
  .use(MonthPicker)
  .use(MonthPickerInput)
  .use(VueApexCharts)
  .use(router)
  .use(VueMobileDetection)
  .use(ElementPlus)
  .use(LazyTube)
  //.use(Buefy.Default)
  .use(VueDraggable)

.use(VueImageZoomer)
  .use(pinia);

  //.use(VueFeather.name, VueFeather)
  app.component('VuePictureSwipe', VuePictureSwipe);
  app.component(VueFeather.name, VueFeather);
  app.component('VideoBg', VideoBg);
  app.mount("#app");
