<template>
<div class="h-screen w-full  ">

    <div class="h-screen w-full  ">
        <header class="absolute bg-white left-0 top-0 z-50 w-full">
            <div class="container">
                <div class="relative -mx-4 flex items-center justify-between">
                    <div class="w-60 max-w-full px-4">
                        <a href="javascript:void(0)" class="block w-full py-5">

                            <div v-if="getU().hasOwnProperty('logo_favicon')" @click="$router.push('/')" class="w-full text-center flex flex-wrap content-center justify-start px-10 h-full">
                                <img class="w-10 h-10 rounded bg-pink-300" :class="getU().color_logo" :src="getU().logo_favicon" :alt="getU().nombre" />
                            </div>
                        </a>
                    </div>

                </div>
            </div>
        </header>

        <div class="relative  justify-between flex flex-wrap content-center h-full w-full  overflow-y-scroll bg-gray-50">
            <div class=" h-full w-full  ">

                <section class="relative flex flex-wrap lg:h-screen lg:items-center w-full bg-gray-50 pt-24">
                    <div class="w-full px-4 py-12 sm:px-6 sm:py-16 lg:w-1/2  lg:px-8 lg:py-24">
                        <div class="mx-auto max-w-lg text-left">
                            <h1 class="px-10 text-2xl md:text-3xl xl:text-5xl lg:text-3xl font-bold sm:text-3xl">Categoría</h1>

                            <p class="px-10 mt-4 text-gray-500">

                               Seleccione una categoría

                            </p>
                        </div>

                        <form action="" class="mx-auto mt-8 mb-0 max-w-md space-y-4">
                            <div>
                                <div class="flex flex-wrap">
                                    <div v-for="(row,index) in categorias" v-bind:key="index" @click="tag=row.nombre" :class="tag==row.nombre?'bg-gray-900 text-white':'bg-gray-100 hover:bg-gray-300 text-black'" role="button" tabindex="0" class=" px-4 py-1 cursor-pointer my-2 mr-2 rounded-full  border-black border font-semibold text-14 transition-all  ">
                                        <div>
                                            <font style="vertical-align: inherit;">
                                                <font style="vertical-align: inherit;">{{row.icono}} </font><span class="ml-2">
                                                    <font style="vertical-align: inherit;">{{row.nombre}}</font>
                                                </span>
                                            </font> <span class="ml-2">
                                                <font style="vertical-align: inherit;"></font>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            
                            <div class="flex items-center justify-between">

                                <button @click="GuardarCategoria()" type="button" class=" inline-block w-full rounded-full bg-purple-700 px-5 py-3 text-sm font-medium text-white">
                                   Continuar
                                </button>
                            </div>
                        </form>
                    </div>

                    <div class="relative h-96 w-full sm:h-96 lg:h-full xl:h-full lg:w-1/2 bg-red-100">
                        <img alt="Welcome" src="https://res.cloudinary.com/dgzea9m7q/image/upload/q_auto:low/v1667982138/software/links/pexels-lady-escabia-2299390.jpg" class="absolute inset-0 h-full w-full object-cover" />
                    </div>
                </section>

            </div>
        </div>

    </div>

</div>
</template>

<script>
import {
    emitter
} from "@/mitt.js";
import {
    useConfigStore
} from '../stores/config'

import generales from "@/functions/generales";
import infosistem from "@/Services/infosistem";
import user_s from "@/Services/user";
import {
    useInfoStore
} from '../stores/info'

import {
    useUserStore
} from '../stores/user'
import {
    useRouter,
    useRoute
} from 'vue-router'
export default {
    components: {

    },
    mixins: [generales, infosistem],
    data() {
        const user = useUserStore()
        const info = useInfoStore()
        return {

            id: 0,
            nombre: '',
            username: '',
            correo: '',
            contrasena: '',
            user: user,
            descripcion: '',
            precio: '',
            boletos: '',
            activo: true,
            tag: '',
            info,
            data: [],
            categorias: [{
                    "icono": "🐶",
                    "nombre": "Animales"
                },
                {
                    "icono": "📺",
                    "nombre": "Anime"
                },
                {
                    "icono": "🎨",
                    "nombre": "Arte"
                },
                {
                    "icono": "💄",
                    "nombre": "belleza"
                },
                {
                    "icono": "📚",
                    "nombre": "Libros y escritura"
                },
                {
                    "icono": "💼",
                    "nombre": "Negocios"
                },
                {
                    "icono": "🕺",
                    "nombre": "Danza"
                },
                {
                    "icono": "✏️",
                    "nombre": "Educación"
                },
                {
                    "icono": "🎥",
                    "nombre": "Entretenimiento"
                },
                {
                    "icono": "👗",
                    "nombre": "Moda"
                },
                {
                    "icono": "🍱",
                    "nombre": "comida"
                },
                {
                    "icono": "👾",
                    "nombre": "Juegos"
                },
                {
                    "icono": "💪",
                    "nombre": "Salud"
                },
                {
                    "icono": "😎 estilo de",
                    "nombre": "vida"
                },
                {
                    "icono": "💃",
                    "nombre": "modelo"
                },
                {
                    "icono": "🎵",
                    "nombre": "Música"
                },
                {
                    "icono": "🙂",
                    "nombre": "personales"
                },
                {
                    "icono": "🎞",
                    "nombre": "Fotografía"
                },
                {
                    "icono": "🏅",
                    "nombre": "Deportes"
                },
                {
                    "icono": "💻",
                    "nombre": "Tecnología"
                },
                {
                    "icono": "🌎",
                    "nombre": "Viajar"
                }
            ]

        }

    },
    setup() {
        const router = useRouter()
        const route = useRoute()

        const config = useConfigStore();

        const salir = () => {
            user.limpiar([]);
            router.push('/');
        }
        return {
            active: 0,
            config,

        }

    },
    mounted() {

    },
    created() {
        if (this.user.issetCategoria()) {
                this.$router.push('/admin');
            } else {
                this.$router.push('/c/cuenta/categorias');
            }
        //this.getData()
        // this.get_info();
    },
    methods: {
        getU() {
            let dat = this.info.getAll();
            console.log(dat)
            return dat;
        },
        async GuardarCategoria() {
            emitter.emit("loading", true)
            let dat = {
                categoria: this.tag,

               
            }
            let data = await user_s.CATEGORIA(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                   
                    this.user.setCategoria(this.tag);
                    this.$router.push('/admin')

                } else {
                    emitter.emit("alerta_show", response.error);
                }
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
