<template>
<section class=" h-full  w-full flex flex-wrap bg-gray-50 justify-center content-center ">
    <div x-show="selectedRows.length" class="bg-teal-200 fixed top-0 left-0 right-0 z-40 w-full shadow" />

    <Tabla :columns="headings" :rows="data" :filtros="['nombre','telefono','correo','rfc']" />

    <div v-if="showM" style="z-index:9000" class="overflow-x-hidden overflow-y-auto fixed inset-0  outline-none focus:outline-none justify-center bg-black bg-opacity-50 items-center flex">
        <div class="relative  my-6 mx-auto md:w-8/12 xl:w-6/12 lg:w-6/12">
            <!--content-->
            <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <!--header-->
                <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 class="text-3xl font-semibold">
                        {{ id==0?'Crear Usuario':'Editar Usuario' }}
                    </h3>
                    <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="showM=false">
                        <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                        </span>
                    </button>
                </div>
                <!--body-->
                <div class="relative p-6 flex-auto h-96 overflow-y-scroll flex flex-wrap">
                    <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                            Nombre
                        </span>
                        <input id="cantidad" v-model="name" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="text" placeholder="Nombre">
                    </div>
                    <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                            Correo
                        </span>
                        <input id="cantidad" v-model="email" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="text" placeholder="Correo">
                    </div>
                    <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                            Teléfono
                        </span>
                        <input id="cantidad" v-model="phone" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="numeric" placeholder="Teléfono">
                    </div>
                    <div class="mb-2 mt-2  justify-start flex flex-wrap w-6/12 px-2">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                            Contraseña
                        </span>
                        <input id="cantidad" v-model="password" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10" type="password" placeholder="Contraseña nueva">
                    </div>
                    <div class="mb-2 mt-2 justify-start flex flex-wrap w-6/12 px-2">
                        <span class="px-4 py-2 block text-gray-700 text-start text-sm font-semibold " htmlFor="nombre">
                            Rol
                        </span>
                        <select id="cantidad" v-model="role" class="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10">
                            <option :value="'admin'" :selected="role=='admin'">
                                Admin
                            </option>
                            <option :value="'vendedor'" :selected="role=='vendedor'">
                                Vendedor
                            </option>
                            <option :value="'produccion'" :selected="role=='produccion'">
                                Producción
                            </option>
                            <option :value="'verificador'" :selected="role=='verificador'">
                                Verificador
                            </option>
                            <option :value="'instalador'" :selected="role=='instalador'">
                                Instalador
                            </option>
                        </select>
                    </div>
                </div>
                <!--footer-->
                <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button class="text-red-500 bg-red-500 bg-opacity-20 border border-solid border-red-500 hover:bg-red-500 hover:bg-opacity-30 hover:text-red-900 active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" @click="showM=false">
                        Cancelar
                    </button>
                    <button class="text-blue-800 bg-blue-500 bg-opacity-40 border hover:text-white  hover:bg-blue-700 border-solid border-blue-800 font-bold uppercase px-6 py-3 text-sm outline-none  rounded focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" @click="guardar()">
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="actionModal!=null" style="z-index:9999900" class="bg-slate-800 bg-opacity-50  h-screen w-full flex content-center justify-center items-center fixed  top-0 right-0 bottom-0 left-0">
        <div class=" bg-white w-72 h-72 self-center  flex-wrap px-5 py-14 rounded-md text-start flex content-center justify-center items-center">
            <div class="text-base mb-4 font-bold text-slate-500">
                ¿Qué desea hacer?
            </div>
            <div class="flex-wrap flex justify-between  w-full">
                <div class="w-3/6 px-2">
                    <button class="w-full bg-blue-500 bg-opacity-20 px-4 py-2 rounded-md text-md text-blue-500 border-2 border-blue-500" @click="edit(actionModal);">
                        Editar
                    </button>
                </div>
                <div class="w-3/6 px-2">
                    <button class="bg-red-500 px-4 py-2 bg-opacity-20 rounded-md text-md text-red-500 w-full  border-2 border-red-500" @click="eliminar(actionModal.id)">
                        Eliminar
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    emitter
} from "../../../mitt.js";
import {
    useUserStore
} from '../../../stores/user'
import usaurios from "@/Services/user";
import Tabla from "@/components/Tabla.vue";
export default {
    components: {
        Tabla
    },
    mixins: [usaurios],
    data() {
        const user = useUserStore()

        return {
            headings: [

                {
                    key: 'id',
                    name: '#',
                    show: true
                },
                {
                    key: 'name',
                    name: 'Nombre',
                    show: true
                },
                {
                    key: 'email',
                    name: 'Correo',
                    show: true
                },
                {
                    key: 'phone',
                    name: 'Teléfono',
                    show: true
                },
                {
                    key: 'password_plano',
                    name: 'Contraseña',
                    show: true
                },
                {
                    key: 'role',
                    name: 'Rol',
                    show: true
                },

                {
                    key: 'edit',
                    name: 'Editar',
                    show: true
                },
                {
                    key: 'eliminar',
                    name: 'Eliminar',
                    show: true
                }
            ],
            buscar: '',

            open: false,
            user: user.getAll(),
            data: [],
            actionModal: null,
            showM: false,
            id: 0,
            email: '',
            phone: '',
            role: 'vendedor',

            password: '',
            name: '',

        }

    },
    mounted() {

    },
    created() {
        this.getData()
    },
    methods: {
        addRow() {
            this.showM = !this.showM;
            this.limpiar()
        },
        buscador(row) {
            if (String(row.telefono).toUpperCase().search(String(this.buscar).toUpperCase()) != -1 || (String(row.nombre)).toUpperCase().search(String(this.buscar).toUpperCase()) != -1) {
                return true;
            } else {
                return false;
            }
        },
        getColumns() {
            let col = 0;
            this.headings.forEach(element => {
                if (element.show) {
                    col = col + 1
                }
            });
            return col;
        },
        limpiar() {
            this.id = 0;
            this.email = '';
            this.password = '';
            this.phone = '';
            this.role = 'vendedor';

        },
        edit(row) {
            this.id = row.id;
            this.email = row.email;
            this.password = row.password;
            this.phone = row.phone;
            this.role = row.role;
            this.name = row.name;

            this.showM = true;
            this.actionModal = null;
        },

        async getData() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await usaurios.GET(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
        async guardar() {
            let dat = {
                id: this.id,
                role: this.role,
                phone: this.phone,
                name: this.name,
                email: this.email,
                password: this.password,

            }
            if (this.id == 0) {
                this.crear(dat)
            } else {
                this.editar(dat)
            }
        },
        async editar(dat) {
            emitter.emit("loading", true)

            await usaurios.EDITAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async crear(dat) {
            emitter.emit("loading", true)

            await usaurios.CREAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                emitter.emit("loading", false)
            });
        },
        async eliminar(id) {
            emitter.emit("loading", true)
            let dat = {
                id: id,

            }
            await usaurios.ELIMINAR(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                this.actionModal = null
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
