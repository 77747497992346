<template>

   <div class="h-screen w-full  ">
   

        <div class="h-screen w-full  overflow-hidden">
        
       <!-- ====== Navbar Section End -->
       <header
         
       class="absolute bg-pink-50 hidden left-0 top-0 z-50 w-full"
     >
       <div class="container w-full">
         <div class="relative -mx-4 flex items-center justify-between w-full">
           <div class="w-48  px-4">
             <a href="javascript:void(0)" class="block w-full py-5">
              
              <div v-if="getU().hasOwnProperty('logo_favicon')" @click="$router.push('/')" class="w-full text-center flex flex-wrap content-center justify-start px-10 h-full">
                  <img class="w-10 h-10 rounded bg-pink-300" :class="getU().color_logo" :src="getU().logo_favicon" :alt="getU().nombre" />
              </div>
             </a>
           </div>
           <div class="flex w-8/12 items-center justify-between px-4">
             <div class="w-full flex flex-wrap">
               <!--<button
                
                 id="navbarToggler"
                 class="absolute right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden"
               >
                 <span
                   class="relative my-[6px] block h-[2px] w-[30px] bg-body-color"
                 ></span>
                 <span
                   class="relative my-[6px] block h-[2px] w-[30px] bg-body-color"
                 ></span>
                 <span
                   class="relative my-[6px] block h-[2px] w-[30px] bg-body-color"
                 ></span>
               </button>-->
             
               
                   
                   
                  
                    <div>
                     
                     </div>
                
             
             </div>
             
           </div>
         </div>
       </div>
     </header>
       <!-- ====== Hero Section Start -->
       <div class="relative bg-purple-700 justify-between flex flex-wrap content-center h-full w-full bg-gray-100" id="content-animate">
         <div class=" h-full w-full">
           <div class=" flex flex-wrap -mt-24 h-full py-0 w-full ">
             <div class="w-full h-full px-4 w-full flex flex-wrap justify-center content-center">
               <div class="hero-content">
                 <h1
                   class="animate__animated   animate__backInLeft  mb-3 text-5xl font-bold  text-purple-400 sm:text-[42px] lg:text-[40px] xl:text-[42px]"
                 >
                   toplink <br />
                   
                 </h1>
                 <p class="mb-3 text-2xl font-bold leading-snug text-purple-400 sm:text-[42px] lg:text-[40px] xl:text-[42px]">
                    Administrador para creadores <br />
                   todo en un solo lugar
                 </p>
                 <p class="mb-8  text-base text-body-color text-purple-400">
                   Comparte a tus seguidores toda tu información en un solo espacio.
                 </p>
                 <div class="flex flex-wrap justify-end w-full   px-2 sm:px-2 md:px-5 lg:px-10 xl:px-10">
                    <div class="flex flex-wrap justify-end w-full  rounded-full " :class="focus?'outline-white outline-solid outline-2  outline-offset-2 ':''">
                   
                        
                        <div  class=" w-4/6 flex pl-4 flex-wrap hover:bg-white inline-flex border-0   bg-white rounded-l-full font-extrabold items-center justify-center rounded-lg  py-4 px-0 text-center text-base font-base text-pink-600  sm:px-0 lg:px-8 xl:px-10"
                       
                        >
                            <label class=" flex text-xl sm:text-xl md:text-xl xl:text-xl lg:text-xl  text-rigth">toplink.app/</label>
                            <input
                              @focusin="focus=true"
                              @focusout="focus=false"
                              placeholder="pop"
                              v-model="nombre"
                              class="w-3/6 text-xl text-left  pr-4 py-3 bg-white  text-gray-700 font-extrabold  border-0 rounded-lg focus:outline-none focus:bg-white focus:border-0 "
                            >
                        </div>
                       
                            
                              
                           
                        
                         <div
                          
                           class=" inline-flex bg-pink-400 w-2/6 border-0 rounded-r-full font-extrabold items-center justify-center rounded-lg   text-center text-base font-base text-white hover:bg-opacity-90 overflow-hidden "
                         >
                          <a @click="CrearCuenta()" class=" w-full flex-wrap h-full bg-pink-400 hover:bg-pink-500  flex flex-wrap content-center justify-center text-pink-200">
                            <span class="  align-middle w-10/12 text-xl">Reclamar</span></a>
                    </div>
                       
                       
                        </div>
                 </div>
                 <div class="w-full text-center flex mt-4 flex-wrap justify-center content-center text-gray-200 opacity-70">
                  <a
                  href="/c/login"
                  class="flex py-2 text-xl underline lowercase font-extrabold hover:text-primary lg:ml-12 lg:inline-flex"
                >
                Ingresar
                </a>
                 </div>
                
                
                 
               </div>
             </div>
            <!--- <div class="hidden px-4  lg:w-1/12 h-full "></div>
             <div class="w-full px-4 lg:w-6/12 h-full hidden">
               <div class="lg:ml-auto lg:text-right h-full ">
                 <div class="relative z-10 inline-block pt-11 h-full hidden">
                   <img
                     src="https://res.cloudinary.com/dgzea9m7q/image/upload/q_auto:low/v1667950441/software/pexels-roy-reyna-3283568.jpg"
                     alt="hero"
                     class="max-w-full h-full rounded-l-2xl rounded-r-lg lg:ml-auto "
                   />
                   <span class="absolute -left-8 -bottom-8 z-[-1]">
                     <svg
                       width="93"
                       height="93"
                       viewBox="0 0 93 93"
                       fill="none"
                       xmlns="http://www.w3.org/2000/svg"
                     >
                       <circle cx="2.5" cy="2.5" r="2.5" fill="#3056D3" />
                       <circle cx="2.5" cy="24.5" r="2.5" fill="#3056D3" />
                       <circle cx="2.5" cy="46.5" r="2.5" fill="#3056D3" />
                       <circle cx="2.5" cy="68.5" r="2.5" fill="#3056D3" />
                       <circle cx="2.5" cy="90.5" r="2.5" fill="#3056D3" />
                       <circle cx="24.5" cy="2.5" r="2.5" fill="#3056D3" />
                       <circle cx="24.5" cy="24.5" r="2.5" fill="#3056D3" />
                       <circle cx="24.5" cy="46.5" r="2.5" fill="#3056D3" />
                       <circle cx="24.5" cy="68.5" r="2.5" fill="#3056D3" />
                       <circle cx="24.5" cy="90.5" r="2.5" fill="#3056D3" />
                       <circle cx="46.5" cy="2.5" r="2.5" fill="#3056D3" />
                       <circle cx="46.5" cy="24.5" r="2.5" fill="#3056D3" />
                       <circle cx="46.5" cy="46.5" r="2.5" fill="#3056D3" />
                       <circle cx="46.5" cy="68.5" r="2.5" fill="#3056D3" />
                       <circle cx="46.5" cy="90.5" r="2.5" fill="#3056D3" />
                       <circle cx="68.5" cy="2.5" r="2.5" fill="#3056D3" />
                       <circle cx="68.5" cy="24.5" r="2.5" fill="#3056D3" />
                       <circle cx="68.5" cy="46.5" r="2.5" fill="#3056D3" />
                       <circle cx="68.5" cy="68.5" r="2.5" fill="#3056D3" />
                       <circle cx="68.5" cy="90.5" r="2.5" fill="#3056D3" />
                       <circle cx="90.5" cy="2.5" r="2.5" fill="#3056D3" />
                       <circle cx="90.5" cy="24.5" r="2.5" fill="#3056D3" />
                       <circle cx="90.5" cy="46.5" r="2.5" fill="#3056D3" />
                       <circle cx="90.5" cy="68.5" r="2.5" fill="#3056D3" />
                       <circle cx="90.5" cy="90.5" r="2.5" fill="#3056D3" />
                     </svg>
                   </span>
                 </div>
               </div>
             </div>-->
           </div>
         </div>
       </div>
     
        </div>
     
        
   
        <div class="clients  px-10 fixed bottom-10 w-full content-center text-center flex flex-wrap justify-center z-30">
          <h6
            class="mb-2 flex items-center text-xl font-normal text-white "
          >
           
            <a
              class="ml-2 inline-block   text-white font-extrabold"
              href="https://www.instagram.com/popcingmx/"
              target="_blank"
            > powered by @popcing</a>
          </h6>
          <div class="flex items-center">
           <div class="flex gap-4">
               <a v-if="info.hasOwnProperty('instagram')" :href="info.instagram" target="_blank" class="text-gray-400 hover:text-gray-500 active:text-gray-600 transition duration-100">
                   <i class="text-white text-3xl lab la-instagram"></i>
               </a>

               <a v-if="info.hasOwnProperty('facebook')" :href="info.facebook" target="_blank" class="text-gray-400 hover:text-gray-500 active:text-gray-600 transition duration-100">
                   <i class="text-white text-3xl lab la-facebook"></i>
               </a>

               <a v-if="info.hasOwnProperty('whatsapp')" :href="'https://wa.me/'+info.whatsapp" target="_blank" class="text-gray-400 hover:text-gray-500 active:text-gray-600 transition duration-100">
                   <i class="text-white text-3xl lab la-whatsapp"></i>
               </a>

              
           </div>
          </div>
        </div>
     

   </div>

   
</template>
<script>
import {
    emitter
} from "@/mitt.js";
import {
    useConfigStore
} from '../stores/config'

import generales from "@/functions/generales";
import infosistem from "@/Services/infosistem";
import {
    useInfoStore
} from '../stores/info'

import {
    useUserStore
} from '../stores/user'
import {
    useRouter,
    useRoute
} from 'vue-router'
export default {
    components: {
        
    },
    mixins: [generales,infosistem],
    data() {
        const user = useUserStore()
        const info = useInfoStore()
        return {
          
            focus:false,
          id:0,
            nombre: '',
            descripcion: '',
            precio: '',
            boletos: '',
            activo:true,
            infoset: info,
            info: info,
            data:[]

        }

    },
    setup() {
        const router = useRouter()
        const route = useRoute()
        const user = useUserStore()
   

        const config = useConfigStore();

        const salir=()=>{
            user.limpiar([]);
            router.push('/');
        }
        return {
            active: 0,
            config,
         
           
            user: user.getAll(),
        
            salir
            

        }

    },
    mounted() {
      emitter.emit("loading", false)
window.VANTA.CLOUDS({
  el: "#content-animate",
  mouseControls: true,
  touchControls: true,
  gyroControls: false,
  minHeight: 200.00,
  minWidth: 200.00,
  skyColor: 0x4e089d,
  cloudColor: 0xb228ed,
  sunColor: 0x2400ed,
  sunGlareColor: 0x4830ff,
  sunlightColor: 0xb6ff31,
  speed: 2.60
})
  /*
  mouseControls: true,
  touchControls: true,
  gyroControls: false,
  minHeight: 200.00,
  minWidth: 200.00,
  baseColor: 0x590029,
  backgroundColor: 0x5a20cd,
  amplitudeFactor: 1.60,
  xOffset: 0.04,
  yOffset: -0.08,
  size: 1.70
})*/


    },
   
    created() {
      emitter.emit("loading", false)
        //this.getData()
        this.get_info();
    },
    methods: {
        getU() {
            let dat = this.info.getAll();
            console.log(dat)
            return dat;
        },
        CrearCuenta(){
            if(this.nombre!=''){
                this.$router.push('/c/cuenta?poplink='+this.nombre)
                
            }else{
                this.$router.push('/c/cuenta')
            }
            

        },
        async get_info() {
            console.log('data------')
            let data = await infosistem.GET().then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    let infov = {};
                    response.forEach(element => {
                        infov[element.key] = element.valor;
                    });
                    this.infoset.set(
                        infov
                    );
                    //infoData.value=(infov);
                    //if(response.user.role=='admin'){

                    //this.$router.push('/app/'); 
                    //}

                }
            });
        },
       
        async getData() {
            this.showM = false;
            this.actionModal = null;
            emitter.emit("loading", true)

            await rifas.GETACTIVAS(this.user.token).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data = response
                    //this.$router.push('/app/'); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });
        },
    

    }
}
</script>
<style>

.outline-solid{
    outline-style:solid !important;
}

</style>