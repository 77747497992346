<template>
    <nav class="flex flex-row h-16 bg-white w-full sticky top-0 ">

        <a v-for="(row,index) in menu_opciones" :ref="row.ref" v-bind:key="index" @click="row.nombre!='Salir'?$router.push(row.ruta):salir()" :class="activo==row.key?'border-black font-extrabold border-b-2':'font-medium '" class="h-16 text-gray-600 py-4 px-2 block hover:text-pink-800 focus:outline-none text-gray-700   ">
            {{row.nombre}}
        </a>

    </nav>
</template>

<script>
//@ts-ignore
import generales from '@/functions/generales'

import {
    useUserStore
} from '@/stores/user'
import {
    useRouter,
    useRoute
} from 'vue-router'
//import palabras from "@/mixins/palabras";
export default {
    name: "Home",
    components: {

    },
    mixins: [generales],
    props: {

       
        
       

    },
    data() {
        const router = useRouter()
        const route = useRoute()
        const user = useUserStore()
        return {
            router:router,

            user:user,
            activo: 0,
            menu_opciones: [
                {
                    key: 'perfil',
                    nombre: 'Perfil',
                    ruta: '/admin/perfil',
                    ref:'paginaperfil'
                },{
                    key: 'links',
                    nombre: 'Links',
                    ruta: '/admin',
                    ref:'paginalinks'
                },
               /* {
                    key: 'grupos',
                    nombre: 'grupos',
                    ruta: '/admin/grupos'
                },*/
                {
                    key: 'apariencia',
                    nombre: 'Apariencia',
                    ruta: '/admin/apariencia',
                    ref:'paginaapariencia'
                },
                {
                    key: 'salir',
                    nombre: 'Salir',
                    ruta: '/'
                },
               
                /*{
                    key: 'paquetes',
                    nombre: 'paquetes',
                    ruta: '/admin/mejoras'
                },*/
               
               /* ,{
                    key: 'social',
                    nombre: 'social',
                    ruta: '/admin/social'
                }*/
            ],
        }
    },

    mounted() {
        this.activo = this.$route.name;
    },
    methods: {
        salir(){
           
            this.router.push('/');
            this.user.limpiar();
        }
       

    }
}
</script>

<style>

</style>
