import moment from "moment";
import dayjs from "dayjs";

import rt from "dayjs/plugin/relativeTime";
//import { es } from "dayjs/locale/es";

dayjs.extend(rt);

export default {
  data() {
    return {};
  },

  created() {},

  methods: {
    timeAgo(date) {
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      options.timeZone = 'UTC';
options.timeZoneName = 'short';
      let fecha = this.formatFullDateTime(date);
      let f=new Date(date)
     let ff= f.toLocaleString('es-MX', options)
      return ff;
      console.log(fecha, "-------fecha------", date);
      return dayjs().to(dayjs(date)); // 31 years
    },

    exportCSV(id) {
      let csv = [];
      var container = document.querySelector("#" + id);
      const rows = container.querySelectorAll("tr");
      for (const row of rows.values()) {
        const cells = row.querySelectorAll("td, th");
        const rowText = Array.from(cells).map((cell) => cell.innerText);
        csv.push(rowText.join(","));
      }
      const csvFile = new Blob([csv.join("\n")], {
        type: "text/csv;charset=utf-8;",
      });
      // saveAs(csvFile, "data.csv");

      var temp_link = document.createElement("a");

      // Download csv file
      temp_link.download = id + ".csv";
      var url = window.URL.createObjectURL(csvFile);
      temp_link.href = url;

      // This link should not be displayed
      temp_link.style.display = "none";
      document.body.appendChild(temp_link);

      // Automatically click the link to trigger download
      temp_link.click();
      document.body.removeChild(temp_link);
    },

    //archivo imagen

    //end archivo imagen

    formatNumberAnd0(event) {
      var inputChar = String.fromCharCode(event.keyCode);
      var code = event.keyCode;
      var allowedKeys = [8];

      console.log();
      if (allowedKeys.indexOf(code) !== -1) {
        return;
      }
      event.target.value = event.target.value.replace(
        /[^\d\/]|^[\/]*$/g,
        "" // To allow only digits and `/`
      );
    },
    formatNumber(event) {
      var inputChar = String.fromCharCode(event.keyCode);
      var code = event.keyCode;
      var allowedKeys = [8];

      console.log();
      if (allowedKeys.indexOf(code) !== -1) {
        return;
      }
      event.target.value = event.target.value.replace(
        /[^\d\/]|^[\/]*$/g,
        "" // To allow only digits and `/`
      );
    },
    CapitalizeTo(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    validation(data) {
      let text = "";
      for (var [key, value] of Object.entries(data.errors)) {
        text +=
          '<div class="w-full flex flex-wrap text-center justify-center content-center"><span>' +
          value +
          "</span> </div><br>"; // "a 5", "b 7", "c 9"
      }
      return text;
      //emitter.emit("alerta_show",text);
    },
    /**calculos de datos */

    CalcularAbonos(abonos) {
      let total = 0;
      abonos.forEach((element) => {
        total = Number(total) + Number(element.cantidad);
      });
      return total;
    },

    /**--------- */

    getFirstDayOfMonth() {
      var date = new Date();
      var d = new Date(date.getFullYear(), date.getMonth(), 1);
      let month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    formatDateEN(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    formatDateTime(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getLastDayOfMonth() {
      var date = new Date();

      var d = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      let month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
      // return lastDay;
    },

    getfisrtDay() {
      var myCurrentDate = new Date();
      var myPastDate = new Date(myCurrentDate);
      myPastDate.setDate(myPastDate.getDate() - 7);
      return myPastDate;
    },

    formatPrice(value) {
      value = value.toString().replace(".00", "");
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    setMasOneDay(date_d) {
      var myPastDate = new Date(date_d);
      myPastDate.setDate(myPastDate.getDate() + 1);
      return myPastDate;
    },
    formatFullDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    formatFullDateTime(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return (
        [year, month, day].join("-") +
        " " +
        d.getUTCHours() +
        ":" +
        d.getUTCMinutes() +
        ":" +
        d.getUTCSeconds()
      );
    },
  },
};
