<template>
    <div class="h-screen w-full  ">

        <div class="h-screen w-full  ">
            <header class="absolute bg-white left-0 top-0 z-50 w-full">
                <div class="container">
                    <div class="relative -mx-4 flex items-center justify-between">
                        <div class="w-60 max-w-full px-4">
                            <a href="javascript:void(0)" class="block w-full py-5">
    
                                <div v-if="getInfo().hasOwnProperty('logo_favicon')" @click="$router.push('/')" class="w-full text-center flex flex-wrap content-center justify-start px-10 h-full">
                                    <img class="w-10 h-10 rounded bg-pink-300" :class="getInfo().color_logo" :src="getInfo().logo_favicon" :alt="getInfo().nombre" />
                                </div>
                            </a>
                        </div>
                        <div class="flex w-full items-center justify-between px-4">
                            <div>
                                <button @click="navbarOpen = !navbarOpen" :class="navbarOpen && 'navbarTogglerActive'" id="navbarToggler" class="absolute right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden">
                                    <span class="relative my-[6px] block h-[2px] w-[30px] bg-body-color"></span>
                                    <span class="relative my-[6px] block h-[2px] w-[30px] bg-body-color"></span>
                                    <span class="relative my-[6px] block h-[2px] w-[30px] bg-body-color"></span>
                                </button>
                                <nav x-transition :class="!navbarOpen && 'hidden'" id="navbarCollapse" class="absolute right-4 top-full w-full max-w-[250px] rounded-lg bg-white py-5 px-6 shadow transition-all lg:static lg:block lg:w-full lg:max-w-full lg:shadow-none">
                                    <ul class="blcok lg:flex">
                                       
    
                                       
                                    </ul>
                                </nav>
                            </div>
    
                        </div>
                    </div>
                </div>
            </header>
            <!-- ====== Navbar Section End -->
    
            <!-- ====== Hero Section Start -->
            <div class="relative  justify-between flex flex-wrap content-center h-full w-full  overflow-y-scroll bg-gray-50">
                <div class=" h-full w-full  " >
    
                    <section id="diegoarmado" class="relative flex flex-wrap h-full sm:h-full md:h-full lg:h-screen lg:items-center w-full bg-gray-50   content-center">
                        <div class=" w-full px-4 py-12 -mt-10 sm:-mt-10 md:-mt-0 lg:-mt-0 xl:-mt-0 sm:px-6 sm:py-16 lg:w-1/2 z-30 lg:px-8 lg:py-24">
                            <div class="mx-auto max-w-lg text-center">
                                <h1 class="text-white sm:text-white md:text-black lg:text-black xl:text-black text-2xl md:text-3xl xl:text-5xl lg:text-3xl font-bold sm:text-3xl">Ingresar</h1>
    
                                
                            </div>
    
                            <form action="" class="mx-auto mt-8 mb-0 max-w-md space-y-4">
                                <div>
                                    <label for="email" class="sr-only">@Usuario</label>
    
                                    <div class="relative">
                                        <input v-model="usuario" type="text" class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm" placeholder="Nombre de usuario" />
    
                                        <span class="absolute inset-y-0 right-4 inline-flex text-purple-800  text-2xl font-extrabold items-center">
                                            <i class="las la-link"></i>
                                        </span>
                                    </div>
                                </div>
    
                                
                                <div>
                                    <label for="password" class="sr-only">Contraseña</label>
                                    <div class="relative">
                                        <input v-model="contrasena" :type="showpass?'text':'password'" class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm" placeholder="Contraseña" />
    
                                        <span @click="showpass=!showpass" class="absolute inset-y-0 cursor-pointer right-4 text-2xl font-extrabold inline-flex items-center">
    
                                            <i v-if="showpass" class="las la-eye"></i>
                                            <i v-else class="las la-eye-slash"></i>
                                        </span>
                                    </div>
                                </div>
                                
                                <div class="flex items-center justify-between">
    
                                    <button @click="ingresar()" type="button" class=" inline-block w-full rounded-full bg-purple-700 px-5 py-3 text-sm font-medium text-white">
                                        Iniciar sesión
                                    </button>
                                </div>
                            </form>
                        </div>
    
                        <div class=" absolute top-0 z-0 opacity-80 sm:opacity-30 md:opacity-100 lg:opacity-100 xl:opacity-100  sm:absolute md:relative lg:relative xl:relative  h-screen sm:h-screen  w-full lg:h-full xl:h-full lg:w-1/2 bg-red-100">
                            <img alt="Welcome" src="https://res.cloudinary.com/dgzea9m7q/image/upload/q_auto:low/v1667982138/software/links/pexels-lady-escabia-2299390.jpg" class="absolute inset-0 h-full w-full object-cover" />
                        </div>
                    </section>
    
                </div>
            </div>
    
        </div>
    
    </div>
</template>

<script>
//import PopularMovies from "@/components/PopularMovies.vue";

//import TopRated from "@/components/TopRated.vue";
import {
    ref,
    reactive
} from "vue";
import userService from "@/Services/user";
import infosistem from "@/Services/infosistem";

import {
    emitter
} from "../mitt.js";
import {
    useUserStore
} from '../stores/user'
import {
    useInfoStore
} from '../stores/info'
import {
    useConfigStore
} from '../stores/config'
import {
    useRouter,
    useRoute
} from 'vue-router'
export default {
    mixins: [infosistem],
    props: {
        price: {
            type: Number,
            default: 0
        },

    },
    data() {
        const user = useUserStore()
        const info = useInfoStore()
        return {

            id: 0,
           
            usuario: '',
          
            contrasena: '',
            showpass: false,
            user: user,
           
            activo: true,

            info,
            data: []

        }

    },

    setup(props) {
       
       
      
        const config = useConfigStore()

        const active = reactive(false);
      
        const visible= ref(false);
        // const add = () => productPrice.price++;

      
     
       
       
        return {
           
            active,
           
           
         
            visible

        };
    },
   
    methods:{
        async ingresar()  {
          
            let dat = {
                username: this.usuario,
                password: this.contrasena
            };

            let data = await userService.LOGIN_USER(dat).then((response) => {
                console.log(response)
                if (response != null && !response.hasOwnProperty('error')) {
                    this.user.set(
                        response
                    );

                    this.$router.push('/c/cuenta/categorias');
                    //if(response.user.role=='admin'){
                    //router.push('/app/welcome');

                    //this.$router.push('/app/'); 
                    //}
                } else {
                    alert("ocurrio un error");
                }
            });
        },
        getInfo() {
            let dat = this.info.getAll();
            console.log(dat)
            return dat;
        },

    },
    mounted() {


    }
};
</script>

<style scoped>
.work-sans {
    font-family: 'Work Sans', sans-serif;
}

#menu-toggle:checked+#menu {
    display: block;
}

.hover\:grow {
    transition: all 0.3s;
    transform: scale(1);
}

.hover\:grow:hover {
    transform: scale(1.02);
}

.carousel-open:checked+.carousel-item {
    position: static;
    opacity: 100;
}

.carousel-item {
    -webkit-transition: opacity 0.6s ease-out;
    transition: opacity 0.6s ease-out;
}

#carousel-1:checked~.control-1,
#carousel-2:checked~.control-2,
#carousel-3:checked~.control-3 {
    display: block;
}

.carousel-indicators {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 2%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;
}

#carousel-1:checked~.control-1~.carousel-indicators li:nth-child(1) .carousel-bullet,
#carousel-2:checked~.control-2~.carousel-indicators li:nth-child(2) .carousel-bullet,
#carousel-3:checked~.control-3~.carousel-indicators li:nth-child(3) .carousel-bullet {
    color: #000;
    /*Set to match the Tailwind colour you want the active one to be */
}
</style>
