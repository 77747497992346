<template>
<div class="w-full flex flex-wrap justify-center content-center">
    <div class="mx-1 rounded-full text-black bg-white h-10 w-10 overflow-hidden flex flex-wrap justify-center content-center" v-if="user.preferencias.instagram!=''&&user.preferencias.instagram!=null">
        <a :href="'https://'+user.preferencias.instagram" target="_blank">
            <i class="text-3xl  cursor-pointer lab la-instagram  " :class="' hover:'+user.color_titulo+' active:'+user.color_titulo+' focus:'+user.color_titulo"></i>
        </a> 
    </div>

    <div class="mx-1 rounded-full text-black bg-white h-10 w-10 overflow-hidden flex flex-wrap justify-center content-center  hover:bg-gradient-to-r hover:from-gray-500 hover:text-black" v-if="user.preferencias.whatsapp!=''&&user.preferencias.whatsapp!=null">
        <a :href="'https://'+user.preferencias.whatsapp" target="_blank"><i class="text-3xl mx-2 cursor-pointer lab la-whatsapp " :class="' hover:'+user.color_titulo+' active:'+user.color_titulo+' focus:'+user.color_titulo"></i> </a> </div>
    <div class="mx-1 rounded-full text-black bg-white h-10 w-10 overflow-hidden flex flex-wrap justify-center content-center" v-if="user.preferencias.facebook!=''&&user.preferencias.facebook!=null">
        <a :href="'https://'+user.preferencias.facebook" target="_blank"><i class="text-3xl mx-2 cursor-pointer lab la-facebook " :class="' hover:'+user.color_titulo+' active:'+user.color_titulo+' focus:'+user.color_titulo"></i> </a> </div>
    <div class="mx-1 rounded-full text-black bg-white h-10 w-10 overflow-hidden flex flex-wrap justify-center content-center" v-if="user.preferencias.telegram!=''&&user.preferencias.telegram!=null">
        <a :href="'https://'+user.preferencias.telegram" target="_blank"><i class="text-3xl mx-2 cursor-pointer lab la-telegram " :class="' hover:'+user.color_titulo+' active:'+user.color_titulo+' focus:'+user.color_titulo"></i> </a> </div>
    <div class="mx-1 rounded-full text-black bg-white h-10 w-10 overflow-hidden flex flex-wrap justify-center content-center" v-if="user.preferencias.twiter!=''&&user.preferencias.twiter!=null">
        <a :href="'https://'+user.preferencias.twiter" target="_blank"><i class="text-3xl mx-2 cursor-pointer lab la-twitter " :class="' hover:'+user.color_titulo+' active:'+user.color_titulo+' focus:'+user.color_titulo"></i> </a> </div>
    <div class="mx-1 rounded-full text-black bg-white h-10 w-10 overflow-hidden flex flex-wrap justify-center content-center" v-if="user.preferencias.twitch!=''&&user.preferencias.twitch!=null">
        <a :href="'https://'+user.preferencias.twitch" target="_blank"><i class="text-3xl mx-2 cursor-pointer lab la-twitch " :class="' hover:'+user.color_titulo+' active:'+user.color_titulo+' focus:'+user.color_titulo"></i> </a> </div>
    <div class="mx-1 rounded-full text-black bg-white h-10 w-10 overflow-hidden flex flex-wrap justify-center content-center" v-if="user.preferencias.github!=''&&user.preferencias.github!=null">
        <a :href="'https://'+user.preferencias.github" target="_blank"><i class="text-3xl mx-2 cursor-pointer lab la-github " :class="' hover:'+user.color_titulo+' active:'+user.color_titulo+' focus:'+user.color_titulo"></i> </a> </div>
    <div class="mx-1 rounded-full text-black bg-white h-10 w-10 overflow-hidden flex flex-wrap justify-center content-center" v-if="user.preferencias.snapchat!=''&&user.preferencias.snapchat!=null">
        <a :href="'https://'+user.preferencias.snapchat" target="_blank"><i class="text-3xl mx-2 cursor-pointer lab la-snapchat " :class="' hover:'+user.color_titulo+' active:'+user.color_titulo+' focus:'+user.color_titulo"></i> </a> </div>
    <div class="mx-1 rounded-full text-black bg-white h-10 w-10 overflow-hidden flex flex-wrap justify-center content-center" v-if="user.preferencias.tiktok!=''&&user.preferencias.tiktok!=null">
        <a :href="'https://'+user.preferencias.tiktok" target="_blank"><i class="text-2xl mx-2 cursor-pointer lni lni-tiktok " :class="' hover:'+user.color_titulo+' active:'+user.color_titulo+' focus:'+user.color_titulo"></i> </a> </div>

</div>
</template>

<script>
//@ts-ignore
import {
    emitter
} from "@/mitt.js";
import generales from '@/functions/generales'
import pagos from "@/Services/pagos";
//import palabras from "@/mixins/palabras";
export default {
    name: "Home",
    components: {

    },
    mixins: [generales],
    props: {

        user: {
            type: Object,

        },

    },
    data() {
        return {

        }
    },

    mounted() {

    },
    methods: {

        OpenLink(row) {
            if (row.tipo == 'url') {

                window.open(row.url);
            }
            if (row.tipo == 'grupo') {
                this.sel_grupo = row;
                this.modalpago = true;
            }
        },

        IsMov() {

            //return true;
            return this.$isMobile()
        },
        ismobile() {
            let check = false;
            (function (a) {
                if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
            })(navigator.userAgent || navigator.vendor || window.opera);
            return check;
        },

    }
}
</script>

<style>
</style>
