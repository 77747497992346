

import initmixin from "@/Services/initmixin";

export default {
 
  //mixins: [initmixin],
 // GET_CLIENTES CREAR_CLIENTES EDITAR_CLIENTES ELIMINAR_CLIENTES
 GET: async function( data){
  let url='/fotos/'+data.text+'/page/'+data.page
  return initmixin.formatrespuesta('get', data,url,true)
},
GETLEXICA: async function( data){

  let url='https://lexica.art/api/v1/search?q='+data.text
  return initmixin.formatrespuesta('getGeneric', data,url)




},

}