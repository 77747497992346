<template>

   <div class="h-screen w-full  ">
   
 <perfilContent v-if="data!=null" :user="data"></perfilContent>

 <div style="z-index:9999999999" class=" w-full flex flex-wrap justify-center fixed  z-index bottom-6 cursor-pointer">
    <a class="text-base font-extrabold   text-white hover:text-yellow-600">Crear cuenta</a>
     </div>

   </div>

   
</template>
<script>
import {
    emitter
} from "@/mitt.js";
import {
    useConfigStore
} from '../stores/config'

import generales from "@/functions/generales";
import infosistem from "@/Services/infosistem";
import users from "@/Services/user";

import perfilContent from "@/components/perfilContent.vue";
import {
    useInfoStore
} from '../stores/info'

import {
    useUserStore
} from '../stores/user'
import {
    useRouter,
    useRoute
} from 'vue-router'
export default {
    components: {
        perfilContent
    },
    mixins: [generales,infosistem],
    data() {
        const user = useUserStore()

        return {
          
          data:null,
          id:0,
            nombre: '',
            descripcion: '',
            precio: '',
            boletos: '',
            activo:true,
           

        }

    },
    setup() {
        const router = useRouter()
        const route = useRoute()
        const user = useUserStore()
        const info = useInfoStore()

        const config = useConfigStore();

      
        return {
            active: 0,
            config,
            info: info.getAll(),
            infoset: info,
           
        
            

        }

    },
    mounted() {

    },
    created() {
        this.getData()
        this.get_info();
    },
    methods: {
        async get_info() {
            console.log('data------')
            let data = await infosistem.GET().then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    let infov = {};
                    response.forEach(element => {
                        infov[element.id] = element.valor;
                    });
                    this.infoset.set(
                        infov
                    );
                    //infoData.value=(infov);
                    //if(response.user.role=='admin'){

                    //this.$router.push('/app/'); 
                    //}

                }
            });
        },
       
        async getData() {

            emitter.emit("loading", true)
            console.log(this.$route)

            if(!this.$route.params.hasOwnProperty('id')){
                emitter.emit("alerta_show",'no existe');
            //this.$router.push('/'); 
            }
            let username=this.$route.params.id;

            await users.GET_ACOUNT({username:username}).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data = response
                    //this.$router.push('/app/'); 
                } else {

                     emitter.emit("alerta_show",'no existe');

                }
                emitter.emit("loading", false)
            });
        },
    

    }
}
</script>
