<template>
<div class="w-full flex flex-wrap h-full  " v-if="getU().hasOwnProperty('foto')">
    <div class="w-full sm:w-full  md:w-full xl:w-4/6 lg:w-4/6 bg-gray-200 xs:pl-16   sm:pl-0    md:pl-16 xl:pl-16 lg:pl-16    h-full flex flex-wrap text-black">
        <div class=" w-full  border-t-2 border-b-2 border-gray-200 h-full">

            <menuTop></menuTop>

            <input type="file" style="display: none" ref="fileInput" accept="image/*" @change="onFileChange" />
            <div :key="indicelinks" class="w-full h-full xs:px-1 sm:px-1 md:px-1 lg:px-2 xl:px-5 pt-5 pb-40 justify-center flex flex-wrap overflow-y-auto">

                <div class="w-full px-10 flex flex-wrap content-start ">
                    <p class="text-xl font-extrabold text-gray-600 text-left px-2 mt-4">Color degradado</p>
                    <div class=" w-full bg-white px-2 py-2 rounded-lg">
                        <div class="w-full flex flex-wrap justify-between" v-for="(row,index) in colores_borde" v-bind:key="index">

                            <template v-for="(r,i) in [50,100,200,300,400,500,600,700,800,900]" v-bind:key="i">

                                <div @click="editarUser($event,'color_degradado','to'+row[r])" class="h-10 w-5 cursor-pointer  mx-1 border-2 border-black hover:opacity-60  mb-1" :class="'bg-gradient-to-t from-transparent to'+row[r]"></div>

                            </template>
                        </div>
                    </div>
                    <p class="hidden text-xl font-extrabold text-gray-600 text-left px-2 mt-4">Temas</p>
                    <div class="hidden w-full bg-white px-2 py-2 rounded-lg ">

                        <div class="w-full border h-auto">
                            <div class=" w-full bg-white px-2 py-2 rounded-lg">
                                <div class="w-full flex flex-wrap justify-between">

                                    <template v-for="(row,index) in temas" v-bind:key="index">

                                        <div class="h-48 w-24 rounded-md bg-white relative">
                                            <img :src="row.img" class="h-full absolute w-full object-center">
                                            <div class="w-8/12 px-2" :class="getU().radius_boton==r?'border-2 border-gray-900 rounded-lg':''">
                                                <div class=" font-extrabold cursor-pointer hover:opacity-50 w-full  py-1 px-2 text-center my-1 flex flex-wrap justify-center content-center text-xs" :class="row.class">
                                                    <div class="h-8 flex flex-wrap justify-center content-center">
                                                        <span>Texto</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p class="text-xl font-extrabold text-gray-600 text-left px-2 mt-4">Fondo de pantalla</p>
                    <div class=" w-full bg-white px-2 py-2 rounded-lg ">
                        <div class="w-full  flex flex-wrap justify-between px-2 py-2">
                            <div @click="sel_img=false" :class="!sel_img?'bg-gray-400 text-white':' bg-gray-100 '" class="h-24 w-5/12  flex flex-wrap justify-center content-center rounded border-2 border-gray-600">
                                <i class="las la-palette text-4xl"></i>
                            </div>
                            <div @click="sel_img=true" :class="sel_img?'bg-gray-400 text-white':' bg-gray-100 '" class="h-24 w-5/12  flex flex-wrap justify-center content-center rounded border-2 border-gray-600">
                                <i class="las la-image text-4xl"></i>
                            </div>
                           

                        </div>
                        <div v-if="sel_img" class="w-full  h-auto bg-gray-100">

                            <div class="w-fulll bg-gray-200 flex flex-wrap justify-center">
                              
                                <div class="w-2/6 px-2 py-2 flex flex-wrap justify-center content-center">
                                    <span @click="busquedagaleria='ia';getFotos()" :class="busquedagaleria=='ia'?'bg-blue-500':'bg-gray-500'" class="px-2 py-2 rounded bg-gray-500 text-xsl font-extrabold text-white">IA Galeria</span>
                                </div>
                                <div class="w-2/6 px-2 py-2 flex flex-wrap justify-center content-center">
                                    <span @click="busquedagaleria='pexels';getFotos()" :class="busquedagaleria=='pexels'?'bg-blue-500':'bg-gray-500'" class="px-2 py-2 rounded text-xsl font-extrabold text-white"> Galeria</span>
                                </div>

                            </div>
                            <div class="w-full flex flex-wrap pt-3 bg-gray-100">
                                <div class="w-full px-2">
                                    <div class="flex justify-between items-center">
                                        <input class="h-12 bg-white rounded w-full text-xs text-center" placeholder="Colores" v-model="query">
                                        <button type="button" class="btn bg-gray-200 hover:bg-gray-300 rounded px-4 text-blue-500 hover:rounded-full py-2 h-12 w-12 font-medium " @click="getFotos()"><i class="las la-search text-base"></i></button>
                                    </div>

                                </div>

                            </div>
                            <div v-if="busquedagaleria=='pexels'" class="w-full flex flex-wrap justify-between my-2 bg-gray-100 pt-4">
                                <template v-for="(row,index) in photos_data" v-bind:key="index">
                                    <img @click="editarUser($event,'imagen_fondo',row.src.original)" class="h-36 hover:opacity-40 bg-black cursor-pointer  w-24 border-2 ml-1 mb-1 rounded-lg border-black  object-cover " :src="row.src.portrait">

                                </template>
                            </div>
                            <div v-if="busquedagaleria=='ia'" class="w-full flex flex-wrap justify-between my-2 bg-gray-100 pt-4">
                                <template v-for="(row,index) in photos_data" v-bind:key="index">
                                    <img @click="editarUser($event,'imagen_fondo',row.src)" class="h-36 hover:opacity-40 bg-black cursor-pointer  w-24 border-2 ml-1 mb-1 rounded-lg border-black  object-cover " :src="row.src">

                                </template>
                            </div>
                        </div>
                        <div v-if="!sel_img" class="w-full border h-auto">
                            <div class=" w-full bg-white px-2 py-2 rounded-lg">
                                <div class="w-full flex flex-wrap justify-between" v-for="(row,index) in colores" v-bind:key="index">

                                    <template v-for="(r,i) in [50,100,200,300,400,500,600,700,800,900]" v-bind:key="i">

                                        <div @click="editarUser($event,'color_fondo',row[r])" class="h-5 w-5 cursor-pointer  mx-1 border-2 border-black hover:opacity-60  mb-1" :class="row[r]+' '+(getU().color_fondo==row[r]?'border-black border-4 rounded-full':' rounded')"></div>

                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="text-xl font-extrabold text-gray-600 text-left px-2 mt-4">Color de nombre de cuenta y descripción</p>
                    <div class=" w-full bg-white px-2 py-2 rounded-lg w-full flex flex-wrap justify-between">
                        <template class="" v-for="(row,index) in colores_text" v-bind:key="index">

                            <div v-for="(r,i) in [50,100,200,300,400,500,600,700,800,900]" v-bind:key="i" @click="editarUser($event,'color_titulo',row[r])" class="h-5 w-10 mx-1  cursor-pointer  hover:opacity-60 text-xs font-extrabold mb-1" :class="row[r]+' '+getU().color_fondo_botton+' '+(getU().color_titulo==row[r]?'border-black border-2 rounded':'  ')">ABC</div>

                        </template>
                    </div>
                    <p class="text-xl font-extrabold text-gray-600 text-left px-2 mt-4">Tipo de botón</p>
                    <div class=" w-full bg-white px-2 py-2 rounded-lg">
                        <div class="w-full  flex flex-wrap justify-start">
                            <p class="text-base w-full font-extrabold text-gray-600 text-left px-2 mt-4">Botón relleno</p>
                            <template v-for="(r,i) in ['rounded-sm bg-opacity-100 shadow0','rounded-lg bg-opacity-100 shadow0','rounded-full bg-opacity-100 shadow0']" v-bind:key="i">
                                <div class="w-4/12 px-2" :class="getU().radius_boton==r?'border-2 border-gray-900 rounded-lg':''">
                                    <div @click="editarUser($event,'radius_boton',r)" class=" font-extrabold cursor-pointer hover:opacity-50 w-full  py-1 px-2 text-center my-1 flex flex-wrap justify-center content-center text-xs" :class="getU().color_fondo_botton+' '+getU().tipo_borde_boton+' '+r+' '+getU().shadow_boton+' '+getU().color_texto_botton+'  '+getU().color_borde+' '+getU().tamano_borde ">
                                        <div class="h-8 flex flex-wrap justify-center content-center">
                                            <span>Texto</span>
                                        </div>
                                    </div>
                                </div>

                            </template>
                            <p class="text-base w-full font-extrabold text-gray-600 text-left px-2 mt-4">Botón relleno con sombra</p>
                            <template v-for="(r,i) in ['rounded-sm bg-opacity-100','rounded-lg bg-opacity-100','rounded-full bg-opacity-100']" v-bind:key="i">
                                <div class="w-4/12 px-2" :class="getU().radius_boton==r?'border-2 border-gray-900 rounded-lg':''">
                                    <div @click="editarUser($event,'radius_boton',r)" class=" font-extrabold cursor-pointer hover:opacity-50 w-full  py-1 px-2 text-center my-1 flex flex-wrap justify-center content-center text-xs" :class="getU().color_fondo_botton+' '+getU().tipo_borde_boton+' '+r+'  '+getU().color_texto_botton+'  '+getU().color_borde+' '+getU().tamano_borde ">
                                        <div class="h-8 flex flex-wrap justify-center content-center">
                                            <span>Texto</span>
                                        </div>
                                    </div>
                                </div>

                            </template>
                            <p class="text-base w-full  font-extrabold text-gray-600 text-left px-2 mt-4">Botón lineal</p>
                            <template v-for="(r,i) in ['rounded-sm bg-opacity-0 shadow0','rounded-lg bg-opacity-0 shadow0','rounded-full bg-opacity-0 shadow0']" v-bind:key="i">
                                <div class="w-4/12 px-2" :class="getU().radius_boton==r?'border-2 border-gray-900 rounded-lg':''">
                                    <div @click="editarUser($event,'radius_boton',r)" class=" font-extrabold cursor-pointer hover:opacity-50 w-full  py-1 px-2 text-center my-1 flex flex-wrap justify-center content-center text-xs" :class="getU().color_fondo_botton+' '+getU().tipo_borde_boton+' '+r+'  '+getU().color_texto_botton+'  '+getU().color_borde+' '+getU().tamano_borde ">
                                        <div class="h-8 flex flex-wrap justify-center content-center">
                                            <span>Texto</span>
                                        </div>
                                    </div>
                                </div>

                            </template>
                            <p class="text-base w-full  font-extrabold text-gray-600 text-left px-2 mt-4">Botón con sombra</p>
                            <template v-for="(r,i) in ['rounded-sm bg-opacity-0','rounded-lg bg-opacity-0','rounded-full bg-opacity-0']" v-bind:key="i">
                                <div class="w-4/12 px-2" :class="getU().radius_boton==r?'border-2 border-gray-900 rounded-lg':''">
                                    <div @click="editarUser($event,'radius_boton',r)" class=" font-extrabold cursor-pointer hover:opacity-50 w-full  py-1 px-2 text-center my-1 flex flex-wrap justify-center content-center text-xs" :class="getU().color_fondo_botton+' '+getU().tipo_borde_boton+' '+r+'  '+getU().color_texto_botton+'  '+getU().color_borde+' '+getU().tamano_borde ">
                                        <div class="h-8 flex flex-wrap justify-center content-center">
                                            <span>Texto</span>
                                        </div>
                                    </div>
                                </div>

                            </template>
                        </div>
                    </div>
                    <p class="text-xl font-extrabold text-gray-600 text-left px-2 mt-4">Tipo de borde</p>
                    <div class=" w-full bg-white px-2 py-2 rounded-lg">
                        <div class="w-full  flex flex-wrap justify-start">

                            <template v-for="(r,i) in ['border-solid','border-dashed','border-dotted','border-double']" v-bind:key="i">
                                <div class="w-4/12 px-2" :class="getU().tipo_borde_boton==r?'border-2 border-gray-900 rounded-lg':''">
                                    <div @click="editarUser($event,'tipo_borde_boton',r)" class=" font-extrabold cursor-pointer hover:opacity-50 w-full  py-1 px-2 text-center my-1 flex flex-wrap justify-center content-center text-xs" :class="getU().color_fondo_botton+' '+getU().radius_boton+' '+r+'  '+getU().color_texto_botton+'  '+getU().color_borde+' '+getU().tamano_borde ">
                                        <div class="h-8 flex flex-wrap justify-center content-center">
                                            <span>Texto</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <p class="text-xl font-extrabold text-gray-600 text-left px-2 mt-4">Color de texto de botón</p>
                    <div class=" w-full bg-white px-2 py-2 rounded-lg w-full flex flex-wrap justify-between">
                        <template class="" v-for="(row,index) in colores_text" v-bind:key="index">

                            <div v-for="(r,i) in [50,100,200,300,400,500,600,700,800,900]" v-bind:key="i" @click="editarUser($event,'color_texto_botton',row[r])" class="h-5 w-10 mx-1  cursor-pointer  hover:opacity-60 text-xs font-extrabold mb-1" :class="row[r]+' '+getU().color_fondo_botton+' '+(getU().color_texto_botton==row[r]?'border-black border-2 rounded':'  ')">ABC</div>

                        </template>
                    </div>
                    <p class="text-xl font-extrabold text-gray-600 text-left px-2 mt-4">Color de borde</p>
                    <div class=" w-full bg-white px-2 py-2 rounded-lg">
                        <div class="w-full flex flex-wrap justify-between" v-for="(row,index) in colores_borde" v-bind:key="index">

                            <template v-for="(r,i) in [50,100,200,300,400,500,600,700,800,900]" v-bind:key="i">

                                <div @click="editarUser($event,'color_borde','border'+row[r]+' shadowc'+row[r])" class="h-5 w-5 cursor-pointer  mx-1 border-2 border-black hover:opacity-60  mb-1" :class="'border'+row[r]+' shadowc'+row[r]+' '+(getU().color_borde==('border'+row[r]+' shadowc'+row[r])?'border-black border-4 rounded-full':' rounded')"></div>

                            </template>
                        </div>
                    </div>
                    <p class="text-xl font-extrabold text-gray-600 text-left px-2 mt-4">Color de fondo de botón</p>
                    <div class=" w-full bg-white px-2 py-2 rounded-lg">
                        <div class="w-full flex flex-wrap justify-between" v-for="(row,index) in colores" v-bind:key="index">

                            <template v-for="(r,i) in [50,100,200,300,400,500,600,700,800,900]" v-bind:key="i">

                                <div @click="editarUser($event,'color_fondo_botton',row[r])" class="h-5 w-5 cursor-pointer  mx-1 border-2 border-black hover:opacity-60  mb-1" :class="row[r]+' '+(getU().color_fondo_botton==row[r]?'border-black border-4 rounded-full':' rounded')"></div>

                            </template>
                        </div>
                    </div>

                    <!-- <div class="" v-for="(row,index) in colores_array" v-bind:key="index">
                        <div class="h-10 w-10  mx-1 " :class="row.color+''+(getU().color_fondo_botton==row.color?'border-purple-500 border-3':'border-0 border-black')"></div>
                    </div>-->
                </div>

            </div>

        </div>
        <div class="w-full flex flex-wrap justify-center content-center fixed right-2 bottom-2 z-30">
            <button @click="previewShow=!previewShow" class="block sm:block md:hidden lg:hidden px-6 text-white xl:hidden h-12 w-auto  bg-pink-600   rounded-full flex flex-wrap justify-center contnet-center text-xl">
                <div class="h-full w-full flex flex-wrap justify-center content-center">
                    <i v-if="previewShow" class="las la-times"></i>
                    <i v-else class="las la-eye text-xl mr-1"></i>
                    <p v-if="!previewShow" class="text-base">Preview</p>
                </div>

            </button>
        </div>
    </div>
    <div class="sm:w-full w-full md:w-full xl:w-2/6 lg:w-2/6 bg-gray-100 h-full hidden sm:hidden md:hidden lg:flex xl:flex  flex flex-wrap content-center justify-center ">
        <div style="border-radius: 41px;width:360px!important;height:720px!important" class="shadow  overflow-hidden  h-5/6 border-8 border-black">
            <perfilContent :user="getU()"></perfilContent>

        </div>
    </div>
    <div :class="previewShow?'h-full':'h-0'" class="z-20 fixed top-0 left-0 w-full bg-black bg-opacity-50  flex flex-wrap content-center justify-center ">
        <div style="border-radius: 41px;width:360px!important;" class="shadow  overflow-hidden  h-5/6 border-8 -mt-8 border-black">
            <perfilContent :user="getU()"></perfilContent>

        </div>
    </div>

</div>
</template>

<script>
import {
    emitter
} from "@/mitt.js";
import {
    useConfigStore
} from '@/stores/config'
import generales from "@/functions/generales";
import colores from "@/functions/colors";
import infosistem from "@/Services/infosistem";
import links from "@/Services/links";
import fotos from "@/Services/fotos";
import users from "@/Services/user";

import perfilContent from "@/components/perfilContent.vue";

import menuTop from "@/components/menuTop.vue";
import {
    createClient
} from 'pexels';
import {
    useInfoStore
} from '@/stores/info'

import {
    useUserStore
} from '@/stores/user'
import {
    useRouter,
    useRoute
} from 'vue-router'
export default {
    components: {
        perfilContent,
        menuTop
    },
    mixins: [generales, infosistem, colores],
    data() {
        const user = useUserStore()
        const info = useInfoStore()
        return {
            busquedagaleria: 'pexels',
            previewShow: false,

            id: 0,

            showpass: false,
            user: user,
            indicelinks: false,
            info,
            data: [],
            drag: false,
            sel_img: false,
            temas: [{
                    img: '',
                    class: ''
                }

            ],
            link: {
                id: 0
            },
            photos_data: '',
            data_file: null,

            links_array: [],
            query: 'Ocean',
            page: 1

        }

    },
    setup() {
       
        const route = useRoute()

        const config = useConfigStore();

        return {

            config,

        }

    },
    mounted() {

        //this.photos_data = photos;

        this.getEnlaces();
        this.getFotos();
        if (this.getU().color_fondo == '') {
            this.sel_img = true
        } else {
            this.sel_img = false
        }

    },
    created() {
        if(!this.user.getAll().hasOwnProperty('name')){
            this.salir();
        }
        //this.getData()
        // this.get_info();
    },
    methods: {
        salir(){
            emitter.emit("loading", false)
            this.user.limpiar();
            this.$router.push('/');
        },
        ismobile() {
            let check = false;
            (function (a) {
                if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
            })(navigator.userAgent || navigator.vendor || window.opera);
            return check;
        },
        getImage(url) {
            if (!this.ismobile()) {
                return url + '?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800'
            } else {
                return url + '?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200'
            }

        },
        getU() {
            return this.user.getAll();
        },
        setColorBorde(textcolor) {
            let sp = textcolor.replaceAll('bg', 'border')
            return sp
        },
        setColorShadow(textcolor) {
            let sp = textcolor.replaceAll('bg', 'shadow')
            return sp
        },
        setColorText(textcolor) {
            let sp = textcolor.replaceAll('text', 'bg')
            return sp
        },

        log(id) {

            console.log(id)
            this.$router.push(id)
        },
        isURLValid: function (inputUrl) {
            let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return regex.test(inputUrl)
        },

        async editarUser($event, key, valor) {
            let el = this.getU();
            switch (key) {
                case 'color_fondo_botton':
                    el.color_fondo_botton = valor;
                    break;
                case 'color_texto_botton':
                    el.color_texto_botton = valor;
                    break;
                case 'color_titulo':
                    el.color_titulo = valor;
                    break;

                case 'color_descripcion':
                    el.color_descripcion = valor;
                    break;
                case 'tipo_borde_boton':
                    el.tipo_borde_boton = valor;
                    break;
                case 'shadow_boton':
                    el.shadow_boton = valor;
                    break;

                case 'radius_boton':
                    el.radius_boton = valor;
                    break;
                case 'color_borde':
                    el.color_borde = valor;
                    break;
                case 'tamano_borde':
                    el.tamano_borde = valor;
                    break;
                case 'color_fondo':
                    el.color_fondo = valor;
                    el.imagen_fondo = null;
                    break;
                    case 'color_degradado':
                    el.degradado = valor;
                   
                    break;

                    
                case 'imagen_fondo':
                    el.imagen_fondo = valor;
                    el.color_fondo = null;
                    break;

                case value:

                    break;

                default:
                    break;
            }

            emitter.emit("loading", true)
            let dat = {
                color_fondo_botton: el.color_fondo_botton,
                color_texto_botton: el.color_texto_botton,
                color_descripcion: el.color_descripcion,
                tipo_borde_boton: el.tipo_borde_boton,
                shadow_boton: el.shadow_boton,
                color_fondo: el.color_fondo,
                color_borde: el.color_borde,
                tamano_borde: el.tamano_borde,
                radius_boton: el.radius_boton,
                imagen_fondo: el.imagen_fondo,
                degradado: el.degradado,

                color_titulo: el.color_titulo,

                id: el.id

            }
            let data = await users.EDITAR_APARIENCIA(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {

                    this.user.setApariencia(response)

                } else {
                    emitter.emit("alerta_show", response.error);
                }
                emitter.emit("loading", false)
            });
        },
        async getEnlaces() {

            emitter.emit("loading", true)

            let data = await links.GET().then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {

                    this.links_array = response;

                } else {
                    emitter.emit("alerta_show", response.error);
                }
                emitter.emit("loading", false)
            });
        },
        async getFotos() {

            emitter.emit("loading", true)
            let dat = {
                text: this.query,
                page: this.page
            }

            if (this.busquedagaleria == 'ia') {

                let data = await fotos.GETLEXICA(dat).then((response) => {
                    if (response != null && !response.hasOwnProperty('error')) {

                        this.photos_data = response.images;

                    } else {
                        emitter.emit("alerta_show", response.error);
                    }
                    emitter.emit("loading", false)
                });
            } else {

                let data = await fotos.GET(dat).then((response) => {
                    if (response != null && !response.hasOwnProperty('error')) {

                        this.photos_data = response.photos;

                    } else {
                        emitter.emit("alerta_show", response.error);
                    }
                    emitter.emit("loading", false)
                });
            }

        },

        onPickFile(link) {
            this.link = link
            this.$refs.fileInput.click();
            //this.$refs.fileInput.click()
        },
        async onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;

            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.imageUrl = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.data_file = files[0];
            this.subirarchivo();
            console.log(e)

        },
        async subirarchivo() {
            this.loader = true;
            this.$root.$emit("loading", true);

            emitter.emit("loading", true)
            var formData = new FormData();

            formData.append("doc", this.data_file, );
            let dat = {
                id: this.link.id,
                data: formData
            }
            await links.IMAGEN(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    this.data_file = null;

                    this.links_array.forEach(element => {
                        if (element.id == response.id) {
                            element.imagen = null;
                            element.imagen = response.imagen;

                        }
                    });

                    //this.links.push(response)

                    //this.productos_data = response
                    //this.$router.push('/app/'); 
                } else {
                    this.data_file = null;
                    // emitter.emit("alerta_show",response.msg);

                }
                emitter.emit("loading", false)
            });

        },

    }
}
</script>

<style>
.flip-list-move {
    transition: transform 0.5s;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
</style>
