export default {
  data() {
    return {
      iconos: [
        { icon: "lab la-accessible-icon" },

        { icon: "las la-american-sign-language-interpreting" },

        { icon: "las la-assistive-listening-systems" },

        { icon: "las la-audio-description" },

        { icon: "las la-blind" },

        { icon: "las la-braille" },

        { icon: "las la-closed-captioning" },

        { icon: "las la-deaf" },

        { icon: "las la-low-vision" },

        { icon: "las la-phone-volume" },

        { icon: "las la-question-circle" },

        { icon: "las la-sign-language" },

        { icon: "las la-tty" },

        { icon: "las la-universal-access" },

        { icon: "las la-wheelchair" },

        { icon: "las la-bell" },

        { icon: "las la-bell-slash" },

        { icon: "las la-exclamation" },

        { icon: "las la-exclamation-circle" },

        { icon: "las la-exclamation-triangle" },

        { icon: "las la-radiation" },

        { icon: "las la-radiation-alt" },

        { icon: "las la-skull-crossbones" },

        { icon: "las la-cat" },

        { icon: "las la-crow" },

        { icon: "las la-dog" },

        { icon: "las la-dove" },

        { icon: "las la-dragon" },

        { icon: "las la-feather" },

        { icon: "las la-feather-alt" },

        { icon: "las la-fish" },

        { icon: "las la-frog" },

        { icon: "las la-hippo" },

        { icon: "las la-horse" },

        { icon: "las la-horse-head" },

        { icon: "las la-kiwi-bird" },

        { icon: "las la-otter" },

        { icon: "las la-paw" },

        { icon: "las la-spider" },

        { icon: "las la-angle-double-down" },

        { icon: "las la-angle-double-left" },

        { icon: "las la-angle-double-right" },

        { icon: "las la-angle-double-up" },

        { icon: "las la-angle-down" },

        { icon: "las la-angle-left" },

        { icon: "las la-angle-right" },

        { icon: "las la-angle-up" },

        { icon: "las la-arrow-alt-circle-down" },

        { icon: "las la-arrow-alt-circle-left" },

        { icon: "las la-arrow-alt-circle-right" },

        { icon: "las la-arrow-alt-circle-up" },

        { icon: "las la-arrow-circle-down" },

        { icon: "las la-arrow-circle-left" },

        { icon: "las la-arrow-circle-right" },

        { icon: "las la-arrow-circle-up" },

        { icon: "las la-arrow-down" },

        { icon: "las la-arrow-left" },

        { icon: "las la-arrow-right" },

        { icon: "las la-arrow-up" },

        { icon: "las la-arrows-alt" },

        { icon: "las la-arrows-alt-h" },

        { icon: "las la-arrows-alt-v" },

        { icon: "las la-caret-down" },

        { icon: "las la-caret-left" },

        { icon: "las la-caret-right" },

        { icon: "las la-caret-square-down" },

        { icon: "las la-caret-square-left" },

        { icon: "las la-caret-square-right" },

        { icon: "las la-caret-square-up" },

        { icon: "las la-caret-up" },

        { icon: "las la-cart-arrow-down" },

        { icon: "las la-chart-line" },

        { icon: "las la-chevron-circle-down" },

        { icon: "las la-chevron-circle-left" },

        { icon: "las la-chevron-circle-right" },

        { icon: "las la-chevron-circle-up" },

        { icon: "las la-chevron-down" },

        { icon: "las la-chevron-left" },

        { icon: "las la-chevron-right" },

        { icon: "las la-chevron-up" },

        { icon: "las la-cloud-download-alt" },

        { icon: "las la-cloud-upload-alt" },

        { icon: "las la-compress-arrows-alt" },

        { icon: "las la-download" },

        { icon: "las la-exchange-alt" },

        { icon: "las la-expand-arrows-alt" },

        { icon: "las la-external-link-alt" },

        { icon: "las la-external-link-square-alt" },

        { icon: "las la-hand-point-down" },

        { icon: "las la-hand-point-left" },

        { icon: "las la-hand-point-right" },

        { icon: "las la-hand-point-up" },

        { icon: "las la-hand-pointer" },

        { icon: "las la-history" },

        { icon: "las la-level-down-alt" },

        { icon: "las la-level-up-alt" },

        { icon: "las la-location-arrow" },

        { icon: "las la-long-arrow-alt-down" },

        { icon: "las la-long-arrow-alt-left" },

        { icon: "las la-long-arrow-alt-right" },

        { icon: "las la-long-arrow-alt-up" },

        { icon: "las la-mouse-pointer" },

        { icon: "las la-play" },

        { icon: "las la-random" },

        { icon: "las la-recycle" },

        { icon: "las la-redo" },

        { icon: "las la-redo-alt" },

        { icon: "las la-reply" },

        { icon: "las la-reply-all" },

        { icon: "las la-retweet" },

        { icon: "las la-share" },

        { icon: "las la-share-square" },

        { icon: "las la-sign-in-alt" },

        { icon: "las la-sign-out-alt" },

        { icon: "las la-sort" },

        { icon: "las la-sort-alpha-down" },

        { icon: "las la-sort-alpha-down-alt" },

        { icon: "las la-sort-alpha-up" },

        { icon: "las la-sort-alpha-up-alt" },

        { icon: "las la-sort-amount-down" },

        { icon: "las la-sort-amount-down-alt" },

        { icon: "las la-sort-amount-up" },

        { icon: "las la-sort-amount-up-alt" },

        { icon: "las la-sort-down" },

        { icon: "las la-sort-numeric-down" },

        { icon: "las la-sort-numeric-down-alt" },

        { icon: "las la-sort-numeric-up" },

        { icon: "las la-sort-numeric-up-alt" },

        { icon: "las la-sort-up" },

        { icon: "las la-sync" },

        { icon: "las la-sync-alt" },

        { icon: "las la-text-height" },

        { icon: "las la-text-width" },

        { icon: "las la-undo" },

        { icon: "las la-undo-alt" },

        { icon: "las la-upload" },

        { icon: "las la-backward" },

        { icon: "las la-broadcast-tower" },

        { icon: "las la-circle" },

        { icon: "las la-compress" },

        { icon: "las la-eject" },

        { icon: "las la-expand" },

        { icon: "las la-fast-backward" },

        { icon: "las la-fast-forward" },

        { icon: "las la-file-audio" },

        { icon: "las la-file-video" },

        { icon: "las la-film" },

        { icon: "las la-forward" },

        { icon: "las la-headphones" },

        { icon: "las la-microphone" },

        { icon: "las la-microphone-alt" },

        { icon: "las la-microphone-alt-slash" },

        { icon: "las la-microphone-slash" },

        { icon: "las la-music" },

        { icon: "las la-pause" },

        { icon: "las la-pause-circle" },

        { icon: "las la-photo-video" },

        { icon: "las la-play-circle" },

        { icon: "las la-podcast" },

        { icon: "las la-rss" },

        { icon: "las la-rss-square" },

        { icon: "las la-step-backward" },

        { icon: "las la-step-forward" },

        { icon: "las la-stop" },

        { icon: "las la-stop-circle" },

        { icon: "las la-tv" },

        { icon: "las la-video" },

        { icon: "las la-volume-down" },

        { icon: "las la-volume-mute" },

        { icon: "las la-volume-off" },

        { icon: "las la-volume-up" },

        { icon: "lab la-youtube" },

        { icon: "las la-air-freshener" },

        { icon: "las la-ambulance" },

        { icon: "las la-bus" },

        { icon: "las la-bus-alt" },

        { icon: "las la-car" },

        { icon: "las la-car-alt" },

        { icon: "las la-car-battery" },

        { icon: "las la-car-crash" },

        { icon: "las la-car-side" },

        { icon: "las la-charging-station" },

        { icon: "las la-gas-pump" },

        { icon: "las la-motorcycle" },

        { icon: "las la-oil-can" },

        { icon: "las la-shuttle-van" },

        { icon: "las la-tachometer-alt" },

        { icon: "las la-taxi" },

        { icon: "las la-truck" },

        { icon: "las la-truck-monster" },

        { icon: "las la-truck-pickup" },

        { icon: "las la-apple-alt" },

        { icon: "las la-campground" },

        { icon: "las la-cloud-sun" },

        { icon: "las la-drumstick-bite" },

        { icon: "las la-football-ball" },

        { icon: "las la-hiking" },

        { icon: "las la-mountain" },

        { icon: "las la-tractor" },

        { icon: "las la-tree" },

        { icon: "las la-wind" },

        { icon: "las la-wine-bottle" },

        { icon: "las la-beer" },

        { icon: "las la-blender" },

        { icon: "las la-cocktail" },

        { icon: "las la-coffee" },

        { icon: "las la-flask" },

        { icon: "las la-glass-cheers" },

        { icon: "las la-glass-martini" },

        { icon: "las la-glass-martini-alt" },

        { icon: "las la-glass-whiskey" },

        { icon: "las la-mug-hot" },

        { icon: "las la-wine-glass" },

        { icon: "las la-wine-glass-alt" },

        { icon: "lab la-500px" },

        { icon: "lab la-accusoft" },

        { icon: "lab la-adn" },

        { icon: "lab la-adobe" },

        { icon: "lab la-adversal" },

        { icon: "lab la-affiliatetheme" },

        { icon: "lab la-airbnb" },

        { icon: "lab la-algolia" },

        { icon: "lab la-amazon" },

        { icon: "lab la-amilia" },

        { icon: "lab la-android" },

        { icon: "lab la-angellist" },

        { icon: "lab la-angrycreative" },

        { icon: "lab la-angular" },

        { icon: "lab la-app-store" },

        { icon: "lab la-app-store-ios" },

        { icon: "lab la-apper" },

        { icon: "lab la-apple" },

        { icon: "lab la-artstation" },

        { icon: "lab la-asymmetrik" },

        { icon: "lab la-atlassian" },

        { icon: "lab la-audible" },

        { icon: "lab la-autoprefixer" },

        { icon: "lab la-avianex" },

        { icon: "lab la-aviato" },

        { icon: "lab la-aws" },

        { icon: "lab la-bandcamp" },

        { icon: "lab la-battle-net" },

        { icon: "lab la-behance" },

        { icon: "lab la-behance-square" },

        { icon: "lab la-bimobject" },

        { icon: "lab la-bitbucket" },

        { icon: "lab la-bity" },

        { icon: "lab la-black-tie" },

        { icon: "lab la-blackberry" },

        { icon: "lab la-blogger" },

        { icon: "lab la-blogger-b" },

        { icon: "lab la-bootstrap" },

        { icon: "lab la-buffer" },

        { icon: "lab la-buromobelexperte" },

        { icon: "lab la-buy-n-large" },

        { icon: "lab la-buysellads" },

        { icon: "lab la-canadian-maple-leaf" },

        { icon: "lab la-centercode" },

        { icon: "lab la-centos" },

        { icon: "lab la-chrome" },

        { icon: "lab la-chromecast" },

        { icon: "lab la-cloudscale" },

        { icon: "lab la-cloudsmith" },

        { icon: "lab la-cloudversify" },

        { icon: "lab la-codepen" },

        { icon: "lab la-codiepie" },

        { icon: "lab la-confluence" },

        { icon: "lab la-connectdevelop" },

        { icon: "lab la-contao" },

        { icon: "lab la-cotton-bureau" },

        { icon: "lab la-cpanel" },

        { icon: "lab la-creative-commons" },

        { icon: "lab la-creative-commons-by" },

        { icon: "lab la-creative-commons-nc" },

        { icon: "lab la-creative-commons-nc-eu" },

        { icon: "lab la-creative-commons-nc-jp" },

        { icon: "lab la-creative-commons-nd" },

        { icon: "lab la-creative-commons-pd" },

        { icon: "lab la-creative-commons-pd-alt" },

        { icon: "lab la-creative-commons-remix" },

        { icon: "lab la-creative-commons-sa" },

        { icon: "lab la-creative-commons-sampling" },

        { icon: "lab la-creative-commons-sampling-plus" },

        { icon: "lab la-creative-commons-share" },

        { icon: "lab la-creative-commons-zero" },

        { icon: "lab la-css3" },

        { icon: "lab la-css3-alt" },

        { icon: "lab la-cuttlefish" },

        { icon: "lab la-dashcube" },

        { icon: "lab la-delicious" },

        { icon: "lab la-deploydog" },

        { icon: "lab la-deskpro" },

        { icon: "lab la-dev" },

        { icon: "lab la-deviantart" },

        { icon: "lab la-dhl" },

        { icon: "lab la-diaspora" },

        { icon: "lab la-digg" },

        { icon: "lab la-digital-ocean" },

        { icon: "lab la-discord" },

        { icon: "lab la-discourse" },

        { icon: "lab la-dochub" },

        { icon: "lab la-docker" },

        { icon: "lab la-draft2digital" },

        { icon: "lab la-dribbble" },

        { icon: "lab la-dribbble-square" },

        { icon: "lab la-dropbox" },

        { icon: "lab la-drupal" },

        { icon: "lab la-dyalog" },

        { icon: "lab la-earlybirds" },

        { icon: "lab la-ebay" },

        { icon: "lab la-edge" },

        { icon: "lab la-elementor" },

        { icon: "lab la-ello" },

        { icon: "lab la-ember" },

        { icon: "lab la-empire" },

        { icon: "lab la-envira" },

        { icon: "lab la-erlang" },

        { icon: "lab la-etsy" },

        { icon: "lab la-evernote" },

        { icon: "lab la-expeditedssl" },

        { icon: "lab la-facebook" },

        { icon: "lab la-facebook-f" },

        { icon: "lab la-facebook-messenger" },

        { icon: "lab la-facebook-square" },

        { icon: "lab la-fedex" },

        { icon: "lab la-fedora" },

        { icon: "lab la-figma" },

        { icon: "lab la-firefox" },

        { icon: "lab la-first-order" },

        { icon: "lab la-first-order-alt" },

        { icon: "lab la-firstdraft" },

        { icon: "lab la-flickr" },

        { icon: "lab la-flipboard" },

        { icon: "lab la-fly" },

        { icon: "lab la-font-awesome" },

        { icon: "lab la-font-awesome-alt" },

        { icon: "lab la-font-awesome-flag" },

        { icon: "lab la-fonticons" },

        { icon: "lab la-fonticons-fi" },

        { icon: "lab la-fort-awesome" },

        { icon: "lab la-fort-awesome-alt" },

        { icon: "lab la-forumbee" },

        { icon: "lab la-foursquare" },

        { icon: "lab la-free-code-camp" },

        { icon: "lab la-freebsd" },

        { icon: "lab la-fulcrum" },

        { icon: "lab la-get-pocket" },

        { icon: "lab la-git" },

        { icon: "lab la-git-alt" },

        { icon: "lab la-git-square" },

        { icon: "lab la-github" },

        { icon: "lab la-github-alt" },

        { icon: "lab la-github-square" },

        { icon: "lab la-gitkraken" },

        { icon: "lab la-gitlab" },

        { icon: "lab la-gitter" },

        { icon: "lab la-glide" },

        { icon: "lab la-glide-g" },

        { icon: "lab la-gofore" },

        { icon: "lab la-goodreads" },

        { icon: "lab la-goodreads-g" },

        { icon: "lab la-google" },

        { icon: "lab la-google-drive" },

        { icon: "lab la-google-play" },

        { icon: "lab la-google-plus" },

        { icon: "lab la-google-plus-g" },

        { icon: "lab la-google-plus-square" },

        { icon: "lab la-gratipay" },

        { icon: "lab la-grav" },

        { icon: "lab la-gripfire" },

        { icon: "lab la-grunt" },

        { icon: "lab la-gulp" },

        { icon: "lab la-hacker-news" },

        { icon: "lab la-hacker-news-square" },

        { icon: "lab la-hackerrank" },

        { icon: "lab la-hips" },

        { icon: "lab la-hire-a-helper" },

        { icon: "lab la-hooli" },

        { icon: "lab la-hornbill" },

        { icon: "lab la-hotjar" },

        { icon: "lab la-houzz" },

        { icon: "lab la-html5" },

        { icon: "lab la-hubspot" },

        { icon: "lab la-imdb" },

        { icon: "lab la-instagram" },

        { icon: "lab la-intercom" },

        { icon: "lab la-internet-explorer" },

        { icon: "lab la-invision" },

        { icon: "lab la-ioxhost" },

        { icon: "lab la-itch-io" },

        { icon: "lab la-itunes" },

        { icon: "lab la-itunes-note" },

        { icon: "lab la-java" },

        { icon: "lab la-jenkins" },

        { icon: "lab la-jira" },

        { icon: "lab la-joget" },

        { icon: "lab la-joomla" },

        { icon: "lab la-js" },

        { icon: "lab la-js-square" },

        { icon: "lab la-jsfiddle" },

        { icon: "lab la-kaggle" },

        { icon: "lab la-keybase" },

        { icon: "lab la-keycdn" },

        { icon: "lab la-kickstarter" },

        { icon: "lab la-kickstarter-k" },

        { icon: "lab la-korvue" },

        { icon: "lab la-laravel" },

        { icon: "lab la-lastfm" },

        { icon: "lab la-lastfm-square" },

        { icon: "lab la-leanpub" },

        { icon: "lab la-less" },

        { icon: "lab la-line" },

        { icon: "lab la-linkedin" },

        { icon: "lab la-linkedin-in" },

        { icon: "lab la-linode" },

        { icon: "lab la-linux" },

        { icon: "lab la-lyft" },

        { icon: "lab la-magento" },

        { icon: "lab la-mailchimp" },

        { icon: "lab la-mandalorian" },

        { icon: "lab la-markdown" },

        { icon: "lab la-mastodon" },

        { icon: "lab la-maxcdn" },

        { icon: "lab la-mdb" },

        { icon: "lab la-medapps" },

        { icon: "lab la-medium" },

        { icon: "lab la-medium-m" },

        { icon: "lab la-medrt" },

        { icon: "lab la-meetup" },

        { icon: "lab la-megaport" },

        { icon: "lab la-mendeley" },

        { icon: "lab la-microsoft" },

        { icon: "lab la-mix" },

        { icon: "lab la-mixcloud" },

        { icon: "lab la-mizuni" },

        { icon: "lab la-modx" },

        { icon: "lab la-monero" },

        { icon: "lab la-neos" },

        { icon: "lab la-nimblr" },

        { icon: "lab la-node" },

        { icon: "lab la-node-js" },

        { icon: "lab la-npm" },

        { icon: "lab la-ns8" },

        { icon: "lab la-nutritionix" },

        { icon: "lab la-odnoklassniki" },

        { icon: "lab la-odnoklassniki-square" },

        { icon: "lab la-opencart" },

        { icon: "lab la-openid" },

        { icon: "lab la-opera" },

        { icon: "lab la-optin-monster" },

        { icon: "lab la-orcid" },

        { icon: "lab la-osi" },

        { icon: "lab la-page4" },

        { icon: "lab la-pagelines" },

        { icon: "lab la-palfed" },

        { icon: "lab la-patreon" },

        { icon: "lab la-periscope" },

        { icon: "lab la-phabricator" },

        { icon: "lab la-phoenix-framework" },

        { icon: "lab la-phoenix-squadron" },

        { icon: "lab la-php" },

        { icon: "lab la-pied-piper" },

        { icon: "lab la-pied-piper-alt" },

        { icon: "lab la-pied-piper-hat" },

        { icon: "lab la-pied-piper-pp" },

        { icon: "lab la-pinterest" },

        { icon: "lab la-pinterest-p" },

        { icon: "lab la-pinterest-square" },

        { icon: "lab la-product-hunt" },

        { icon: "lab la-pushed" },

        { icon: "lab la-python" },

        { icon: "lab la-qq" },

        { icon: "lab la-quinscape" },

        { icon: "lab la-quora" },

        { icon: "lab la-r-project" },

        { icon: "lab la-raspberry-pi" },

        { icon: "lab la-ravelry" },

        { icon: "lab la-react" },

        { icon: "lab la-reacteurope" },

        { icon: "lab la-readme" },

        { icon: "lab la-rebel" },

        { icon: "lab la-red-river" },

        { icon: "lab la-reddit" },

        { icon: "lab la-reddit-alien" },

        { icon: "lab la-reddit-square" },

        { icon: "lab la-redhat" },

        { icon: "lab la-renren" },

        { icon: "lab la-replyd" },

        { icon: "lab la-researchgate" },

        { icon: "lab la-resolving" },

        { icon: "lab la-rev" },

        { icon: "lab la-rocketchat" },

        { icon: "lab la-rockrms" },

        { icon: "lab la-safari" },

        { icon: "lab la-salesforce" },

        { icon: "lab la-sass" },

        { icon: "lab la-schlix" },

        { icon: "lab la-scribd" },

        { icon: "lab la-searchengin" },

        { icon: "lab la-sellcast" },

        { icon: "lab la-sellsy" },

        { icon: "lab la-servicestack" },

        { icon: "lab la-shirtsinbulk" },

        { icon: "lab la-shopware" },

        { icon: "lab la-simplybuilt" },

        { icon: "lab la-sistrix" },

        { icon: "lab la-sith" },

        { icon: "lab la-sketch" },

        { icon: "lab la-skyatlas" },

        { icon: "lab la-skype" },

        { icon: "lab la-slack" },

        { icon: "lab la-slack-hash" },

        { icon: "lab la-slideshare" },

        { icon: "lab la-snapchat" },

        { icon: "lab la-snapchat-ghost" },

        { icon: "lab la-snapchat-square" },

        { icon: "lab la-sourcetree" },

        { icon: "lab la-speakap" },

        { icon: "lab la-speaker-deck" },

        { icon: "lab la-squarespace" },

        { icon: "lab la-stack-exchange" },

        { icon: "lab la-stack-overflow" },

        { icon: "lab la-stackpath" },

        { icon: "lab la-staylinked" },

        { icon: "lab la-sticker-mule" },

        { icon: "lab la-strava" },

        { icon: "lab la-studiovinari" },

        { icon: "lab la-stumbleupon" },

        { icon: "lab la-stumbleupon-circle" },

        { icon: "lab la-superpowers" },

        { icon: "lab la-supple" },

        { icon: "lab la-suse" },

        { icon: "lab la-swift" },

        { icon: "lab la-symfony" },

        { icon: "lab la-teamspeak" },

        { icon: "lab la-telegram" },

        { icon: "lab la-telegram-plane" },

        { icon: "lab la-tencent-weibo" },

        { icon: "lab la-the-red-yeti" },

        { icon: "lab la-themeco" },

        { icon: "lab la-themeisle" },

        { icon: "lab la-think-peaks" },

        { icon: "lab la-trade-federation" },

        { icon: "lab la-trello" },

        { icon: "lab la-tripadvisor" },

        { icon: "lab la-tumblr" },

        { icon: "lab la-tumblr-square" },

        { icon: "lab la-twitter" },

        { icon: "lab la-twitter-square" },

        { icon: "lab la-typo3" },

        { icon: "lab la-uber" },

        { icon: "lab la-ubuntu" },

        { icon: "lab la-uikit" },

        { icon: "lab la-umbraco" },

        { icon: "lab la-uniregistry" },

        { icon: "lab la-untappd" },

        { icon: "lab la-ups" },

        { icon: "lab la-usb" },

        { icon: "lab la-usps" },

        { icon: "lab la-ussunnah" },

        { icon: "lab la-vaadin" },

        { icon: "lab la-viacoin" },

        { icon: "lab la-viadeo" },

        { icon: "lab la-viadeo-square" },

        { icon: "lab la-viber" },

        { icon: "lab la-vimeo" },

        { icon: "lab la-vimeo-square" },

        { icon: "lab la-vimeo-v" },

        { icon: "lab la-vine" },

        { icon: "lab la-vk" },

        { icon: "lab la-vnv" },

        { icon: "lab la-vuejs" },

        { icon: "lab la-waze" },

        { icon: "lab la-weebly" },

        { icon: "lab la-weibo" },

        { icon: "lab la-weixin" },

        { icon: "lab la-whatsapp" },

        { icon: "lab la-whatsapp-square" },

        { icon: "lab la-whmcs" },

        { icon: "lab la-wikipedia-w" },

        { icon: "lab la-windows" },

        { icon: "lab la-wix" },

        { icon: "lab la-wolf-pack-battalion" },

        { icon: "lab la-wordpress" },

        { icon: "lab la-wordpress-simple" },

        { icon: "lab la-wpbeginner" },

        { icon: "lab la-wpexplorer" },

        { icon: "lab la-wpforms" },

        { icon: "lab la-wpressr" },

        { icon: "lab la-xing" },

        { icon: "lab la-xing-square" },

        { icon: "lab la-y-combinator" },

        { icon: "lab la-yahoo" },

        { icon: "lab la-yammer" },

        { icon: "lab la-yandex" },

        { icon: "lab la-yandex-international" },

        { icon: "lab la-yarn" },

        { icon: "lab la-yelp" },

        { icon: "lab la-yoast" },

        { icon: "lab la-youtube-square" },

        { icon: "lab la-zhihu" },

        { icon: "las la-archway" },

        { icon: "las la-building" },

        { icon: "las la-church" },

        { icon: "las la-city" },

        { icon: "las la-clinic-medical" },

        { icon: "las la-dungeon" },

        { icon: "las la-gopuram" },

        { icon: "las la-home" },

        { icon: "las la-hospital" },

        { icon: "las la-hospital-alt" },

        { icon: "las la-hotel" },

        { icon: "las la-house-damage" },

        { icon: "las la-igloo" },

        { icon: "las la-industry" },

        { icon: "las la-kaaba" },

        { icon: "las la-landmark" },

        { icon: "las la-monument" },

        { icon: "las la-mosque" },

        { icon: "las la-place-of-worship" },

        { icon: "las la-school" },

        { icon: "las la-store" },

        { icon: "las la-store-alt" },

        { icon: "las la-synagogue" },

        { icon: "las la-torii-gate" },

        { icon: "las la-university" },

        { icon: "las la-vihara" },

        { icon: "las la-warehouse" },

        { icon: "las la-address-book" },

        { icon: "las la-address-card" },

        { icon: "las la-archive" },

        { icon: "las la-balance-scale" },

        { icon: "las la-balance-scale-left" },

        { icon: "las la-balance-scale-right" },

        { icon: "las la-birthday-cake" },

        { icon: "las la-book" },

        { icon: "las la-briefcase" },

        { icon: "las la-bullhorn" },

        { icon: "las la-bullseye" },

        { icon: "las la-business-time" },

        { icon: "las la-calculator" },

        { icon: "las la-calendar" },

        { icon: "las la-calendar-alt" },

        { icon: "las la-certificate" },

        { icon: "las la-chart-area" },

        { icon: "las la-chart-bar" },

        { icon: "las la-chart-pie" },

        { icon: "las la-clipboard" },

        { icon: "las la-columns" },

        { icon: "las la-compass" },

        { icon: "las la-copy" },

        { icon: "las la-copyright" },

        { icon: "las la-cut" },

        { icon: "las la-edit" },

        { icon: "las la-envelope" },

        { icon: "las la-envelope-open" },

        { icon: "las la-envelope-square" },

        { icon: "las la-eraser" },

        { icon: "las la-fax" },

        { icon: "las la-file" },

        { icon: "las la-file-alt" },

        { icon: "las la-folder" },

        { icon: "las la-folder-minus" },

        { icon: "las la-folder-open" },

        { icon: "las la-folder-plus" },

        { icon: "las la-glasses" },

        { icon: "las la-globe" },

        { icon: "las la-highlighter" },

        { icon: "las la-marker" },

        { icon: "las la-paperclip" },

        { icon: "las la-paste" },

        { icon: "las la-pen" },

        { icon: "las la-pen-alt" },

        { icon: "las la-pen-fancy" },

        { icon: "las la-pen-nib" },

        { icon: "las la-pen-square" },

        { icon: "las la-pencil-alt" },

        { icon: "las la-percent" },

        { icon: "las la-phone" },

        { icon: "las la-phone-alt" },

        { icon: "las la-phone-slash" },

        { icon: "las la-phone-square" },

        { icon: "las la-phone-square-alt" },

        { icon: "las la-print" },

        { icon: "las la-project-diagram" },

        { icon: "las la-registered" },

        { icon: "las la-save" },

        { icon: "las la-sitemap" },

        { icon: "las la-socks" },

        { icon: "las la-sticky-note" },

        { icon: "las la-stream" },

        { icon: "las la-table" },

        { icon: "las la-tag" },

        { icon: "las la-tags" },

        { icon: "las la-tasks" },

        { icon: "las la-thumbtack" },

        { icon: "las la-trademark" },

        { icon: "las la-wallet" },

        { icon: "las la-binoculars" },

        { icon: "las la-fire" },

        { icon: "las la-fire-alt" },

        { icon: "las la-first-aid" },

        { icon: "las la-map" },

        { icon: "las la-map-marked" },

        { icon: "las la-map-marked-alt" },

        { icon: "las la-map-signs" },

        { icon: "las la-route" },

        { icon: "las la-toilet-paper" },

        { icon: "las la-dollar-sign" },

        { icon: "las la-donate" },

        { icon: "las la-gift" },

        { icon: "las la-hand-holding-heart" },

        { icon: "las la-hand-holding-usd" },

        { icon: "las la-hands-helping" },

        { icon: "las la-handshake" },

        { icon: "las la-heart" },

        { icon: "las la-leaf" },

        { icon: "las la-parachute-box" },

        { icon: "las la-piggy-bank" },

        { icon: "las la-ribbon" },

        { icon: "las la-seedling" },

        { icon: "las la-comment" },

        { icon: "las la-comment-alt" },

        { icon: "las la-comment-dots" },

        { icon: "las la-comment-medical" },

        { icon: "las la-comment-slash" },

        { icon: "las la-comments" },

        { icon: "las la-frown" },

        { icon: "las la-icons" },

        { icon: "las la-meh" },

        { icon: "las la-poo" },

        { icon: "las la-quote-left" },

        { icon: "las la-quote-right" },

        { icon: "las la-smile" },

        { icon: "las la-sms" },

        { icon: "las la-video-slash" },

        { icon: "las la-chess" },

        { icon: "las la-chess-bishop" },

        { icon: "las la-chess-board" },

        { icon: "las la-chess-king" },

        { icon: "las la-chess-knight" },

        { icon: "las la-chess-pawn" },

        { icon: "las la-chess-queen" },

        { icon: "las la-chess-rook" },

        { icon: "las la-square-full" },

        { icon: "las la-baby" },

        { icon: "las la-baby-carriage" },

        { icon: "las la-bath" },

        { icon: "las la-biking" },

        { icon: "las la-cookie" },

        { icon: "las la-cookie-bite" },

        { icon: "las la-gamepad" },

        { icon: "las la-ice-cream" },

        { icon: "las la-mitten" },

        { icon: "las la-robot" },

        { icon: "las la-shapes" },

        { icon: "las la-snowman" },

        { icon: "las la-graduation-cap" },

        { icon: "las la-hat-cowboy" },

        { icon: "las la-hat-cowboy-side" },

        { icon: "las la-hat-wizard" },

        { icon: "las la-shoe-prints" },

        { icon: "las la-tshirt" },

        { icon: "las la-user-tie" },

        { icon: "las la-barcode" },

        { icon: "las la-bug" },

        { icon: "las la-code" },

        { icon: "las la-code-branch" },

        { icon: "las la-file-code" },

        { icon: "las la-filter" },

        { icon: "las la-fire-extinguisher" },

        { icon: "las la-keyboard" },

        { icon: "las la-laptop-code" },

        { icon: "las la-microchip" },

        { icon: "las la-qrcode" },

        { icon: "las la-shield-alt" },

        { icon: "las la-terminal" },

        { icon: "las la-user-secret" },

        { icon: "las la-window-close" },

        { icon: "las la-window-maximize" },

        { icon: "las la-window-minimize" },

        { icon: "las la-window-restore" },

        { icon: "las la-at" },

        { icon: "lab la-bluetooth" },

        { icon: "lab la-bluetooth-b" },

        { icon: "las la-chalkboard" },

        { icon: "las la-inbox" },

        { icon: "las la-language" },

        { icon: "las la-mobile" },

        { icon: "las la-mobile-alt" },

        { icon: "las la-paper-plane" },

        { icon: "las la-voicemail" },

        { icon: "las la-wifi" },

        { icon: "las la-database" },

        { icon: "las la-desktop" },

        { icon: "las la-ethernet" },

        { icon: "las la-hdd" },

        { icon: "las la-laptop" },

        { icon: "las la-memory" },

        { icon: "las la-mouse" },

        { icon: "las la-plug" },

        { icon: "las la-power-off" },

        { icon: "las la-satellite" },

        { icon: "las la-satellite-dish" },

        { icon: "las la-sd-card" },

        { icon: "las la-server" },

        { icon: "las la-sim-card" },

        { icon: "las la-tablet" },

        { icon: "las la-tablet-alt" },

        { icon: "las la-brush" },

        { icon: "las la-drafting-compass" },

        { icon: "las la-dumpster" },

        { icon: "las la-hammer" },

        { icon: "las la-hard-hat" },

        { icon: "las la-paint-roller" },

        { icon: "las la-pencil-ruler" },

        { icon: "las la-ruler" },

        { icon: "las la-ruler-combined" },

        { icon: "las la-ruler-horizontal" },

        { icon: "las la-ruler-vertical" },

        { icon: "las la-screwdriver" },

        { icon: "las la-toolbox" },

        { icon: "las la-tools" },

        { icon: "las la-wrench" },

        { icon: "lab la-bitcoin" },

        { icon: "lab la-btc" },

        { icon: "lab la-ethereum" },

        { icon: "las la-euro-sign" },

        { icon: "lab la-gg" },

        { icon: "lab la-gg-circle" },

        { icon: "las la-hryvnia" },

        { icon: "las la-lira-sign" },

        { icon: "las la-money-bill" },

        { icon: "las la-money-bill-alt" },

        { icon: "las la-money-bill-wave" },

        { icon: "las la-money-bill-wave-alt" },

        { icon: "las la-money-check" },

        { icon: "las la-money-check-alt" },

        { icon: "las la-pound-sign" },

        { icon: "las la-ruble-sign" },

        { icon: "las la-rupee-sign" },

        { icon: "las la-shekel-sign" },

        { icon: "las la-tenge" },

        { icon: "las la-won-sign" },

        { icon: "las la-yen-sign" },

        { icon: "las la-calendar-check" },

        { icon: "las la-calendar-minus" },

        { icon: "las la-calendar-plus" },

        { icon: "las la-calendar-times" },

        { icon: "las la-clock" },

        { icon: "las la-hourglass" },

        { icon: "las la-hourglass-end" },

        { icon: "las la-hourglass-half" },

        { icon: "las la-hourglass-start" },

        { icon: "las la-stopwatch" },

        { icon: "las la-adjust" },

        { icon: "las la-bezier-curve" },

        { icon: "las la-clone" },

        { icon: "las la-crop" },

        { icon: "las la-crop-alt" },

        { icon: "las la-crosshairs" },

        { icon: "las la-draw-polygon" },

        { icon: "las la-eye" },

        { icon: "las la-eye-dropper" },

        { icon: "las la-eye-slash" },

        { icon: "las la-fill" },

        { icon: "las la-fill-drip" },

        { icon: "las la-layer-group" },

        { icon: "las la-magic" },

        { icon: "las la-object-group" },

        { icon: "las la-object-ungroup" },

        { icon: "las la-paint-brush" },

        { icon: "las la-palette" },

        { icon: "las la-splotch" },

        { icon: "las la-spray-can" },

        { icon: "las la-stamp" },

        { icon: "las la-swatchbook" },

        { icon: "las la-tint" },

        { icon: "las la-tint-slash" },

        { icon: "las la-vector-square" },

        { icon: "las la-align-center" },

        { icon: "las la-align-justify" },

        { icon: "las la-align-left" },

        { icon: "las la-align-right" },

        { icon: "las la-bold" },

        { icon: "las la-border-all" },

        { icon: "las la-border-none" },

        { icon: "las la-border-style" },

        { icon: "las la-font" },

        { icon: "las la-heading" },

        { icon: "las la-i-cursor" },

        { icon: "las la-indent" },

        { icon: "las la-italic" },

        { icon: "las la-link" },

        { icon: "las la-list" },

        { icon: "las la-list-alt" },

        { icon: "las la-list-ol" },

        { icon: "las la-list-ul" },

        { icon: "las la-outdent" },

        { icon: "las la-paragraph" },

        { icon: "las la-remove-format" },

        { icon: "las la-spell-check" },

        { icon: "las la-strikethrough" },

        { icon: "las la-subscript" },

        { icon: "las la-superscript" },

        { icon: "las la-th" },

        { icon: "las la-th-large" },

        { icon: "las la-th-list" },

        { icon: "las la-trash" },

        { icon: "las la-trash-alt" },

        { icon: "las la-trash-restore" },

        { icon: "las la-trash-restore-alt" },

        { icon: "las la-underline" },

        { icon: "las la-unlink" },

        { icon: "las la-atom" },

        { icon: "las la-award" },

        { icon: "las la-book-open" },

        { icon: "las la-book-reader" },

        { icon: "las la-chalkboard-teacher" },

        { icon: "las la-microscope" },

        { icon: "las la-theater-masks" },

        { icon: "las la-user-graduate" },

        { icon: "las la-angry" },

        { icon: "las la-dizzy" },

        { icon: "las la-flushed" },

        { icon: "las la-frown-open" },

        { icon: "las la-grimace" },

        { icon: "las la-grin" },

        { icon: "las la-grin-alt" },

        { icon: "las la-grin-beam" },

        { icon: "las la-grin-beam-sweat" },

        { icon: "las la-grin-hearts" },

        { icon: "las la-grin-squint" },

        { icon: "las la-grin-squint-tears" },

        { icon: "las la-grin-stars" },

        { icon: "las la-grin-tears" },

        { icon: "las la-grin-tongue" },

        { icon: "las la-grin-tongue-squint" },

        { icon: "las la-grin-tongue-wink" },

        { icon: "las la-grin-wink" },

        { icon: "las la-kiss" },

        { icon: "las la-kiss-beam" },

        { icon: "las la-kiss-wink-heart" },

        { icon: "las la-laugh" },

        { icon: "las la-laugh-beam" },

        { icon: "las la-laugh-squint" },

        { icon: "las la-laugh-wink" },

        { icon: "las la-meh-blank" },

        { icon: "las la-meh-rolling-eyes" },

        { icon: "las la-sad-cry" },

        { icon: "las la-sad-tear" },

        { icon: "las la-smile-beam" },

        { icon: "las la-smile-wink" },

        { icon: "las la-surprise" },

        { icon: "las la-tired" },

        { icon: "las la-battery-empty" },

        { icon: "las la-battery-full" },

        { icon: "las la-battery-half" },

        { icon: "las la-battery-quarter" },

        { icon: "las la-battery-three-quarters" },

        { icon: "las la-burn" },

        { icon: "las la-lightbulb" },

        { icon: "las la-poop" },

        { icon: "las la-solar-panel" },

        { icon: "las la-sun" },

        { icon: "las la-water" },

        { icon: "las la-file-archive" },

        { icon: "las la-file-excel" },

        { icon: "las la-file-image" },

        { icon: "las la-file-pdf" },

        { icon: "las la-file-powerpoint" },

        { icon: "las la-file-word" },

        { icon: "las la-cash-register" },

        { icon: "las la-coins" },

        { icon: "las la-comment-dollar" },

        { icon: "las la-comments-dollar" },

        { icon: "las la-credit-card" },

        { icon: "las la-file-invoice" },

        { icon: "las la-file-invoice-dollar" },

        { icon: "las la-percentage" },

        { icon: "las la-receipt" },

        { icon: "las la-bicycle" },

        { icon: "las la-heartbeat" },

        { icon: "las la-running" },

        { icon: "las la-skating" },

        { icon: "las la-skiing" },

        { icon: "las la-skiing-nordic" },

        { icon: "las la-snowboarding" },

        { icon: "las la-spa" },

        { icon: "las la-swimmer" },

        { icon: "las la-walking" },

        { icon: "las la-bacon" },

        { icon: "las la-bone" },

        { icon: "las la-bread-slice" },

        { icon: "las la-candy-cane" },

        { icon: "las la-carrot" },

        { icon: "las la-cheese" },

        { icon: "las la-cloud-meatball" },

        { icon: "las la-egg" },

        { icon: "las la-hamburger" },

        { icon: "las la-hotdog" },

        { icon: "las la-lemon" },

        { icon: "las la-pepper-hot" },

        { icon: "las la-pizza-slice" },

        { icon: "las la-stroopwafel" },

        { icon: "las la-dice" },

        { icon: "las la-dice-d20" },

        { icon: "las la-dice-d6" },

        { icon: "las la-dice-five" },

        { icon: "las la-dice-four" },

        { icon: "las la-dice-one" },

        { icon: "las la-dice-six" },

        { icon: "las la-dice-three" },

        { icon: "las la-dice-two" },

        { icon: "las la-ghost" },

        { icon: "las la-headset" },

        { icon: "lab la-playstation" },

        { icon: "las la-puzzle-piece" },

        { icon: "lab la-steam" },

        { icon: "lab la-steam-square" },

        { icon: "lab la-steam-symbol" },

        { icon: "lab la-twitch" },

        { icon: "lab la-xbox" },

        { icon: "las la-genderless" },

        { icon: "las la-mars" },

        { icon: "las la-mars-double" },

        { icon: "las la-mars-stroke" },

        { icon: "las la-mars-stroke-h" },

        { icon: "las la-mars-stroke-v" },

        { icon: "las la-mercury" },

        { icon: "las la-neuter" },

        { icon: "las la-transgender" },

        { icon: "las la-transgender-alt" },

        { icon: "las la-venus" },

        { icon: "las la-venus-double" },

        { icon: "las la-venus-mars" },

        { icon: "las la-book-dead" },

        { icon: "las la-broom" },

        { icon: "las la-cloud-moon" },

        { icon: "las la-mask" },

        { icon: "las la-allergies" },

        { icon: "las la-fist-raised" },

        { icon: "las la-hand-holding" },

        { icon: "las la-hand-lizard" },

        { icon: "las la-hand-middle-finger" },

        { icon: "las la-hand-paper" },

        { icon: "las la-hand-peace" },

        { icon: "las la-hand-rock" },

        { icon: "las la-hand-scissors" },

        { icon: "las la-hand-spock" },

        { icon: "las la-hands" },

        { icon: "las la-praying-hands" },

        { icon: "las la-thumbs-down" },

        { icon: "las la-thumbs-up" },

        { icon: "las la-h-square" },
        { icon: "las la-medkit" },
        { icon: "las la-plus-square" },
        { icon: "las la-prescription" },
        { icon: "las la-stethoscope" },
        { icon: "las la-user-md" },

        { icon: "las la-gifts" },

        { icon: "las la-holly-berry" },

        { icon: "las la-sleigh" },

        { icon: "las la-bed" },
        { icon: "las la-concierge-bell" },
        { icon: "las la-door-closed" },
        { icon: "las la-door-open" },
        { icon: "las la-dumbbell" },
        { icon: "las la-hot-tub" },
        { icon: "las la-infinity" },
        { icon: "las la-key" },
        { icon: "las la-luggage-cart" },
        { icon: "las la-shower" },
        { icon: "las la-smoking" },
        { icon: "las la-smoking-ban" },
        { icon: "las la-snowflake" },
        { icon: "las la-suitcase" },
        { icon: "las la-suitcase-rolling" },
        { icon: "las la-swimming-pool" },
        { icon: "las la-umbrella-beach" },
        { icon: "las la-utensils" },

        { icon: "las la-chair" },

        { icon: "las la-couch" },

        { icon: "las la-fan" },

        { icon: "las la-bolt" },
        { icon: "las la-camera" },
        { icon: "las la-camera-retro" },
        { icon: "las la-id-badge" },
        { icon: "las la-id-card" },
        { icon: "las la-image" },
        { icon: "las la-images" },
        { icon: "las la-portrait" },
        { icon: "las la-sliders-h" },

        { icon: "las la-ban" },

        { icon: "las la-bars" },

        { icon: "las la-blog" },

        { icon: "las la-check" },

        { icon: "las la-check-circle" },

        { icon: "las la-check-double" },

        { icon: "las la-check-square" },

        { icon: "las la-cloud" },

        { icon: "las la-cog" },

        { icon: "las la-cogs" },

        { icon: "las la-dot-circle" },

        { icon: "las la-ellipsis-h" },

        { icon: "las la-ellipsis-v" },

        { icon: "las la-file-download" },

        { icon: "las la-file-export" },

        { icon: "las la-file-import" },

        { icon: "las la-file-upload" },

        { icon: "las la-fingerprint" },

        { icon: "las la-flag" },

        { icon: "las la-flag-checkered" },

        { icon: "las la-grip-horizontal" },

        { icon: "las la-grip-lines" },

        { icon: "las la-grip-lines-vertical" },

        { icon: "las la-grip-vertical" },

        { icon: "las la-hashtag" },

        { icon: "las la-info" },

        { icon: "las la-info-circle" },

        { icon: "las la-medal" },

        { icon: "las la-minus" },

        { icon: "las la-minus-circle" },

        { icon: "las la-minus-square" },

        { icon: "las la-plus" },

        { icon: "las la-plus-circle" },

        { icon: "las la-question" },

        { icon: "las la-search" },

        { icon: "las la-search-minus" },

        { icon: "las la-search-plus" },

        { icon: "las la-share-alt" },

        { icon: "las la-share-alt-square" },

        { icon: "las la-signal" },

        { icon: "las la-star" },

        { icon: "las la-star-half" },

        { icon: "las la-times" },

        { icon: "las la-times-circle" },

        { icon: "las la-toggle-off" },

        { icon: "las la-toggle-on" },

        { icon: "las la-trophy" },

        { icon: "las la-user" },

        { icon: "las la-user-alt" },

        { icon: "las la-user-circle" },

        { icon: "las la-box" },
        { icon: "las la-boxes" },
        { icon: "las la-clipboard-check" },
        { icon: "las la-clipboard-list" },
        { icon: "las la-dolly" },
        { icon: "las la-dolly-flatbed" },
        { icon: "las la-pallet" },
        { icon: "las la-shipping-fast" },

        { icon: "las la-anchor" },

        { icon: "las la-bomb" },

        { icon: "las la-bookmark" },

        { icon: "las la-directions" },

        { icon: "las la-fighter-jet" },

        { icon: "las la-gavel" },

        { icon: "las la-helicopter" },

        { icon: "las la-life-ring" },

        { icon: "las la-magnet" },

        { icon: "las la-male" },

        { icon: "las la-map-marker" },

        { icon: "las la-map-marker-alt" },

        { icon: "las la-map-pin" },

        { icon: "las la-newspaper" },

        { icon: "las la-parking" },

        { icon: "las la-plane" },

        { icon: "las la-restroom" },

        { icon: "las la-road" },

        { icon: "las la-rocket" },

        { icon: "las la-ship" },

        { icon: "las la-shopping-bag" },

        { icon: "las la-shopping-basket" },

        { icon: "las la-shopping-cart" },

        { icon: "las la-snowplow" },

        { icon: "las la-street-view" },

        { icon: "las la-subway" },

        { icon: "las la-ticket-alt" },

        { icon: "las la-traffic-light" },

        { icon: "las la-train" },

        { icon: "las la-tram" },

        { icon: "las la-umbrella" },

        { icon: "las la-utensil-spoon" },

        { icon: "las la-dharmachakra" },

        { icon: "las la-ad" },

        { icon: "las la-envelope-open-text" },

        { icon: "las la-funnel-dollar" },

        { icon: "las la-mail-bulk" },

        { icon: "las la-poll" },

        { icon: "las la-poll-h" },

        { icon: "las la-search-dollar" },

        { icon: "las la-search-location" },

        { icon: "las la-divide" },
        { icon: "las la-equals" },
        { icon: "las la-greater-than" },
        { icon: "las la-greater-than-equal" },
        { icon: "las la-less-than" },
        { icon: "las la-less-than-equal" },
        { icon: "las la-not-equal" },
        { icon: "las la-square-root-alt" },
        { icon: "las la-wave-square" },

        { icon: "las la-band-aid" },

        { icon: "las la-biohazard" },

        { icon: "las la-bong" },

        { icon: "las la-book-medical" },

        { icon: "las la-brain" },

        { icon: "las la-briefcase-medical" },

        { icon: "las la-cannabis" },

        { icon: "las la-capsules" },

        { icon: "las la-crutch" },

        { icon: "las la-diagnoses" },

        { icon: "las la-dna" },

        { icon: "las la-file-medical" },

        { icon: "las la-file-medical-alt" },

        { icon: "las la-file-prescription" },

        { icon: "las la-hospital-symbol" },

        { icon: "las la-id-card-alt" },

        { icon: "las la-joint" },

        { icon: "las la-laptop-medical" },

        { icon: "las la-mortar-pestle" },

        { icon: "las la-notes-medical" },

        { icon: "las la-pager" },

        { icon: "las la-pills" },

        { icon: "las la-prescription-bottle" },

        { icon: "las la-prescription-bottle-alt" },

        { icon: "las la-procedures" },

        { icon: "las la-star-of-life" },

        { icon: "las la-syringe" },

        { icon: "las la-tablets" },

        { icon: "las la-teeth" },

        { icon: "las la-teeth-open" },

        { icon: "las la-thermometer" },

        { icon: "las la-tooth" },

        { icon: "las la-user-nurse" },

        { icon: "las la-vial" },

        { icon: "las la-vials" },

        { icon: "las la-weight" },

        { icon: "las la-x-ray" },

        { icon: "las la-box-open" },
        { icon: "las la-people-carry" },
        { icon: "las la-sign" },
        { icon: "las la-tape" },
        { icon: "las la-truck-loading" },
        { icon: "las la-truck-moving" },

        { icon: "las la-drum" },

        { icon: "las la-drum-steelpan" },

        { icon: "las la-guitar" },

        { icon: "las la-headphones-alt" },

        { icon: "lab la-napster" },

        { icon: "las la-record-vinyl" },

        { icon: "lab la-soundcloud" },

        { icon: "lab la-spotify" },

        { icon: "las la-cube" },
        { icon: "las la-cubes" },
        { icon: "las la-digital-tachograph" },
        { icon: "las la-futbol" },
        { icon: "las la-gem" },
        { icon: "las la-heart-broken" },
        { icon: "las la-lock" },
        { icon: "las la-lock-open" },
        { icon: "las la-moon" },
        { icon: "las la-ring" },
        { icon: "las la-scroll" },
        { icon: "las la-space-shuttle" },
        { icon: "las la-toilet" },
        { icon: "las la-unlock" },
        { icon: "las la-unlock-alt" },

        { icon: "las la-backspace" },

        { icon: "las la-blender-phone" },

        { icon: "las la-crown" },

        { icon: "las la-dumpster-fire" },

        { icon: "las la-file-csv" },

        { icon: "las la-network-wired" },

        { icon: "las la-signature" },

        { icon: "las la-skull" },

        { icon: "las la-vr-cardboard" },

        { icon: "las la-weight-hanging" },

        { icon: "lab la-alipay" },
        { icon: "lab la-amazon-pay" },
        { icon: "lab la-apple-pay" },
        { icon: "las la-cart-plus" },
        { icon: "lab la-cc-amazon-pay" },
        { icon: "lab la-cc-amex" },
        { icon: "lab la-cc-apple-pay" },
        { icon: "lab la-cc-diners-club" },
        { icon: "lab la-cc-discover" },
        { icon: "lab la-cc-jcb" },
        { icon: "lab la-cc-mastercard" },
        { icon: "lab la-cc-paypal" },
        { icon: "lab la-cc-stripe" },
        { icon: "lab la-cc-visa" },
        { icon: "lab la-google-wallet" },
        { icon: "lab la-paypal" },
        { icon: "lab la-stripe" },
        { icon: "lab la-stripe-s" },

        { icon: "las la-democrat" },

        { icon: "las la-flag-usa" },

        { icon: "las la-person-booth" },

        { icon: "las la-republican" },

        { icon: "las la-vote-yea" },

        { icon: "las la-ankh" },
        { icon: "las la-bible" },
        { icon: "las la-cross" },
        { icon: "las la-hamsa" },
        { icon: "las la-hanukiah" },
        { icon: "las la-haykal" },
        { icon: "las la-jedi" },
        { icon: "las la-journal-whills" },
        { icon: "las la-khanda" },
        { icon: "las la-menorah" },
        { icon: "las la-om" },
        { icon: "las la-pastafarianism" },
        { icon: "las la-peace" },
        { icon: "las la-pray" },
        { icon: "las la-quran" },
        { icon: "las la-star-and-crescent" },
        { icon: "las la-star-of-david" },
        { icon: "las la-torah" },
        { icon: "las la-yin-yang" },

        { icon: "las la-temperature-high" },

        { icon: "las la-temperature-low" },

        { icon: "lab la-galactic-republic" },
        { icon: "lab la-galactic-senate" },
        { icon: "lab la-jedi-order" },
        { icon: "las la-meteor" },
        { icon: "lab la-old-republic" },
        { icon: "las la-user-astronaut" },

        { icon: "las la-file-contract" },

        { icon: "las la-file-signature" },

        { icon: "las la-passport" },

        { icon: "las la-user-lock" },

        { icon: "las la-user-shield" },

        { icon: "las la-square" },

        { icon: "las la-user-friends" },

        { icon: "las la-user-plus" },

        { icon: "las la-users" },

        { icon: "las la-asterisk" },
        { icon: "las la-circle-notch" },
        { icon: "las la-compact-disc" },
        { icon: "las la-slash" },
        { icon: "las la-spinner" },

        { icon: "las la-baseball-ball" },

        { icon: "las la-basketball-ball" },

        { icon: "las la-bowling-ball" },

        { icon: "las la-golf-ball" },

        { icon: "las la-hockey-puck" },

        { icon: "las la-quidditch" },

        { icon: "las la-table-tennis" },

        { icon: "las la-volleyball-ball" },

        { icon: "las la-cloud-sun-rain" },
        { icon: "las la-rainbow" },

        { icon: "las la-calendar-day" },

        { icon: "las la-calendar-week" },

        { icon: "las la-star-half-alt" },

        { icon: "las la-thermometer-empty" },

        { icon: "las la-thermometer-full" },

        { icon: "las la-thermometer-half" },

        { icon: "las la-thermometer-quarter" },

        { icon: "las la-thermometer-three-quarters" },

        { icon: "las la-user-alt-slash" },

        { icon: "las la-user-slash" },

        { icon: "lab la-acquisitions-incorporated" },
        { icon: "lab la-critical-role" },
        { icon: "lab la-d-and-d" },
        { icon: "lab la-d-and-d-beyond" },
        { icon: "lab la-fantasy-flight-games" },
        { icon: "lab la-penny-arcade" },
        { icon: "lab la-wizards-of-the-coast" },

        { icon: "las la-atlas" },

        { icon: "las la-globe-africa" },

        { icon: "las la-globe-americas" },

        { icon: "las la-globe-asia" },

        { icon: "las la-globe-europe" },

        { icon: "las la-plane-arrival" },

        { icon: "las la-plane-departure" },

        { icon: "las la-child" },
        { icon: "las la-female" },
        { icon: "las la-user-check" },
        { icon: "las la-user-clock" },
        { icon: "las la-user-cog" },
        { icon: "las la-user-edit" },
        { icon: "las la-user-injured" },
        { icon: "las la-user-minus" },
        { icon: "las la-user-ninja" },
        { icon: "las la-user-tag" },
        { icon: "las la-user-times" },
        { icon: "las la-users-cog" },

        { icon: "las la-cloud-moon-rain" },

        { icon: "las la-cloud-rain" },

        { icon: "las la-cloud-showers-heavy" },

        { icon: "las la-poo-storm" },

        { icon: "las la-smog" },

        { icon: "las la-icicles" },
      ],
    };
  },

  created() {},
};
