// stores/counter.js
import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => {
    return { 
        usuario: {foto:''},
        methods:[],
        token:'',
    
    }
  },
  persist: true,
  // could also be defined as
  // state: () => ({ count: 0 })
  getters: {
    getPreferencias: (state) => {
      return () => state.usuario.preferencias
    },
    getAllLinks: (state) => {
      return () => state.usuario.links
    },
    getAll: (state) => {
      return () => state.usuario
    },
    getToken: (state) => {
      return () => state.token
    },
    issetToken: (state) => {
      return () => state.token!=''
    },
    issetCategoria: (state) => {
      return () => state.usuario.categoria
    },
    getMetodos: (state) => {
      return () => state.methods
    },
  },
  actions: {
    setMercado(data) {
    
      this.usuario.tokenmercado=data;
      
    },
    setPreferencias(data) {
    
      this.usuario.preferencias=data;
      
    },
    setCategoria(data) {
    
      this.usuario.categoria=data;
      
    },
    setDescripcion(data) {
    
      this.usuario.descripcion=data;
      
      
    },
    setPhyId(data) {
    
      this.usuario.phyid=data;
      
    },
    setUsername(data) {
    
      this.usuario.username=data;
      
    },
    setLinks(data) {
    
      this.usuario.links=data;
      
    },
    pushLink(data) {
    
      this.usuario.links.push(data);
      
    },
   eliminarLink(data) {
      
      this.usuario.links=this.usuario.links.filter(element =>element.id != data.id);
      
    },
    updateLink(data) {
    
      this.usuario.links.forEach(element => {
        if (element.id == data.id) {
            element.icono = data.icono;
            element.imagen = data.imagen;
            element.url = data.url;
            element.nombre = data.nombre;
            element.explicito = data.explicito;
            
            //element = response

        }
    });
      
    },
    setApariencia(data) {

      this.usuario.tipo_borde_boton=data.tipo_borde_boton
      this.usuario.shadow_boton=data.shadow_boton
      this.usuario.color_texto_botton=data.color_texto_botton
      this.usuario.color_fondo_botton=data.color_fondo_botton
      this.usuario.imagen_fondo=data.imagen_fondo

      this.usuario.radius_boton=data.radius_boton
      this.usuario.color_borde=data.color_borde
       this.usuario.color_fondo=data.color_fondo
       this.usuario.color_titulo=data.color_titulo

       this.usuario.color_descripcion=data.color_descripcion
       this.usuario.fuente=data.fuente
 

      
    },
    setFoto(data) {
    
      this.usuario.foto=data;
      
    },
    setUser(data) {
    
      this.usuario=data;
      
    },
    set(data) {
      this.token=data.token;
      this.usuario=data.user;
      
    },
    limpiar() {
      this.token='';
      this.usuario={};
    
    },
    get() {
     return this.usuario
    },
  },
})