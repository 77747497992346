<template>
<div class="w-full flex flex-wrap h-full  ">
    <div class="w-full sm:w-full  md:w-full xl:w-4/6 lg:w-4/6 bg-gray-200 xs:pl-16   sm:pl-0    md:pl-16 xl:pl-16 lg:pl-16    h-full flex flex-wrap text-black">
        <div class=" w-full  border-t-2 border-b-2 border-gray-200 h-full">

            <menuTop></menuTop>

            <div class="w-full h-full xs:px-1 sm:px-1 md:px-1 lg:px-2 xl:px-5 pt-5 pb-20 justify-center content-start flex flex-wrap overflow-y-scroll">

                <div class="w-full bg-gray-200 sticky top-0 flex flex-wrap justify-start content-center">
                    <button class="w-36 h-12 bg-purple-400 rounded-xl" type="button" @click="init()">conectar</button>
                </div>
                <div v-for="(row,index) in social_connect.data" v-bind:key="index" @click="seleccionado=row;getDemografic(row.account.id)" class="px-1 py-1 w-3/6">
                    <div class="rounded-xl w-full  bg-white  px-2 py-2 flex flex-wrap justify-center content-center ">
                        <div class="w-24 h-24 px-1 py-1 rounded-full bg-gray-200 border border-gray-300">
                            <img :src="row.work_platform.logo_url" class="w-full h-full rounded-full">

                        </div>
                        <span class="text-xs font-extrabold w-full mt-2">{{row.work_platform.name}}</span>

                    </div>

                </div>
            </div>

        </div>
    </div>
    <div class="sm:w-full w-full md:w-full xl:w-4/6 lg:w-4/6 bg-gray-100 h-full flex flex-wrap content-start overflow-y-scroll py-20 justify-center">

        <aside class="w-full px-10" v-if="seleccionado!=null">

            <div class="bg-white shadow rounded-lg p-10">
                <div class="flex flex-col gap-1 text-center items-center">
                    <img class="h-32 w-32 bg-white p-2 rounded-full shadow mb-4" :src="seleccionado.image_url" alt="">
                    <p class="font-semibold">{{seleccionado.nick_name}} {{seleccionado.platform_username}}<span v-if="seleccionado.is_verified"><i class="las text-xl text-blue-600 la-check-circle"></i></span></p>
                    <div class="text-xs leading-normal text-gray-500 flex justify-center items-center">
                        {{seleccionado.url}}

                    </div>
                </div>

            </div>

            <div class="bg-white shadow mt-6  rounded-lg p-6">
                <h3 class="text-gray-600 text-sm font-semibold mb-4">Datos de cuenta</h3>

                <div class="w-full flex flex-wrap justify-between mb-2">
                    <div class="w-3/6 flex flex-wrap justify-center content-center">
                        <a class="block bg-white p-1 mb-2  rounded-full bg-gray-100  h-16 w-16 content-center flex flex-wrap justify-center text-xs" href="#">
                            <span>{{seleccionado.reputation.follower_count}} </span>
                        </a>
                        <!-- Username -->
                        <span class="text-xs text-gray-500 font-extrabold w-full">
                            Seguidores
                        </span>
                    </div>
                    <div class="w-3/6 flex flex-wrap justify-center content-center">
                        <a class="block bg-white p-1 mb-2 rounded-full bg-gray-100  h-16 w-16 content-center flex flex-wrap justify-center text-xs" href="#">
                            <span> {{seleccionado.reputation.following_count}} </span>
                        </a>

                        <!-- Username -->
                        <span class="text-xs text-gray-500 font-extrabold w-full">
                            Seguidos
                        </span>
                    </div>
                </div>
                <div class="w-full flex flex-wrap justify-between mb-2">
                    <div class="w-3/6 flex flex-wrap justify-center content-center">
                        <a class="block bg-white p-1 mb-2 rounded-full bg-gray-100  h-16 w-16 content-center flex flex-wrap justify-center text-xs" href="#">
                            <span>{{seleccionado.reputation.subscriber_count}} </span>
                        </a>

                        <!-- Username -->
                        <span class="text-xs text-gray-500 font-extrabold w-full">
                            Suscriptores
                        </span>
                    </div>
                    <div class="w-3/6 flex flex-wrap justify-center content-center">
                        <a class="block bg-white p-1 mb-2 rounded-full bg-gray-100  h-16 w-16 content-center flex flex-wrap justify-center text-xs" href="#">
                            <span>{{seleccionado.reputation.content_count}} </span> </a>

                        <!-- Username -->
                        <span class="text-xs text-gray-500 font-extrabold w-full">
                            Publicaciones
                        </span>
                    </div>
                </div>
                <div class="w-full flex flex-wrap justify-between mb-2">
                    <div class="w-3/6 flex flex-wrap justify-center content-center">
                        <a class="block bg-white p-1 mb-2 rounded-full bg-gray-100  h-16 w-16 content-center flex flex-wrap justify-center text-xs" href="#">
                            <span>{{seleccionado.reputation.watch_time_in_hours}}</span> </a>

                        <!-- Username -->
                        <span class="text-xs text-gray-500 font-extrabold w-full">
                            Horas de reproducción
                        </span>
                    </div>
                    <div class="w-3/6 flex flex-wrap justify-center content-center">

                    </div>
                </div>

            </div>

            <div class="flex flex-col">
                <h2 class="mt-4 text-2xl font-bold">Paises</h2>
              
                <div class="mt-2 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
                  <div v-for="(row,index) in demografic.countries" v-bind:key="index" class="flex items-start rounded-xl bg-white p-4 shadow-lg flex-wrap justify-center content-center">
                   <!-- <div class="flex h-12 w-12 items-center justify-center rounded-full border border-blue-100 bg-blue-50">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                      </svg>
                    </div>-->
              
                    <div class="">
                      <h2 class="font-semibold">{{row.value}}</h2>
                      <p class="mt-2 text-sm text-gray-500">{{row.code}}</p>
                    </div>
                  </div>
              
                  
                </div>
              </div>

              <div class="flex flex-col">
                <h2 class="mt-4 text-2xl font-bold">Ciudades</h2>
              
                <div class="mt-2 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
                  <div v-for="(row,index) in demografic.cities" v-bind:key="index" class="flex items-start rounded-xl bg-white p-4 shadow-lg flex-wrap justify-center content-center">
                   <!-- <div class="flex h-12 w-12 items-center justify-center rounded-full border border-blue-100 bg-blue-50">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                      </svg>
                    </div>-->
              
                    <div class="">
                      <h2 class="font-semibold">{{row.value}}</h2>
                      <p class="mt-2 text-sm text-gray-500">{{row.name}}</p>
                    </div>
                  </div>
              
                  
                </div>
              </div>
              <div class="flex flex-col">
                <h2 class="mt-4 text-2xl font-bold">Generos</h2>
              
                <div class="mt-2 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
                  <div v-for="(row,index) in demografic.gender_age_distribution" v-bind:key="index" 
                  class="flex items-start rounded-xl bg-white p-4 shadow-lg  flex-wrap justify-center content-center">
                    <!--<div class="flex h-12 w-12 items-center justify-center rounded-full border border-blue-100 bg-blue-50">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                      </svg>
                    </div>-->
              
                    <div class="">
                      <h2 class="font-semibold">{{row.value}}</h2>
                      <p class="mt-2 text-sm text-gray-500">{{row.gender}}</p>
                      <p class="mt-2 text-sm text-gray-500">{{row.age_rangue}}</p>
                    </div>
                  </div>
              
                  
                </div>
              </div>
        </aside>

    </div>

</div>
</template>

<script>
import {
    emitter
} from "@/mitt.js";
import {
    useConfigStore
} from '@/stores/config'
import generales from "@/functions/generales";
import infosistem from "@/Services/infosistem";
import preferencias from "@/Services/preferencias";
import links from "@/Services/links";
import fotos from "@/Services/fotos";
import users from "@/Services/user";

import menuTop from "@/components/menuTop.vue";
import {
    createClient
} from 'pexels';
import {
    useInfoStore
} from '@/stores/info'

import {
    useUserStore
} from '@/stores/user'
import {
    useRouter,
    useRoute
} from 'vue-router'
export default {
    components: {

        menuTop
    },
    mixins: [generales, infosistem],
    data() {
        const user = useUserStore()
        const info = useInfoStore()
        return {
            seleccionado: null,
            social_connect: {
                data: []
            },
            demografic: null,
            user: user,
            query: '',
            page: 1,

        }

    },
    setup() {
        const router = useRouter()
        const route = useRoute()

        const config = useConfigStore();

        return {

            config,

        }

    },
    mounted() {
        this.UpdateTablero();

    },
    created() {
        //this.getData()
        // this.get_info();
    },
    methods: {

        getU() {
            return this.user.getAll();
        },

        async getDemografic(id) {

            let data = await users.GET_DEMOGRAFIC({
                id: id
            }).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {

                    //this.user.setPhyId(response.id)
                    this.demografic = response;
                    //this.user.setPhyId(response.id)
                    // this.query = response.username;
                    //this.user_data = this.getUPreferencias();

                } else {
                    emitter.emit("loading", false)
                    return null
                    //emitter.emit("alerta_show", response.error);
                }

            });

        },
        async UpdateTablero() {
            let data = await users.GET_ACCOUNTS_CONNECT().then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {

                    //this.user.setPhyId(response.id)
                    this.social_connect = response;
                    //this.user.setPhyId(response.id)
                    // this.query = response.username;
                    //this.user_data = this.getUPreferencias();

                } else {
                    emitter.emit("loading", false)
                    return null
                    //emitter.emit("alerta_show", response.error);
                }

            });

        },

        async CreateUserAcount() {
            let el = this.getU();

            let dat = {
                username: this.query,
                id: el.id
            }

            emitter.emit("loading", true)

            let data = await users.CONECTAR_PHY(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {

                    this.user.setPhyId(response.id)
                    return response.id;
                    //this.user.setPhyId(response.id)
                    // this.query = response.username;
                    //this.user_data = this.getUPreferencias();

                } else {
                    emitter.emit("loading", false)
                    return null
                    //emitter.emit("alerta_show", response.error);
                }

            });
            return data;
        },
        async CreateToken() {
            let el = this.getU();

            let dat = {

            }

            emitter.emit("loading", true)

            let data = await users.TOKEN_PHY(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    emitter.emit("loading", false)
                    return response.id;
                    //this.user.setPhyId(response.id)
                    // this.query = response.username;
                    //this.user_data = this.getUPreferencias();

                } else {
                    emitter.emit("loading", false)
                    return null
                    //emitter.emit("alerta_show", response.error);
                }

            });
            return data;

        },
        async init() {
            const env = "sandbox"; // the mode in which you want to use the SDK,  `sandbox` or `production`
            // const uniqId = new Date().getTime(); // Unique ID for the user supplied by you.

            const userId = await this.CreateUserAcount();
            const token = await this.CreateToken();
            //const appName = "Sample App";

            let environment = 'sandbox';
            let clientDisplayName = 'poplink';

            let workPlatformId = '';

            const config = {
                clientDisplayName, // the name of your app that you want the creators to see while granting access
                environment, // the mode in which you want to use the SDK,  `sandbox` or `production`
                userId, // the unique user_id parameter returned by Phyllo API when you create a user (see https://docs.getphyllo.com/docs/api-reference/reference/openapi.v1.yml/paths/~1v1~1users/post)
                token,
                redirect: false, // (optional) flag to indicate that you want to use the redirect flow, this is `false` by default
                workPlatformId, // (optional) the unique work_platform_id of a specific work platform, if you want the creator to skip the platform selection screen and just be able to connect just with a single work platform
            };

            const phylloConnect = PhylloConnect.initialize(config);
            phylloConnect.on("accountConnected", (accountId, workplatformId, userId) => { // gives the successfully connected account ID and work platform ID for the given user ID

                this.UpdateTablero()
                console.log(`onAccountConnected: ${accountId}, ${workplatformId}, ${userId}`);
            })
            phylloConnect.on("accountDisconnected", (accountId, workplatformId, userId) => { // gives the successfully disconnected account ID and work platform ID for the given user ID
                console.log(`onAccountDisconnected: ${accountId}, ${workplatformId}, ${userId}`);
            })
            phylloConnect.on("tokenExpired", (userId) => { // gives the user ID for which the token has expired
                console.log(`onTokenExpired: ${userId}`); // the SDK closes automatically in case the token has expired, and you need to handle this by showing an appropriate UI and messaging to the users
            })
            phylloConnect.on("exit", (reason, userId) => { // indicates that the user with given user ID has closed the SDK and gives an appropriate reason for it
                console.log(`onExit: ${reason}, ${userId}`);
            })
            phylloConnect.on("connectionFailure", (reason, workplatformId, userId) => { // optional, indicates that the user with given user ID has attempted connecting to the work platform but resulted in a failure and gives an appropriate reason for it
                console.log(`onConnectionFailure: ${reason}, ${workplatformId}, ${userId}`);
            })
            phylloConnect.open();

        }

    }
}
</script>

<style>
.flip-list-move {
    transition: transform 0.5s;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
</style>
