
export default {
  data() {
    return {
      colores: [

        {
            "id": "Gray",
            "50": "bg-gray-50",
            "100": "bg-gray-100",
            "200": "bg-gray-200",
            "300": "bg-gray-300",
            "400": "bg-gray-400",
            "500": "bg-gray-500",
            "600": "bg-gray-600",
            "700": "bg-gray-700",
            "800": "bg-gray-800",
            "900": "bg-gray-900"
        },

        {
            "id": "Red",
            "50": "bg-red-50",
            "100": "bg-red-100",
            "200": "bg-red-200",
            "300": "bg-red-300",
            "400": "bg-red-400",
            "500": "bg-red-500",
            "600": "bg-red-600",
            "700": "bg-red-700",
            "800": "bg-red-800",
            "900": "bg-red-900"
        },

        {
            "id": "Yellow",
            "50": "bg-yellow-50",
            "100": "bg-yellow-100",
            "200": "bg-yellow-200",
            "300": "bg-yellow-300",
            "400": "bg-yellow-400",
            "500": "bg-yellow-500",
            "600": "bg-yellow-600",
            "700": "bg-yellow-700",
            "800": "bg-yellow-800",
            "900": "bg-yellow-900"
        },

        {
            "id": "Green",
            "50": "bg-green-50",
            "100": "bg-green-100",
            "200": "bg-green-200",
            "300": "bg-green-300",
            "400": "bg-green-400",
            "500": "bg-green-500",
            "600": "bg-green-600",
            "700": "bg-green-700",
            "800": "bg-green-800",
            "900": "bg-green-900"
        },

        {
            "id": "Blue",
            "50": "bg-blue-50",
            "100": "bg-blue-100",
            "200": "bg-blue-200",
            "300": "bg-blue-300",
            "400": "bg-blue-400",
            "500": "bg-blue-500",
            "600": "bg-blue-600",
            "700": "bg-blue-700",
            "800": "bg-blue-800",
            "900": "bg-blue-900"
        },
        {
            "id": "Indigo",
            "50": "bg-indigo-50",
            "100": "bg-indigo-100",
            "200": "bg-indigo-200",
            "300": "bg-indigo-300",
            "400": "bg-indigo-400",
            "500": "bg-indigo-500",
            "600": "bg-indigo-600",
            "700": "bg-indigo-700",
            "800": "bg-indigo-800",
            "900": "bg-indigo-900"
        },

        {
            "id": "Purple",
            "50": "bg-purple-50",
            "100": "bg-purple-100",
            "200": "bg-purple-200",
            "300": "bg-purple-300",
            "400": "bg-purple-400",
            "500": "bg-purple-500",
            "600": "bg-purple-600",
            "700": "bg-purple-700",
            "800": "bg-purple-800",
            "900": "bg-purple-900"
        },

        {
            "id": "Pink",
            "50": "bg-pink-50",
            "100": "bg-pink-100",
            "200": "bg-pink-200",
            "300": "bg-pink-300",
            "400": "bg-pink-400",
            "500": "bg-pink-500",
            "600": "bg-pink-600",
            "700": "bg-pink-700",
            "800": "bg-pink-800",
            "900": "bg-pink-900"
        },

    ],
    colores_borde: [

        {
            "id": "Gray",
            "50": "-gray-50",
            "100": "-gray-100",
            "200": "-gray-200",
            "300": "-gray-300",
            "400": "-gray-400",
            "500": "-gray-500",
            "600": "-gray-600",
            "700": "-gray-700",
            "800": "-gray-800",
            "900": "-gray-900"
        },

        {
            "id": "Red",
            "50": "-red-50",
            "100": "-red-100",
            "200": "-red-200",
            "300": "-red-300",
            "400": "-red-400",
            "500": "-red-500",
            "600": "-red-600",
            "700": "-red-700",
            "800": "-red-800",
            "900": "-red-900"
        },

        {
            "id": "Yellow",
            "50": "-yellow-50",
            "100": "-yellow-100",
            "200": "-yellow-200",
            "300": "-yellow-300",
            "400": "-yellow-400",
            "500": "-yellow-500",
            "600": "-yellow-600",
            "700": "-yellow-700",
            "800": "-yellow-800",
            "900": "-yellow-900"
        },

        {
            "id": "Green",
            "50": "-green-50",
            "100": "-green-100",
            "200": "-green-200",
            "300": "-green-300",
            "400": "-green-400",
            "500": "-green-500",
            "600": "-green-600",
            "700": "-green-700",
            "800": "-green-800",
            "900": "-green-900"
        },

        {
            "id": "Blue",
            "50": "-blue-50",
            "100": "-blue-100",
            "200": "-blue-200",
            "300": "-blue-300",
            "400": "-blue-400",
            "500": "-blue-500",
            "600": "-blue-600",
            "700": "-blue-700",
            "800": "-blue-800",
            "900": "-blue-900"
        },
        {
            "id": "Indigo",
            "50": "-indigo-50",
            "100": "-indigo-100",
            "200": "-indigo-200",
            "300": "-indigo-300",
            "400": "-indigo-400",
            "500": "-indigo-500",
            "600": "-indigo-600",
            "700": "-indigo-700",
            "800": "-indigo-800",
            "900": "-indigo-900"
        },

        {
            "id": "Purple",
            "50": "-purple-50",
            "100": "-purple-100",
            "200": "-purple-200",
            "300": "-purple-300",
            "400": "-purple-400",
            "500": "-purple-500",
            "600": "-purple-600",
            "700": "-purple-700",
            "800": "-purple-800",
            "900": "-purple-900"
        },

        {
            "id": "Pink",
            "50": "-pink-50",
            "100": "-pink-100",
            "200": "-pink-200",
            "300": "-pink-300",
            "400": "-pink-400",
            "500": "-pink-500",
            "600": "-pink-600",
            "700": "-pink-700",
            "800": "-pink-800",
            "900": "-pink-900"
        },

    ],

    colores_text: [

        {
            "id": "Gray",
            "50": "text-gray-50",
            "100": "text-gray-100",
            "200": "text-gray-200",
            "300": "text-gray-300",
            "400": "text-gray-400",
            "500": "text-gray-500",
            "600": "text-gray-600",
            "700": "text-gray-700",
            "800": "text-gray-800",
            "900": "text-gray-900"
        },

        {
            "id": "Red",
            "50": "text-red-50",
            "100": "text-red-100",
            "200": "text-red-200",
            "300": "text-red-300",
            "400": "text-red-400",
            "500": "text-red-500",
            "600": "text-red-600",
            "700": "text-red-700",
            "800": "text-red-800",
            "900": "text-red-900"
        },

        {
            "id": "Yellow",
            "50": "text-yellow-50",
            "100": "text-yellow-100",
            "200": "text-yellow-200",
            "300": "text-yellow-300",
            "400": "text-yellow-400",
            "500": "text-yellow-500",
            "600": "text-yellow-600",
            "700": "text-yellow-700",
            "800": "text-yellow-800",
            "900": "text-yellow-900"
        },

        {
            "id": "Green",
            "50": "text-green-50",
            "100": "text-green-100",
            "200": "text-green-200",
            "300": "text-green-300",
            "400": "text-green-400",
            "500": "text-green-500",
            "600": "text-green-600",
            "700": "text-green-700",
            "800": "text-green-800",
            "900": "text-green-900"
        },

        {
            "id": "Blue",
            "50": "text-blue-50",
            "100": "text-blue-100",
            "200": "text-blue-200",
            "300": "text-blue-300",
            "400": "text-blue-400",
            "500": "text-blue-500",
            "600": "text-blue-600",
            "700": "text-blue-700",
            "800": "text-blue-800",
            "900": "text-blue-900"
        },
        {
            "id": "Indigo",
            "50": "text-indigo-50",
            "100": "text-indigo-100",
            "200": "text-indigo-200",
            "300": "text-indigo-300",
            "400": "text-indigo-400",
            "500": "text-indigo-500",
            "600": "text-indigo-600",
            "700": "text-indigo-700",
            "800": "text-indigo-800",
            "900": "text-indigo-900"
        },

        {
            "id": "Purple",
            "50": "text-purple-50",
            "100": "text-purple-100",
            "200": "text-purple-200",
            "300": "text-purple-300",
            "400": "text-purple-400",
            "500": "text-purple-500",
            "600": "text-purple-600",
            "700": "text-purple-700",
            "800": "text-purple-800",
            "900": "text-purple-900"
        },

        {
            "id": "Pink",
            "50": "text-pink-50",
            "100": "text-pink-100",
            "200": "text-pink-200",
            "300": "text-pink-300",
            "400": "text-pink-400",
            "500": "text-pink-500",
            "600": "text-pink-600",
            "700": "text-pink-700",
            "800": "text-pink-800",
            "900": "text-pink-900"
        },

    ],
    colores_array: [
        'Slate',
        'Gray',
        'Zinc',
        'Neutral',
        'Stone',
        'Red',
        'Orange',
        'Amber',
        'Yellow',
        'Lime',
        'Green',
        'Emerald',
        'Teal',
        'Cyan',
        'Sky',
        'Blue',
        'Indigo',
        'Violet',
        'Purple',
        'Fuchsia',
        'Pink',
        'Rose',
    ],
    redes_l: [{
            id: 'lab la-whatsapp',
            nombre: 'WhatsApp'
        },
        {
            id: 'lab la-telegram',
            nombre: 'Telegram'
        },
        {
            id: 'lab la-facebook',
            nombre: 'Facebook'
        },
        {
            id: 'lab la-discord',
            nombre: 'Discord'
        },
        {
            id: 'lab la-github',
            nombre: 'Github'
        },
        {
            id: 'lab la-instagram',
            nombre: 'Instagram'
        },

        {
            id: 'lab la-snapchat',
            nombre: 'Snapchat'
        },
        {
            id: 'lab la-twitter',
            nombre: 'Twiter'
        },
        {
            id: 'lab la-snapchat-ghost',
            nombre: 'Snapchat'
        },
        {
            id: 'lab la-pinterest',
            nombre: 'Pinteres'
        },
        {
            id: 'lni lni-tiktok',
            nombre: 'Tiktok'
        },
    ],
    }
  },

  created() {

  },


}