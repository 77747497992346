<template>
<div class="h-screen w-full  ">

    <div class="h-screen w-full  ">
        <header class="absolute bg-white left-0 top-0 z-50 w-full">
            <div class="container">
                <div class="relative -mx-4 flex items-center justify-between">
                    <div class="w-60 max-w-full px-4">
                        <a  class="block w-full py-5">

                            <div v-if="getU().hasOwnProperty('logo_favicon')" @click="$router.push('/')" class="w-full text-center flex flex-wrap content-center justify-start px-10 h-full">
                                <img class="w-10 h-10 rounded bg-pink-300" :class="getU().color_logo" :src="getU().logo_favicon" :alt="getU().nombre" />
                            </div>
                        </a>
                    </div>
                    <div class="flex w-full items-center justify-between px-4">
                        <div>
                            <button @click="navbarOpen = !navbarOpen" :class="navbarOpen && 'navbarTogglerActive'" id="navbarToggler" class="absolute right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden">
                                <span class="relative my-[6px] block h-[2px] w-[30px] bg-body-color"></span>
                                <span class="relative my-[6px] block h-[2px] w-[30px] bg-body-color"></span>
                                <span class="relative my-[6px] block h-[2px] w-[30px] bg-body-color"></span>
                            </button>
                            <nav x-transition :class="!navbarOpen && 'hidden'" id="navbarCollapse" class="absolute right-4 top-full w-full max-w-[250px] rounded-lg bg-white py-5 px-6 shadow transition-all lg:static lg:block lg:w-full lg:max-w-full lg:shadow-none">
                                <ul class="blcok lg:flex">
                                    <li>

                                        <a href="/" class="flex py-2 text-base font-medium text-dark hover:text-primary lg:ml-12 lg:inline-flex">
                                            Inicio
                                        </a>
                                    </li>

                                 
                                </ul>
                            </nav>
                        </div>

                    </div>
                </div>
            </div>
        </header>
        <!-- ====== Navbar Section End -->

        <!-- ====== Hero Section Start -->
        <div class="relative  justify-between flex flex-wrap content-center h-full w-full  overflow-y-scroll bg-gray-50">
            <div class=" h-full w-full  ">

                <section class="relative flex flex-wrap lg:h-screen lg:items-center w-full bg-gray-50 pt-24">
                    <div class="w-full px-4 py-12 sm:px-6 sm:py-16 lg:w-1/2  lg:px-8 lg:py-24">
                        <div class="mx-auto max-w-lg text-center">
                            <h1 class="text-2xl md:text-3xl xl:text-5xl lg:text-3xl font-bold sm:text-3xl">Crear una cuenta!</h1>

                            <p class="mt-4 text-gray-500">

                                Escribe tu apodo para que tus seguidores te encuentren

                            </p>
                        </div>

                        <form action="" class="mx-auto mt-8 mb-0 max-w-md space-y-4">
                            <div>
                                <label for="email" class="sr-only">Codigo de invitación</label>

                                <div class="relative">
                                    <input v-model="refer"  :maxlength="12"  @keydown.space.prevent type="text" class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm" placeholder="Codigo de invitación" />

                                    <span class="absolute inset-y-0 right-4 inline-flex text-purple-800  text-2xl font-extrabold items-center">
                                        <i class="las la-link"></i>
                                    </span>
                                </div>
                            </div>
                            <div>
                                <label for="email" class="sr-only">@Usuario</label>

                                <div class="relative">
                                    <input v-model="username"  :maxlength="12"  @keydown.space.prevent type="text" class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm" placeholder="Nombre de usuario" />

                                    <span class="absolute inset-y-0 right-4 inline-flex text-purple-800  text-2xl font-extrabold items-center">
                                        <i class="las la-link"></i>
                                    </span>
                                </div>
                            </div>

                            <div>
                                <label for="password" class="sr-only">Correo</label>
                                <div class="relative">
                                    <input v-model="correo" type="email" class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm" placeholder="Correo" />

                                    <span class="absolute inset-y-0 right-4 text-2xl font-extrabold inline-flex items-center">
                                        <i class="las la-at"></i>
                                    </span>
                                </div>
                            </div>
                            <div>
                                <label for="password" class="sr-only">Contraseña</label>
                                <div class="relative">
                                    <input v-model="contrasena" :type="showpass?'text':'password'" class="w-full rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm" placeholder="Contrasena" />

                                    <span @click="showpass=!showpass" class="absolute inset-y-0 cursor-pointer right-4 text-2xl font-extrabold inline-flex items-center">

                                        <i v-if="showpass" class="las la-eye"></i>
                                        <i v-else class="las la-eye-slash"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="flex items-start justify-between">
                                <p class="text-sm text-left text-gray-500">

                                    Al hacer clic en Crear cuenta, aceptas las condiciones de poplink.space <br>

                                    <a href="/terminos" class="underline">Términos y condiciones</a>
                                    , confirme que ha leído nuestro

                                    <a href="/privacy" class="underline">Aviso de Privacidad</a>
                                    .

                                </p>

                            </div>
                            <div class="flex items-center justify-between">

                                <button @click="crearCuenta()" type="button" class=" inline-block w-full rounded-full bg-purple-700 px-5 py-3 text-sm font-medium text-white">
                                    Crear cuenta
                                </button>
                            </div>
                        </form>
                    </div>

                    <div class="relative h-96 w-full sm:h-96 lg:h-full xl:h-full lg:w-1/2 bg-red-100">
                        <img alt="Welcome" src="https://res.cloudinary.com/dgzea9m7q/image/upload/q_auto:low/v1667982138/software/links/pexels-lady-escabia-2299390.jpg" class="absolute inset-0 h-full w-full object-cover" />
                    </div>
                </section>

            </div>
        </div>

    </div>

</div>
</template>

<script>
import {
    emitter
} from "@/mitt.js";
import {
    useConfigStore
} from '../stores/config'

import generales from "@/functions/generales";
import infosistem from "@/Services/infosistem";
import user_s from "@/Services/user";
import {
    useInfoStore
} from '../stores/info'

import {
    useUserStore
} from '../stores/user'
import {
    useRouter,
    useRoute
} from 'vue-router'
export default {
    components: {

    },
    mixins: [generales, infosistem],
    data() {
        const user = useUserStore()
        const info = useInfoStore()
        return {

            id: 0,
            nombre: '',
            username: '',
            refer:'',
            correo: '',
            contrasena: '',
            showpass: false,
            user: user,
            descripcion: '',
            precio: '',
            boletos: '',
            activo: true,

            info,
            data: []

        }

    },
    setup() {
        const router = useRouter()
        const route = useRoute()

        const config = useConfigStore();

        const salir = () => {
            user.limpiar([]);
            router.push('/');
        }
        return {
            active: 0,
            config,

        }

    },
    mounted() {

      if(this.$route.query.hasOwnProperty('poplink')){
        this.username=this.$route.query.poplink;
      }
        if (this.user.issetToken()) {
            if (this.user.issetCategoria()) {
                this.$router.push('/admin/perfil');
            } else {
                this.$router.push('/c/cuenta/categorias');
            }

        }
    },
    created() {
        //this.getData()
        // this.get_info();
    },
    methods: {
        getU() {
            let dat = this.info.getAll();
            console.log(dat)
            return dat;
        },
        async crearCuenta() {
            emitter.emit("loading", true)
            let dat = {
                username: this.username,
                refer: this.refer,
                password: this.contrasena,
                email: this.correo
            }
            let data = await user_s.REGISTRO_USER(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {

                    this.user.set(response);
                    this.$router.push('/c/cuenta/categorias');

                } else {
                    emitter.emit("alerta_show", response.error);
                }
                emitter.emit("loading", false)
            });
        },

    }
}
</script>
