import { createRouter, createWebHistory } from "vue-router";

import Login from "../views/Login.vue";

import Root from "../views/Root.vue";
import HomePage from "../views/HomePage.vue";
import CrearCuenta from "../views/CrearCuenta.vue";
import CategoriasCuenta from "../views/CategoriasCuenta.vue";
import AgregarContrasenaCuenta from "../views/AgregarContrasenaCuenta.vue";
import CuentaCreadaRedirect from "../views/CrearCuenta.vue";



import Welcome from "../views/app/welcome.vue";
import Perfiles from "../views/Perfiles.vue";
import usuarios from "../views/app/admin/usuarios.vue";
import IndexAdmin from "../views/app/admin/index.vue";
import Suscriptores from "../views/app/admin/suscriptores.vue";
import Grupos from "../views/app/admin/grupos.vue";
import Perfil from "../views/app/admin/perfil.vue";
import Apariencia from "../views/app/admin/apariencia.vue";
import SocialAdmin from "../views/app/admin/SocialAdmin.vue";

const routes = [
  {
    path: "/",
    name: "hp",
    component: HomePage
  },
  {
    path: "/:id",
    name: "Perfiles",
    component: Perfiles
  },
  {
    path: "/c/cuenta/",
    name: "crear_cuenta_paso_1",
    component: CrearCuenta
  },
  {
    path: "/c/cuenta/secure",
    name: "crear_cuenta_paso2",
    component: AgregarContrasenaCuenta
  },
  {
    path: "/c/cuenta/categorias",
    name: "crear_cuenta_paso3",
    component:CategoriasCuenta
  },
  {
    path: "/c/cuenta/success",
    name: "crear_cuenta_paso4",
    component: CuentaCreadaRedirect
  },

  {
    path: "/c/login",
    name: "login",
    component: Login
  },

  {
    path: "/d/login",
    name: "loginadmin",
    component: Login
  },
  

  
  {
    path: "/admin",
    name: "admin",
    component: Root,
    children: [
      {
        path: '',
        name: 'links',
        component: IndexAdmin
      },
      {
        path: 'apariencia',
        name: 'apariencia',
        component: Apariencia
      },
      {
        path: 'suscriptores',
        name: 'suscriptores',
        component: Suscriptores
      },
      {
        path: 'grupos',
        name: 'grupos',
        component: Grupos
      },
      {
        path: 'perfil',
        name: 'perfil',
        component: Perfil
      },
  
      
      {
        path: 'welcome',
        name: 'Welcome',
        component: Welcome
      },
  
     
    
      {
        path: 'usuarios',
        name: 'usuarios',
        component: usuarios
      },
      {
        path: 'social',
        name: 'social',
        component: SocialAdmin
      },
     
      

    ]
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
