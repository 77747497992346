<template>
    <div :class="user.color_fondo"
        class="w-full h-full    relative overflow-hidden flex flex-wrap content-start justify-center">
        <!-- :style="user.imagen_fondo!=''||user.imagen_fondo!=null?'background-position:center;background-size:100% 100%;background:url('+getImage(user.imagen_fondo)+');':''"-->

        <div class="h-full w-full overflow-y-auto flex flex-wrap justify-center content-start">
            <div class="  max-w-md w-full flex p-10 px-2 flex-wrap content-start justify-center">
                <div class="flex flex-col gap-1 text-center items-center z-30">
                    <div class="w-full flex flex-wrap justify-center content-center relative h-36 ">

                        <div style="height:138px;width:138px"
                            class="rounded-full absolute bg-transparent  overflow-hidden z-0  flex flex-wrap justify-center content-center ">
                            <img class="h-32 w-32 bg-white  rounded-full shadow object-cover  z-10" :src="user.foto"
                                alt="">

                            <div class="w-48 h-48 absolute animate-ping animate-spin z-0"
                                style="background:radial-gradient(at left bottom, rgb(88, 28, 135), rgb(56, 189, 248), rgb(134, 25, 143))">
                            </div>
                        </div>
                        <div v-if="user.verified" class="checkmark text-blue-500">
                            <svg class="star" height="19" viewBox="0 0 19 19" width="19"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z">
                                </path>
                            </svg>
                            <svg class="star" height="19" viewBox="0 0 19 19" width="19"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z">
                                </path>
                            </svg>
                            <svg class="star" height="19" viewBox="0 0 19 19" width="19"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z">
                                </path>
                            </svg>
                            <svg class="star" height="19" viewBox="0 0 19 19" width="19"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z">
                                </path>
                            </svg>
                            <svg class="star" height="19" viewBox="0 0 19 19" width="19"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z">
                                </path>
                            </svg>
                            <svg class="star" height="19" viewBox="0 0 19 19" width="19"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z">
                                </path>
                            </svg>
                            <svg class="checkmark__check opacity-0" height="36" viewBox="0 0 48 36" width="48"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M47.248 3.9L43.906.667a2.428 2.428 0 0 0-3.344 0l-23.63 23.09-9.554-9.338a2.432 2.432 0 0 0-3.345 0L.692 17.654a2.236 2.236 0 0 0 .002 3.233l14.567 14.175c.926.894 2.42.894 3.342.01L47.248 7.128c.922-.89.922-2.34 0-3.23">
                                </path>
                            </svg>
                            <svg class="checkmark__background opacity-0" height="115" viewBox="0 0 120 115" width="120"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z">
                                </path>
                            </svg>
                        </div>
                    </div>

                    <div class="font-extrabold mt-4 flex  flex-wrap justify-center content-center"
                        :class="user.color_titulo">@{{user.username}}

                        <div v-if="user.verified" class="ml-2 checkmark2 text-blue-500 ">
                            <svg class="checkmark__check2 " height="6" viewBox="0 0 48 36" width="6"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M47.248 3.9L43.906.667a2.428 2.428 0 0 0-3.344 0l-23.63 23.09-9.554-9.338a2.432 2.432 0 0 0-3.345 0L.692 17.654a2.236 2.236 0 0 0 .002 3.233l14.567 14.175c.926.894 2.42.894 3.342.01L47.248 7.128c.922-.89.922-2.34 0-3.23">
                                </path>
                            </svg>
                            <svg class="checkmark__background " height="17" viewBox="0 0 120 115" width="17"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z">
                                </path>
                            </svg>
                        </div>
                    </div>


                    <div class="text-base  font-medium  flex justify-center items-center" :class="user.color_titulo">

                        {{user.descripcion}}
                    </div>
                </div>
                <div class="content-center  flex flex-wrap justify-center mt-2 mb-8 w-full z-30">

                   

               <div v-if="user.preferencias.estilo_iconos=='minimal'" class="w-4/6 flex flex-wrap justify-center content-center">
              
               <IconsMinimal :user="user"></IconsMinimal>

             </div>





                    <div v-if="user.preferencias.estilo_iconos=='clasic'" class="w-4/6">
                        <a v-if="user.preferencias.instagram!=''&&user.preferencias.instagram!=null"
                            :href="'https://'+user.preferencias.instagram" target="_blank">
                            <i class="text-3xl  cursor-pointer lab la-instagram  "
                                :class="user.color_titulo+' hover:'+user.color_titulo+' active:'+user.color_titulo+' focus:'+user.color_titulo"></i>
                        </a>
                        <a v-if="user.preferencias.whatsapp!=''&&user.preferencias.whatsapp!=null"
                            :href="'https://'+user.preferencias.whatsapp" target="_blank"><i
                                class="text-3xl mx-2 cursor-pointer lab la-whatsapp hover:opacity-70"
                                :class="user.color_titulo+' active:'+user.color_titulo+' focus:'+user.color_titulo"></i></a>
                        <a v-if="user.preferencias.facebook!=''&&user.preferencias.facebook!=null"
                            :href="'https://'+user.preferencias.facebook" target="_blank"><i
                                class="text-3xl mx-2 cursor-pointer lab la-facebook hover:opacity-70"
                                :class="user.color_titulo+' active:'+user.color_titulo+' focus:'+user.color_titulo"></i></a>
                        <a v-if="user.preferencias.telegram!=''&&user.preferencias.telegram!=null"
                            :href="'https://'+user.preferencias.telegram" target="_blank"><i
                                class="text-3xl mx-2 cursor-pointer lab la-telegram hover:opacity-70"
                                :class="user.color_titulo+' active:'+user.color_titulo+' focus:'+user.color_titulo"></i></a>
                        <a v-if="user.preferencias.twiter!=''&&user.preferencias.twiter!=null"
                            :href="'https://'+user.preferencias.twiter" target="_blank"><i
                                class="text-3xl mx-2 cursor-pointer lab la-twitter hover:opacity-70"
                                :class="user.color_titulo+' active:'+user.color_titulo+' focus:'+user.color_titulo"></i></a>
                        <a v-if="user.preferencias.twitch!=''&&user.preferencias.twitch!=null"
                            :href="'https://'+user.preferencias.twitch" target="_blank"><i
                                class="text-3xl mx-2 cursor-pointer lab la-twitch hover:opacity-70"
                                :class="user.color_titulo+' active:'+user.color_titulo+' focus:'+user.color_titulo"></i></a>
                        <a v-if="user.preferencias.github!=''&&user.preferencias.github!=null"
                            :href="'https://'+user.preferencias.github" target="_blank"><i
                                class="text-3xl mx-2 cursor-pointer lab la-github hover:opacity-70"
                                :class="user.color_titulo+' active:'+user.color_titulo+' focus:'+user.color_titulo"></i></a>
                        <a v-if="user.preferencias.snapchat!=''&&user.preferencias.snapchat!=null"
                            :href="'https://'+user.preferencias.snapchat" target="_blank"><i
                                class="text-3xl mx-2 cursor-pointer lab la-snapchat hover:opacity-70"
                                :class="user.color_titulo+' active:'+user.color_titulo+' focus:'+user.color_titulo"></i></a>
                        <a v-if="user.preferencias.tiktok!=''&&user.preferencias.tiktok!=null"
                            :href="'https://'+user.preferencias.tiktok" target="_blank"><i
                                class="text-3xl mx-2 cursor-pointer lni lni-tiktok hover:opacity-70"
                                :class="user.color_titulo+' active:'+user.color_titulo+' focus:'+user.color_titulo"></i></a>
                    </div>
                </div>

                <template v-for="(row,index) in user.links" v-bind:key="index">

                    <a class="w-full px-5 mt-2 z-30 cursor-pointer"
                        v-if="row.tipo=='url'||(row.tipo=='grupo')&&(row.idpago!=''&&row.grupoid!=null)"
                        @click="OpenLink(row)">
                        <div class="w-full flex py-1 px-3 h-16 flex-wrap font-semibold text-center content-center  "
                            :class="user.tipo_borde_boton+'  '+user.color_texto_botton+' '+user.color_fondo_botton+' '+user.color_borde+' '+user.tamano_borde+' '+user.radius_boton">
                            <div class="content-center h-full h-full  flex flex-wrap  w-1/6 relative"
                                v-if="row.imagen!=''&&row.imagen!=null">


                                <img class="rounded-full h-8 w-8   object-cover" :src="row.imagen">

                            </div>
                            <div class="content-center h-full flex flex-wrap w-1/6"
                                v-if="row.icono!=''&&row.icono!=null">
                            <div class="w-8 h-8 flex flex-wrap justify-center content-center">
                                <i :class="row.icono" class="text-2xl"></i>
                            </div>
                              
                            </div>
                            <div class="content-center h-full flex flex-wrap text-base justify-between  w-5/6">
                                <div class="px-2 py-2">{{row.nombre}}</div> <span
                                    class="text-green-800 py-2 ">{{row.tipo=='grupo'?'$'+Number(row.precio).toFixed(0):''}}</span>
                            </div>

                        </div>
                    </a>
                </template>


            </div>

        </div>
        <div class="absolute z-20 h-full w-full  bg-gradient-to-t from-transparent  top-0 left-0" :class="user.degradado">
        </div>
        <img v-if="user.imagen_fondo!=''&&user.imagen_fondo!=null" :src="TypeImg(user.imagen_fondo)"
            class="object-cover absolute z-10 top-0 left-0 h-full w-full">
        <el-dialog v-model="modalpago" title="" width="50%">
            <div class="text-2xl font-extrabold w-full mb-5 text-blue-500">Pagar suscripción a <br> grupo/canal</div>
            <p class="text-blue-500 text-left">Para poder pagar es necesario su usuario de telegram,<br> para obtenerlo
                es
                necesario dar click <a class="text-black font-extrabold" target="_blank"
                    href="https://t.me/Popcing_getid_bot">aquí</a></p>
            <p class="text-left"><span class="text-green-500 mr-2 ">¿Para que es necesario?</span><span>se utiliara para
                    proporcionarle acceso a el grupo una vez se confirme su pago</span></p>
            <input v-model="nombre" class="w-full h-12 bg-gray-200 px-2 mb-1 mt-2" placeholder="Nombre completo">
            <input v-model="teliduser" class="w-full h-12 bg-gray-200 px-2" placeholder="identificador telegram">
            <div class="w-full h-48 overflow-y-scroll flex flex-wrap justify-center content-start">
                <div class="text-xl w-full my-10 font-extrabold">{{sel_grupo.nombre}}</div>
                <div class="w-full mb-5 justify-center text-base flex">Precio suscripción:<span
                        class="font-extrabold mx-5 text-base text-green-500">${{sel_grupo.precio}}</span></div>
                <button type="button" class="w-6/12 bg-green-500 text-black rounded text-xl text-center px-3 py-1"
                    @click="guardarPago()">
                    Pagar
                </button>
            </div>

            <template #footer>
                <span class="dialog-footer">

                    <button type="button" class="bg-blue-500 text-black rounded text-xs text-center px-3 py-1"
                        @click="modalpago = false">
                        Cancelar
                    </button>
                </span>
            </template>
        </el-dialog>

    </div>
</template>

<script>
//@ts-ignore
import {
    emitter
} from "@/mitt.js";
import generales from '@/functions/generales'
import pagos from "@/Services/pagos";
import IconsMinimal from "@/components/IconsMinimal";
//import palabras from "@/mixins/palabras";
export default {
    name: "Home",
    components: {
        IconsMinimal
    },
    mixins: [generales],
    props: {

        user: {
            type: Object,

        },



    },
    data() {
        return {
            modalpago: false,
            teliduser: '',
            nombre: '',
            sel_grupo: {
                id: '',
                precio: 0
            }
        }
    },

    mounted() {

    },
    methods: {
        TypeImg(url) {
            let pos = 0;
            pos = url.search("https://images.pexels.com/");
            if (pos != -1) {
                return url + '?auto=compress&cs=tinysrgb&h=650&w=940';
            } else {
                return url;
            }
            //?auto=compress&cs=tinysrgb&h=650&w=940




        },
        OpenLink(row) {
            if (row.tipo == 'url') {

                window.open('https://'+row.url);
            }
            if (row.tipo == 'grupo') {
                this.sel_grupo = row;
                this.modalpago = true;
            }
        },
        async guardarPago() {
            if (this.sel_grupo == null) {
                emitter.emit("alerta_show", 'No selecciono un grupo');
                return;
            }
            if (this.teliduser == '') {
                emitter.emit("alerta_show", 'El userid de telegram es necesario');
                return;
            }
            if (this.nombre == '') {
                emitter.emit("alerta_show", 'El nombre es necesario');
                return;
            }


            emitter.emit("loading", true)
            let dat = {
                id: this.sel_grupo.id,
                prom: this.sel_grupo.users_id,
                total: this.sel_grupo.precio,
                telegramid: this.teliduser,
                nombre: this.nombre
            }
            await pagos.BOTTON(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {
                    //this.limpiar();
                    //console.log(response)
                    //this.getData();
                    //window.location.assign(response)
                    window.location.href = response
                    //this.$router.push(response); 
                } else {

                    // emitter.emit("alerta_show",response.msg);

                }


                emitter.emit("loading", false)
            });
        },
        IsMov() {

            //return true;
            return this.$isMobile()
        },
        ismobile() {
            let check = false;
            (function (a) {
                if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
            })(navigator.userAgent || navigator.vendor || window.opera);
            return check;
        },
        getImage(url) {
            if (!this.ismobile()) {
                return url + '?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800'
            } else {
                return url + '?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200'
            }

        },
        getU() {
            return this.user.getAll();
        },

    }
}
</script>

<style>
td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.vgt-wrap {
    height: 100%;
}

.vgt-inner-wrap {
    height: 100%;
}

:root {
    --color-blue: #0094ff;
    --color-white: #fff;
    --curve: cubic-bezier(0.42, 0, 0.275, 1.155);
}

.star {
    position: absolute;
    animation: grow 3s infinite;
    fill: var(--color-blue);
    opacity: 0;
}

.star:nth-child(1) {
    width: 12px;
    height: 12px;
    left: 12px;
    top: 16px;
}

.star:nth-child(2) {
    width: 18px;
    height: 18px;
    left: 168px;
    top: 84px;
}

.star:nth-child(3) {
    width: 10px;
    height: 10px;
    left: 32px;
    top: 162px;
}

.star:nth-child(4) {
    width: 20px;
    height: 20px;
    left: 82px;
    top: -12px;
}

.star:nth-child(5) {
    width: 14px;
    height: 14px;
    left: 125px;
    top: 162px;
}

.star:nth-child(6) {
    width: 10px;
    height: 10px;
    left: 16px;
    top: 16px;
}

.star:nth-child(1) {
    animation-delay: 1.5s;
}

.star:nth-child(2) {
    animation-delay: 3s;
}

.star:nth-child(3) {
    animation-delay: 4.5s;
}

.star:nth-child(4) {
    animation-delay: 6s;
}

.star:nth-child(5) {
    animation-delay: 7.5s;
}

.star:nth-child(6) {
    animation-delay: 9s;
}

.checkmark {
    position: relative;
    padding: 30px;
    animation: checkmark 5m var(--curve) both;
}

.checkmark2 {
    position: relative;
    padding: 0px;
    padding-top: 5px;
    animation: checkmark 5m var(--curve) both;
}


.checkmark__check2 {
    position: absolute;
    top: 60%;
    left: 50%;
    z-index: 10;
    transform: translate3d(-50%, -50%, 0);
    fill: var(--color-white);
}

.checkmark__background2 {
    fill: var(--color-blue);
    animation: rotate 35s linear both infinite;
}

.checkmark__check {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    transform: translate3d(-50%, -50%, 0);
    fill: var(--color-white);
}

.checkmark__background {
    fill: var(--color-blue);
    animation: rotate 35s linear both infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes grow {

    0%,
    100% {
        transform: scale(0);
        opacity: 0;
    }

    50% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes checkmark {

    0%,
    100% {
        opacity: 0;
        transform: scale(0);
    }

    10%,
    50%,
    90% {
        opacity: 1;
        transform: scale(1);
    }
}
</style>
