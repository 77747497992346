

import initmixin from "@/Services/initmixin";

export default {
 
  //mixins: [initmixin],
 // GET_CLIENTES CREAR_CLIENTES EDITAR_CLIENTES ELIMINAR_CLIENTES

BOTTON: async function( data) {
  let url='/pago/'
  return initmixin.formatrespuesta('post', data,url,true)
},

}