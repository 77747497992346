<template>
<div class="w-full h-full ">
    <div  class="relative flex flex-col min-w-0 break-words  w-full  shadow-lg rounded overflow-hidden bg-white h-full">
        <div class="overflow-x-auto bg-white shadow overflow-y-auto  h-full w-full">
          <div v-if="buscador" class="text-left w-full flex flex-wrap bg-gray-300">
            <div :colspan="getColumns()" class="bg-gray-400 bg-opacity-80  z-10 sticky top-10  border-gray-600 px-6 py-1 text-gray-600 font-bold tracking-wider uppercase text-xs w-full">
                <div class=" flex justify-between items-center z-20">
                    <div class="flex-1 pr-4">

                        <div class="relative md:w-8/6 flex py-2 flex-wrap  justify-start">
                            <input v-if="filtroApi==true" v-model="buscarapi" type="search" class="w-3/6 pl-10 pr-4 h-8 py-2 rounded shadow focus:outline-none text-sm focus:shadow-outline text-gray-600 font-medium" placeholder="Buscador...">
                            <button v-if="filtroApi==true" class="text-gray-800  bg-gray-500 h-8 mx-2 rounded bg-opacity-40  border hover:text-white  hover:bg-blue-700 border-solid border-gray-800 font-bold  px-6 py-1 text-sm outline-none   focus:outline-none   ease-linear transition-all duration-150" type="button" @click="$parent.buscarGet(buscarapi)">
                                Filtrar
                            </button>

                            <input v-if="filtroApi==false" v-model="buscar" type="search" class="w-2/6 pl-10 pr-4 h-8 py-2 rounded-lg shadow focus:outline-none focus:shadow-outline text-gray-600 font-medium" placeholder="Buscador...">

                        </div>
                    </div>
                    <div>
                        <div class="shadow rounded-lg flex">
                            <div class="relative">
                                <button v-if="showcols" class="rounded-lg inline-flex items-center bg-white hover:text-blue-500 focus:outline-none focus:shadow-outline text-gray-500 font-semibold py-2 px-2 md:px-4" @click.prevent="open = !open">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 md:hidden" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <rect x="0" y="0" width="24" height="24" stroke="none" />
                                        <path d="M5.5 5h13a1 1 0 0 1 0.5 1.5L14 12L14 19L10 16L10 12L5 6.5a1 1 0 0 1 0.5 -1.5" />
                                    </svg>
                                    <span class="hidden md:block">Columnas</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 ml-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <rect x="0" y="0" width="24" height="24" stroke="none" />
                                        <polyline points="6 9 12 15 18 9" />
                                    </svg>
                                </button>

                                <div v-show="open" class=" absolute z-20 top-0 right-0 w-40 bg-white rounded-lg shadow-lg mt-12 -mr-1 block py-1 overflow-hidden" @click="open = false">
                                    <template v-for="heading in columns" :key="heading.key">
                                        <label v-if="!heading.hasOwnProperty('children')" class="flex justify-start items-center text-truncate hover:bg-gray-100 px-4 py-2">
                                            <div class="text-teal-600 mr-3">
                                                <input v-model="heading.show" type="checkbox" class="form-checkbox focus:outline-none focus:shadow-outline" :checked="heading.show" :value="heading.key">
                                            </div>
                                            <div class="select-none text-gray-700" v-text="heading.name" />
                                        </label>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>




            <table v-if="!IsMov()" class=" table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                <thead class="bg-gray-200 bg-opacity-60 z-10">
                    <tr>
                        <template v-for="(heading,index) in columns" :key="index">
                            <th v-show="heading.show&&!heading.hasOwnProperty('children')" class="bg-yellow-400 font-sans tracking-wide normal-case text-xs bg-opacity-20  sticky top-0   border-gray-600  py-1 text-gray-600 font-bold tracking-wider uppercase text-xs z-30"  >
                             <span>{{heading.name}}</span>
                            </th>
                        </template>
                    </tr>
                 
                </thead>
                <tbody class="bg-white divide-y divide-gray-200 z-0">
                    <template v-for="(row,index) in rows">
                        <tr v-if="buscadorpropiedades(row)" :key="index">
                            <template v-for="(row_col,index_col) in columns" :key="index_col">
                                <td v-if="row_col.key=='files'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                                    <div class="mb-5  relative flex flex-wrap z-10">
                                        <img src="https://res.cloudinary.com/dgzea9m7q/image/upload/v1656487048/software/icons8-plus-48.png" class="z-10 w-8 h-8 rounded-full border-2 " @click="$parent.uploadsel(row.id)">
                                        <img v-for="(row_f,index) in row[row_col.key]" :key="index" :src="'https://ik.imagekit.io/popcing/'+row_f.name+'?t=50'" class="w-8 h-8 z-10 rounded-full border-2  ">
                                    </div>
                                </td>
                                <td v-else-if="row_col.show&&row_col.key=='inventario'" :class="colores(row)+' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                                    <span class="text-black  font-extrabold text-xs px-3 rounded-lg py-1  text-center  items-center">
                                        {{ CalcExistencias(row) }}
                                    </span>
                                </td>
                                <td v-else-if="row_col.show&&row_col.key=='link'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                                    <a v-if="row.link!=null" class="text-green-500   font-extrabold text-xs px-3 rounded-lg py-1  text-center  items-center" target="_blank" :href="'http://localhost:8080/r?u='+row.id+'&code='+row.link">
                                        /r?u={{row.id}}&code={{row.link}}
                                    </a>
                                    <span v-else>N/A</span>
                                </td>
                                <td v-else-if="row_col.show&&row_col.key=='color'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                                    <span class="text-black w-12 h-12  font-extrabold text-xs px-3 rounded-lg py-1  text-center  items-center" :style="'background:'+row.color">

                                    </span>
                                </td>
                                <td v-else-if="row_col.show&&row_col.key=='url'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                                    <div style="width:30px;height:30px;background-size:cover;" class="text-black   font-extrabold text-xs px-3 rounded-lg py-1  text-center  items-center" :style="'background:url('+row.url+'?tr=w-30)'" />

                                </td>
                                <td v-else-if="row_col.show&&row_col.key=='clientes'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                                    <span v-if="row.clientes!=null" class=" w-12 h-12  font-extrabold text-sm px-3 rounded-lg py-1  text-center  items-center">
                                        {{row.clientes.name}}
                                    </span>
                                </td>
                                <td v-else-if="row_col.show&&row_col.key=='usuario'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                                    <span class=" w-12 h-12  font-extrabold text-sm px-3 rounded-lg py-1  text-center  items-center">
                                        {{row.usuario.name}}
                                    </span>
                                </td>
                                <td v-else-if="row_col.show&&row_col.key=='ver_cotizacion'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">

                                   <!-- <span v-if="row.status_verificar=='APROBADA'" class=" w-12 h-12  font-extrabold bg-green-400 text-white cursor-pointer text-xs px-3 rounded-lg py-1  text-center  items-center">
                                        {{row.status_verificar}}
                                    </span>-->

                                    <span  @click="$parent.verCotizacion(row)" class=" w-12 h-12  font-extrabold text-blue-700 cursor-pointer text-sm px-3 rounded-lg py-1  text-center  items-center">
                                        {{row_col.name}}
                                    </span>
                                </td>

                                <td v-else-if="row_col.show&&row_col.key=='fecha_ago'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">

                                    {{timeAgo(row.y+'-'+row.m+'-'+row.d+' '+row.t)}}

                                </td>

                                <td v-else-if="row_col.show&&row_col.key=='sucursal'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                                    <span class=" w-12 h-12  font-extrabold text-base px-3 rounded-lg py-1  text-center  items-center" :style="'color:'+row.sucursales.color">
                                        {{row.sucursales.nombre}}
                                    </span>
                                </td>

                                <td v-else-if="row_col.show&&row_col.key=='variante'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                                    <span class="text-white bg-green-700 px-3 rounded-lg py-1  text-center  items-center" @click="$parent.addVariante(row)">
                                        {{ row_col.name }}
                                    </span>
                                </td>

                                <td v-else-if="row_col.show&&row_col.key=='status_verificar'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                                    <a :class="colorVerificar(row.status_verificar)" class=" px-3 rounded-lg py-1  text-center  items-center cursor-pointer" :target="row.status_verificar!='APROBADA'?'_blank':''" :href="row.status_verificar!='APROBADA'?'/app/nueva/cotizacion?select='+row.id+'&a=produccion':'#'">
                                        {{ row.status_verificar==null||row.status_verificar==''?'SIN VERIFICAR':row.status_verificar }}
                                    </a>
                                </td>
                                <td v-else-if="row_col.show&&row_col.key=='status_instalacion'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                                    <span :class="colorInstalacion(row.status_instalacion)" class="  px-3 rounded-lg py-1  text-center  items-center" @click="$parent.acceionInstalacion(row)">
                                        {{ row.status_instalacion==null||row.status_instalacion==''?'SIN PROGRAMAR':row.status_instalacion }}

                                    </span>
                                </td>
                                <td v-else-if="row_col.show&&row_col.key=='status_produccion'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                                    <span :class="colorProduccion(row.status_produccion)" class=" px-3 rounded-lg py-1  text-center  items-center" @click="$parent.accionProduccion(row)">

                                        {{ row.status_produccion==null||row.status_produccion==''?'PENDIENTE':row.status_produccion }}

                                    </span>
                                </td>

                                <td v-else-if="row_col.show&&row_col.key=='saldo'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                                    <a :href="'/app/cotizaciones?cid='+row.id" target="_blank" class="text-black font-extrabold bg-yellow-500  px-3 rounded-lg py-1  text-center  items-center">
                                        ${{formatPrice( calcularPendiente(row) )}}
                                    </a>
                                </td>
                                <td v-else-if="row_col.show&&row_col.key=='total'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                                    <span class="text-black font-extrabold bg-green-500  px-3 rounded-lg py-1  text-center  items-center">
                                        ${{formatPrice( row.total )}}
                                    </span>
                                </td>
                                <td v-else-if="row_col.show&&row_col.key=='detalle_ventas'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                                    <span class="text-white bg-green-700 px-3 rounded-lg py-1  text-center  items-center" @click="$parent.addVariante(row)">
                                        {{ row.detalle_ventas.length }}
                                    </span>
                                </td>

                                <td v-else-if="row_col.show&&(row_col.key=='tarjeta_debito' )" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                                    <span class="text-black bg-yellow-500 px-3 rounded-lg py-1  text-xs font-bold  text-center  items-center" @click="$parent.addVariante(row)">

                                        ${{ formatPrice(Number(row.tarjeta_debito)) }}
                                    </span>
                                </td>
                                <td v-else-if="row_col.show&&(row_col.key=='efectivo')" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                                    <span class="text-black bg-yellow-500 px-3 rounded-lg py-1  text-xs font-bold text-center  items-center" @click="$parent.addVariante(row)">
                                        ${{ formatPrice(Number(row.efectivo)) }}
                                    </span>
                                </td>
                                <td v-else-if="row_col.show&&(row_col.key=='tarjeta_credito' )" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                                    <span class="text-black bg-yellow-500 px-3 rounded-lg py-1  text-xs font-bold  text-center  items-center" @click="$parent.addVariante(row)">
                                        ${{ formatPrice(Number(row.tarjeta_credito)) }}
                                    </span>
                                </td>
                                <td v-else-if="row_col.show&&row_col.key=='info'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                                    <span class="text-white bg-pink-500 px-3 rounded-lg py-1  text-centerflex items-center" @click="$parent.infoModal(row)">
                                        Informacion
                                    </span>
                                </td>
                                <td v-else-if="row_col.show&&row_col.key=='eliminar'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                                    <span class="text-white bg-red-700 px-3 rounded-lg py-1  text-centerflex items-center" @click="$parent.eliminar(row.id)">
                                        Eliminar
                                    </span>
                                </td>
                                <td v-else-if="row_col.show&&row_col.key=='edit'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                                    <span class="text-white bg-blue-700 px-3 rounded-lg py-1  text-centerflex items-center" @click="$parent.edit(row)">
                                        Editar
                                    </span>
                                </td>

                                <td v-else v-show="row_col.show&&!row_col.hasOwnProperty('children')"  :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                                    <span class="text-gray-700   text-center w-full px-2 flex items-center" :class="row_col.class">
                                        {{ row[row_col.key] }}
                                    </span>
                                </td>
                            </template>
                        </tr>

                    </template>
                </tbody>
            </table>
            <div v-if="IsMov()" class="w-full flex flex-wrap">
              <div class="bg-white divide-y divide-gray-200 z-0 justify-center flex flex-wrap">
                
                <template v-for="(row,index) in rows">
                  <div v-if="buscadorpropiedades(row)" :key="index" class="w-11/12 flex flex-wrap bg-gray-100 justify-center px-2 py-2 rounded-lg my-3 mx-2 shadow ">
                    
                    <template v-for="(row_col,index_col) in columns" :key="index_col">
                      <div class="border border-gray-200 font-extrabold w-6/12 text-left px-10">
                      {{row_col.name}}
                      </div>

                        <div v-if="row_col.key=='files'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                            <div class="mb-5  relative flex flex-wrap z-10">
                                <img src="https://res.cloudinary.com/dgzea9m7q/image/upload/v1656487048/software/icons8-plus-48.png" class="z-10 w-8 h-8 rounded-full border-2 " @click="$parent.uploadsel(row.id)">
                                <img v-for="(row_f,index) in row[row_col.key]" :key="index" :src="'https://ik.imagekit.io/popcing/'+row_f.name+'?t=50'" class="w-8 h-8 z-10 rounded-full border-2  ">
                            </div>
                        </div>
                        <div v-else-if="row_col.show&&row_col.key=='inventario'" :class="colores(row)+' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                            <span class="text-black  font-exdivabold text-xs px-3 rounded-lg py-1  text-center  items-center">
                                {{ CalcExistencias(row) }}
                            </span>
                        </div>
                        <div v-else-if="row_col.show&&row_col.key=='link'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                            <a v-if="row.link!=null" class="text-green-500   font-exdivabold text-xs px-3 rounded-lg py-1  text-center  items-center" target="_blank" :href="'http://localhost:8080/r?u='+row.id+'&code='+row.link">
                                /r?u={{row.id}}&code={{row.link}}
                            </a>
                            <span v-else>N/A</span>
                        </div>
                        <div v-else-if="row_col.show&&row_col.key=='color'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                            <span class="text-black w-12 h-12  font-exdivabold text-xs px-3 rounded-lg py-1  text-center  items-center" :style="'background:'+row.color">

                            </span>
                        </div>
                        <div v-else-if="row_col.show&&row_col.key=='url'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                            <div style="width:30px;height:30px;background-size:cover;" class="text-black   font-exdivabold text-xs px-3 rounded-lg py-1  text-center  items-center" :style="'background:url('+row.url+'?div=w-30)'" />

                        </div>
                        <div v-else-if="row_col.show&&row_col.key=='clientes'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                            <span v-if="row.clientes!=null" class=" w-12 h-12  font-exdivabold text-sm  rounded-lg   text-center  items-center">
                                {{row.clientes.name}}
                            </span>
                        </div>
                        <div v-else-if="row_col.show&&row_col.key=='usuario'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                            <span class=" w-12 h-12  font-exdivabold text-sm  rounded-lg  text-center  items-center">
                                {{row.usuario.name}}
                            </span>
                        </div>
                        <div v-else-if="row_col.show&&row_col.key=='ver_cotizacion'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">

                            <span v-if="row.status_verificar=='APROBADA'" class=" w-12 h-12  font-exdivabold bg-green-400 text-white cursor-pointer text-xs px-3 rounded-lg py-1  text-center  items-center">
                                {{row.status_verificar}}
                            </span>

                            <span v-else @click="$parent.verCotizacion(row)" class=" w-12 h-12  font-exdivabold text-blue-700 cursor-pointer text-sm px-3 rounded-lg py-1  text-center  items-center">
                                {{row_col.name}}
                            </span>
                        </div>

                        <div v-else-if="row_col.show&&row_col.key=='fecha_ago'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">

                            {{timeAgo(row.y+'-'+row.m+'-'+row.d+' '+row.t)}}

                        </div>

                        <div v-else-if="row_col.show&&row_col.key=='sucursal'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                            <span class=" w-12 h-12  font-exdivabold text-base px-3 rounded-lg py-1  text-center  items-center" :style="'color:'+row.sucursales.color">
                                {{row.sucursales.nombre}}
                            </span>
                        </div>

                        <div v-else-if="row_col.show&&row_col.key=='variante'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                            <span class="text-white bg-green-700 px-3 rounded-lg py-1  text-center  items-center" @click="$parent.addVariante(row)">
                                {{ row_col.name }}
                            </span>
                        </div>

                        <div v-else-if="row_col.show&&row_col.key=='status_verificar'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                            <a :class="colorVerificar(row.status_verificar)" class=" px-3 rounded-lg py-1  text-center  items-center cursor-pointer" :target="row.status_verificar!='APROBADA'?'_blank':''" :href="row.status_verificar!='APROBADA'?'/app/nueva/cotizacion?select='+row.id+'&a=produccion':'#'">
                                {{ row.status_verificar==null||row.status_verificar==''?'SIN VERIFICAR':row.status_verificar }}
                            </a>
                        </div>
                        <div v-else-if="row_col.show&&row_col.key=='status_instalacion'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                            <span :class="colorInstalacion(row.status_instalacion)" class="  px-3 rounded-lg py-1  text-center  items-center" @click="$parent.acceionInstalacion(row)">
                                {{ row.status_instalacion==null||row.status_instalacion==''?'SIN PROGRAMAR':row.status_instalacion }}

                            </span>
                        </div>
                        <div v-else-if="row_col.show&&row_col.key=='status_produccion'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                            <span :class="colorProduccion(row.status_produccion)" class=" px-3 rounded-lg py-1  text-center  items-center" @click="$parent.accionProduccion(row)">

                                {{ row.status_produccion==null||row.status_produccion==''?'PENDIENTE':row.status_produccion }}

                            </span>
                        </div>

                        <div v-else-if="row_col.show&&row_col.key=='saldo'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                            <a :href="'/app/cotizaciones?cid='+row.id" target="_blank" class="text-black font-exdivabold bg-yellow-500  px-3 rounded-lg py-1  text-center  items-center">
                                ${{formatPrice( calcularPendiente(row) )}}
                            </a>
                        </div>
                        <div v-else-if="row_col.show&&row_col.key=='total'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                            <span class="text-black font-exdivabold bg-green-500  px-3 rounded-lg py-1  text-center  items-center">
                                ${{formatPrice( row.total )}}
                            </span>
                        </div>
                        <div v-else-if="row_col.show&&row_col.key=='detalle_ventas'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                            <span class="text-white bg-green-700 px-3 rounded-lg py-1  text-center  items-center" @click="$parent.addVariante(row)">
                                {{ row.detalle_ventas.length }}
                            </span>
                        </div>

                        <div v-else-if="row_col.show&&(row_col.key=='tarjeta_debito' )" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                            <span class="text-black bg-yellow-500 px-3 rounded-lg py-1  text-xs font-bold  text-center  items-center" @click="$parent.addVariante(row)">

                                ${{ formatPrice(Number(row.tarjeta_debito)) }}
                            </span>
                        </div>
                        <div v-else-if="row_col.show&&(row_col.key=='efectivo')" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                            <span class="text-black bg-yellow-500 px-3 rounded-lg py-1  text-xs font-bold text-center  items-center" @click="$parent.addVariante(row)">
                                ${{ formatPrice(Number(row.efectivo)) }}
                            </span>
                        </div>
                        <div v-else-if="row_col.show&&(row_col.key=='tarjeta_credito' )" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                            <span class="text-black bg-yellow-500 px-3 rounded-lg py-1  text-xs font-bold  text-center  items-center" @click="$parent.addVariante(row)">
                                ${{ formatPrice(Number(row.tarjeta_credito)) }}
                            </span>
                        </div>
                        <div v-else-if="row_col.show&&row_col.key=='info'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                            <span class="text-white bg-pink-500 px-3 rounded-lg py-1  text-centerflex items-center" @click="$parent.infoModal(row)">
                                Informacion
                            </span>
                        </div>
                        <div v-else-if="row_col.show&&row_col.key=='eliminar'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                            <span class="text-white bg-red-700 px-3 rounded-lg py-1  text-centerflex items-center" @click="$parent.eliminar(row.id)">
                                Eliminar
                            </span>
                        </div>
                        <div v-else-if="row_col.show&&row_col.key=='edit'" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                            <span class="text-white bg-blue-700 px-3 rounded-lg py-1  text-centerflex items-center" @click="$parent.edit(row)">
                                Editar
                            </span>
                        </div>

                        <div v-else v-show="row_col.show&&!row_col.hasOwnProperty('children')" class="  userId" :class="' bg-'+(impar(index_col))+' '+class_general+' border-['+'#E8E8E8'+']'">
                            <span class="text-gray-700    text-center w-full  flex items-center" :class="row_col.class">
                                {{ row[row_col.key] }}
                            </span>
                        </div>
                    </template>
                </div>
                </template>
                </div>
            </div>

            <button v-if="add!=false" class="btn font-bold bg-blue-800 text-white text-3xl w-12 h-12 justify-center content-center flex flex-wrap fixed z-20  float-right m-r-xxs rounded-full fixed  bottom-5 right-5 " @click="$parent.addRow()">
                <vue-feather :type="'plus-circle'" size="24" class="self-center" :stroke="'white'" />
            </button>

            <button v-if="refr!=false" class="btn font-bold bg-yellow-300 text-white text-3xl w-12 h-12 justify-center content-center flex flex-wrap fixed bottom-5 z-20 right-20 float-right m-r-xxs rounded-full" @click="$parent.getData()">
                <vue-feather :type="'refresh-cw'" size="24" class="self-center" :stroke="'white'" />
            </button>
        </div>
    </div>
</div>
</template>

<script>
//@ts-ignore
import generales from '@/functions/generales'
//import palabras from "@/mixins/palabras";
export default {
    name: "Home",
    components: {

    },
    mixins: [generales],
    props: {

        showcols: {
            type: Boolean,
            default: true
        },
        coloresparam: {
            type: Array,

        },
        rows: {
            type: Array,

        },
        columns: {
            type: Array,

        },
        filtros: {
            type: Array,

        },
        page: {
            type: Object,

        },
        buscarp: {
            type: String,
            default: ''
        },
        buscador: {
            type: Boolean,
            default: true
        },
        refr: {
            type: Boolean,
            default: true
        },
        add: {
            type: Boolean,
            default: true
        },
        filtroApi: {
            type: Boolean,
            default: false
        },
        paginate: {
            type: Boolean,
            default: false
        },

    },
    data() {
        return {

            metodos: ['nada',
                'Efectivo',
                'T. Crédito',
                'T. Débito'
            ],

            showconfirm: false,
            buscar: '',
            buscarapi: '',
            show: false,
            open: false,
            class_general: ' font-sans tracking-wide normal-case    text-xs     border-b border-l '+(this.IsMov()?'w-6/12 text-left px-1 ':' px-3 py-1  ')
        }
    },

    mounted() {

        console.log('ss-----')
        console.log(this.columns)
        //this.formatrow();
        this.show = true;
        console.log(this.buscarp)
        if (this.buscarp != 'all') {
            console.log('entre--', this.buscarp)
            this.buscar = this.buscarp;
            this.buscarapi = this.buscarp;
        }

    },
    methods: {
      IsMov(){


        //return true;
        return this.$isMobile()
      },
        colorProduccion(status) {
            switch (status) {

                case 'EN PRODUCCION':
                    return 'bg-yellow-600 text-black font-extrabold';
                    break;
                case 'TERMINADO':
                    return 'bg-green-500 text-black font-extrabold';
                    break;

                default:
                    return 'bg-red-500 text-black font-extrabold';
                    break;
            }
        },
        colorInstalacion(status) {
            switch (status) {

                case 'INCOMPLETO':
                    return 'bg-orange-500 text-black font-extrabold';
                    break;
                case 'INSTALADO':
                    return 'bg-green-500 text-black font-extrabold';
                    break;

                default:
                    return 'bg-red-500 text-black font-extrabold';
                    break;
            }
        },
        colorVerificar(status) {

            switch (status) {
                case 'PENDIENTE DE VERIFICAR':
                    return 'bg-yellow-500 text-black font-extrabold';
                    break;
                case 'RECHAZADA':
                    return 'bg-red-500 text-black font-extrabold';
                    break;
                case 'APROBADA':
                    return 'bg-green-500 text-black font-extrabold';
                    break;

                default:
                    return 'bg-blue-500 text-black font-extrabold';
                    break;
            }
        },
        impar(numero) {
          //?'':'white'
            if (numero % 2 == 0)

            {

                return 'gray-400 bg-opacity-10';

            } else {
                return 'white';
            }

        },
        colores(row) {
            let color = 'bg-gray-100';
            console.log(this.coloresparam)
            if (this.coloresparam != null && this.coloresparam.length > 0) {
                //let parametro=this.coloresparam[0];
                let casos = this.coloresparam;

                casos.forEach(element => {

                    if (this.CalcExistencias(row) <= element.precio) {
                        color = element.color;
                    }
                });

                return color;

            } else {
                return color;
            }
        },
        CalcExistencias(row) {
            let total = 0;
            let total_vendidos = 0;
            row.existencias.forEach(element => {
                total = Number(total) + element.cantidad
            });
            row.detalle_ventas.forEach(element => {
                total_vendidos = Number(total_vendidos) + element.cantidad
            });
            return total - total_vendidos
        },
        calcularPendiente(row) {
            let tot = 0;
            row.abonos.forEach(element => {
                tot = Number(tot) + Number(element.capital)
            });
            return Number(row.total) - Number(tot);
        },
        getColumnsContain(key) {
            let c = 0;
            this.columns.forEach(element => {
                if (key.includes(element.key)) {
                    c = c + 1
                }
            });
            if (c > 0) {
                return true
            } else {
                return false;
            }
        },
        getColumns() {
            let col = 0;
            this.columns.forEach(element => {
                if (element.show && !element.hasOwnProperty('children')) {
                    col = col + 1
                }
            });
            return col;
        },
        verificarTipo(tipo) {
            switch (tipo) {
                case 'distancia_permitir':
                    return 'Permite estas Cordenadas'
                    break;
                case 'distancia_bloquear':
                    return 'Bloquea estas Cordenadas'
                    break;
                case 'bloqueo_hora':
                    return 'Bloquea por horas'
                    break;

                default:
                    break;
            }
        },
        buscadorpropiedades(row) {
            let mostrar = 0;

            if (this.buscador == false) {
                return true;
            }
            if (this.buscar == '') {
                return true;
            }

            /*
             Array(this.filtros).forEach(element => {
             
                     //se busca por esta columna
                     if (this.columns[element].search(this.buscar) != -1) {
                         //si contiene si muestra
                     } else {
                         //no contiene no muestra
                         ocultar =ocultar+ 1;
                     }
               
             });*/
            this.columns.forEach(element => {

                if ((this.filtros).includes(element.key)) {
                    //console.log(' filtrar',element.key,row[element.key])
                    //console.log(String(row[element.key]))
                    //se busca por esta columna

                    if (String(row[element.key]).toUpperCase().search(String(this.buscar).toUpperCase()) != -1) {
                        //si contiene si muestra
                        mostrar = mostrar + 1;
                        // console.log('si muestra',this.buscar,row[element.data])
                    } else {
                        //console.log('no muestra',this.buscar,row[element.data])
                        //no contiene no muestra

                    }
                } else {
                    //console.log('no filtrar',[element.data],this.filtros)
                    //no se busca por esta columna
                }
            });
            if (mostrar > 0) {
                return true
            } else {
                return false;
            }

        },
        formatDat(date) {
            var options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            };
            let dat = new Date(date);
            return dat.toLocaleDateString("es-ES", options)
        },
        getExistTag(prop) {
            let valores = this.columns.filter((row) => row.key == prop)
            if (valores.length > 0) {
                return true
            } else {
                return false;
            }
        },
        formatrow() {
            let data = [];
            let labels = this.es.table;
            //console.log(labels);
            this.columns.forEach(element => {

                data.push({
                    label: labels[element.tag],
                    field: element.data,
                    filterOptions: {
                        enabled: element.active, // enable filter for this column
                        placeholder: 'Buscar', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        //filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        //filterFn: this.columnFilterFn, //custom filter function that
                        trigger: 'keyup', //only trigger on enter not on keyup 
                    }
                });

            })
            return data;
        }
    }
}
</script>

<style>
td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.vgt-wrap {
    height: 100%;
}

.vgt-inner-wrap {
    height: 100%;
}
</style>
