

import initmixin from "@/Services/initmixin";

export default {
 
  //mixins: [initmixin],
  
  GET_ACCOUNTS_CONNECT: async function( data) {
    let url='/usuarios/social/accounts'
    return initmixin.formatrespuesta('get', data,url,true)
  },
  GET_DEMOGRAFIC: async function( data) {
    let url='/usuarios/social/demografic/'+data.id
    return initmixin.formatrespuesta('get', data,url,true)
  },

  TOKEN_PHY: async function( data) {
    let url='/usuarios/ptoken'
    return initmixin.formatrespuesta('post', data,url,true)
  },

  CONECTAR_PHY: async function( data) {
    let url='/usuarios/pconect'
    return initmixin.formatrespuesta('post', data,url,true)
  },
  GET_ACOUNT: async function( data){
    let url='/acount/'+data.username
    return initmixin.formatrespuesta('get', data,url)
  },
    LOGIN_USER: async function( data){
      let url='/login/'
      return initmixin.formatrespuesta('post', data,url)
    },
    REGISTRO_USER: async function( data) {
      let url='/user/'
      return initmixin.formatrespuesta('post', data,url)
    },
    
    
    REGISTRO_CLIENTE: async function(data) {
      let url='/registro/'
      return initmixin.formatrespuesta('post', data,url)
    },
     
    LINK_CLIENTE: async function(data) {
      let url='/link/'
      return initmixin.formatrespuesta('post', data,url)
    },
    GET_PERFIL: async function( ) {
      let url='/perfil/'
      return initmixin.formatrespuesta('get',[],url,true)
    },
    
    GET_LINK: async function( data){
      let url='/usuarios/link'
      return initmixin.formatrespuesta('get', data,url,true)
    },
    GET: async function( data){
      let url='/usuarios/'
      return initmixin.formatrespuesta('get', data,url,true)
    },
    GET_CLIENTES: async function( data){
      let url='/usuarios/clientes/page/'+data.page+'/limit/'+data.limit+'/search/'+data.search
      return initmixin.formatrespuesta('get', data.data,url,true)
    },
    CREAR: async function( data) {
      let url='/usuarios/'
      return initmixin.formatrespuesta('post', data,url,true)
    },
    EDITAR: async function( data) {
      let url='/usuarios/'+data.id
      return initmixin.formatrespuesta('put', data,url,true)
    },
    EDITAR_APARIENCIA: async function( data) {
      let url='/usuarios/apariencia/'+data.id
      return initmixin.formatrespuesta('post', data,url,true)
    },
    IMAGEN: async function( data) {
      let url='/usuarios/imagen/'+data.id
      return initmixin.formatrespuesta('post', data.data,url,true)
    },

    DESCRIPCION: async function( data) {
      let url='/usuarios/descripcion/'
      return initmixin.formatrespuesta('put', data,url,true)
    },
    MERCADO: async function( data) {
      let url='/usuarios/mercado/'
      return initmixin.formatrespuesta('put', data,url,true)
    },
    USERNAME: async function( data) {
      let url='/usuarios/username/'
      return initmixin.formatrespuesta('put', data,url,true)
    },
    
    CATEGORIA: async function( data) {
      let url='/usuarios/categoria/'
      return initmixin.formatrespuesta('post', data,url,true)
    },
    ELIMINAR: async function( data) {
      let url='/usuarios/'+data.id
      return initmixin.formatrespuesta('delete', data,url,true)
    },




}