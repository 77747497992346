<template>
<div class="w-full h-screen flex flex-wrap translated-ltr">
    <Transition enter-active-class="transition duration-300 ease-in-out" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition duration-300 ease-in-out" leave-from-start="opacity-100" leave-to-class="opacity-0">
        <div class="h-16 bg-white sm:h-16 md:h-full xl:h-full lg:h-full w-full sm:w-full fixed md:w-16 xl:w-16 lg:w-16 border-r-2 border-gray-200 ">
            
            <div v-if="info.hasOwnProperty('logo_favicon')"  class="flex flex-wrap w-full justify-center py-3">
                <img class="w-10 h-10 rounded bg-pink-300" :class="info.color_logo" :src="info.logo_favicon" :alt="info.nombre" />
            </div>
        </div>
    </Transition>
            <main class="w-full h-full overflow-hidden mt-16 sm:mt-16 md:mt-0 lg:mt-0 xl:mt-0">

                <router-view />

            </main>

            <!-- Panels -->

            <!-- Settings Panel -->
            <!-- Backdrop -->

          
            <!-- Panel -->

            <!-- Search panel -->
          

  
    

</div>
</template>

<script>
import userService from "@/Services/user";

import {
    useUserStore
} from '../stores/user'
import {
    useConfigStore
} from '../stores/config'
import {
    useInfoStore
} from '../stores/info'

import infosistem from "@/Services/infosistem";
import {
    useRouter,
    useRoute
} from 'vue-router'
export default {
    name: "Root",
    components: {

    },
    mixins: [infosistem],
    data() {
        const getTheme = () => {
            if (window.localStorage.getItem('dark')) {
                return JSON.parse(window.localStorage.getItem('dark'))
            }else{
                return false;
            }
            return !!window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
        }

        const setTheme = (value) => {
            window.localStorage.setItem('dark', false)
        }

        return {
            loading: true,
           
        }
    },
    setup() {
        const router = useRouter()
        const route = useRoute()
        const user = useUserStore()
        const info = useInfoStore()

        const config = useConfigStore();

     
        const salir=()=>{
            user.limpiar([]);
            router.push('/');
        }
        return {
            active: 0,
            config,
            info: info.getAll(),
            infoset: info,
            user: user,
          
            salir
            

        }

    },
    created() {
       

    },
    methods: {

    
        showMenu1(id) {
            let actual = false;
            if ($('#' + id).hasClass('hidden')) {
                actual = true;
            }

            $('.genmen').removeClass('hidden');

            $('.genmen').addClass('hidden');
            // let menu1 = document.getElementById(id);
            let icon1 = document.getElementById("icon" + id);
            let menu1 = document.getElementById(id);
            if (actual) {

                $('#' + id).removeClass('hidden');
                //$('#icon'+id).addClass('hidden');
                //$('#'+id).addClass('hidden');
            } else {

                //$('#'+id).removeClass('hidden');
                //$('#icon'+id).addClass('hidden');
                $('#' + id).addClass('hidden');
            }
            icon1.classList.toggle("rotate-180");
        },

        showMenu2(flag) {
            let menu2 = document.getElementById("menu2");

            if (flag) {
                icon2.classList.toggle("rotate-180");
                menu2.classList.toggle("hidden");
            }
        },

        showMenu3(flag) {
            let menu3 = document.getElementById("menu3");
            if (flag) {
                icon3.classList.toggle("rotate-180");
                menu3.classList.toggle("hidden");
            }
        },
        years() {
            const year = new Date().getFullYear()
            return Array.from({
                length: year - 1900
            }, (value, index) => 1901 + index)
        },
        validateEmail(value) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
                this.msg['email'] = '';
            } else {
                this.msg['email'] = 'Invalid Email Address';
            }
        },

    }
}
</script>

<style>
/* This example part of kwd-dashboard see https://kamona-wd.github.io/kwd-dashboard/ */
/* So here we will write some classes to simulate dark mode and some of tailwind css config in our project */
:root {
    --light: #edf2f9;
    --dark: #152e4d;
    --darker: #12263f;
}

.dark .dark\:text-light {
    color: var(--light);
}

.dark .dark\:bg-dark {
    background-color: var(--dark);
}

.dark .dark\:bg-darker {
    background-color: var(--darker);
}

.dark .dark\:text-gray-300 {
    color: #d1d5db;
}

.dark .dark\:text-gray-500 {
    color: #6366f1;
}

.dark .dark\:text-gray-100 {
    color: #e0e7ff;
}

.dark .dark\:hover\:text-light:hover {
    color: var(--light);
}

.dark .dark\:border-gray-800 {
    border-color: #3730a3;
}

.dark .dark\:border-gray-700 {
    border-color: #4338ca;
}

.dark .dark\:bg-gray-600 {
    background-color: #4f46e5;
}

.dark .dark\:hover\:bg-gray-600:hover {
    background-color: #4f46e5;
}

.dark .dark\:border-gray-500 {
    border-color: #6366f1;
}

.hover\:overflow-y-auto:hover {
    overflow-y: auto;
}
</style>
