

import initmixin from "@/Services/initmixin";

export default {
 
  //mixins: [initmixin],
 // GET_CLIENTES CREAR_CLIENTES EDITAR_CLIENTES ELIMINAR_CLIENTES
 GET: async function( data){
  let url='/links/'
  return initmixin.formatrespuesta('get', data,url,true)
},
CREAR: async function( data) {
  let url='/links/'
  return initmixin.formatrespuesta('post', data,url,true)
},
REORDENAR: async function( data) {
  let url='/links/reordenar'
  return initmixin.formatrespuesta('post', data,url,true)
},

IMAGEN: async function( data) {
  let url='/links/imagen/'+data.id
  return initmixin.formatrespuesta('post', data.data,url,true)
},
EDITAR: async function( data) {
  let url='/links/'+data.id
  return initmixin.formatrespuesta('put', data,url,true)
},
ELIMINAR: async function( data) {
  let url='/links/'+data.id
  return initmixin.formatrespuesta('delete', data,url,true)
},

}