<template>
<div class=" h-full bg-white w-full">
    <loading :active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage" />
    <div v-if="showmodal" style="z-index:9999999999" class="bg-black bg-opacity-50  h-screen w-full flex content-center justify-center items-center fixed  top-0 right-0 bottom-0 left-0">
        <div :class="{'bg-white':!statusal,'bg-green-100':statusal}" class=" w-80 min-h-72 self-center  flex-wrap px-16 py-14 rounded-md text-start flex content-center justify-center items-center">
            <div class="text-base mb-4 font-bold text-slate-500 w-full" v-html="mensaje" />
            <button class="bg-black px-4 py-2 rounded-md text-md text-white" @click="showmodal=false">
                Ok
            </button>
        </div>
    </div>
    <router-view />
</div>
</template>

<script>
//import { MagicStick } from '@element-plus/icons'
import {
    emitter
} from "./mitt.js";
import {
    useUserStore
} from './stores/user'
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import {
    useInfoStore
} from './stores/info'

import infosistem from "@/Services/infosistem";
export default {
    components: {
        Loading
    },
    data() {
        const info = useInfoStore()
        const user = useUserStore()
        // const config = useConfigStore()

        return {
            user,
            //config,
            isLoading: false,
            fullPage: true,
            infoset: info,
            showmodal: false,
            mensaje: '',
            statusal: false
        }

    },
     created() {

      this.get_info();

        console.log(document)
        //document.zoom = .8;
        var th = this;
        emitter.on('loading', (number) => {
                console.log(number)
                this.doAjax(number);
            }

        );
        emitter.on('alerta_show', (msg) => {
            console.log(msg)
            this.show_alert(msg);

        });
        emitter.on('success', (msg) => {

            this.success(msg);

        });

        this.user.$subscribe((mutation, state) => {
            // import { MutationType } from 'pinia'
            //mutation.type // 'direct' | 'patch object' | 'patch function'
            // same as cartStore.$id
            //mutation.storeId // 'cart'
            // only available with mutation.type === 'patch object'
            //mutation.payload // patch object passed to cartStore.$patch()

            // persist the whole state to the local storage whenever it changes
            localStorage.setItem('user', JSON.stringify(state))
        })

    },
    methods: {
        async get_info() {
           // emitter.emit("loading", true)

            let data = await infosistem.GET().then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    let infov = {};
                    response.forEach(element => {
                        infov[element.key] = element.valor;
                    });
                    this.infoset.set(
                        infov
                    );
                    //infoData.value=(infov);
                    //if(response.user.role=='admin'){

                    //this.$router.push('/app/'); 
                    //}

                }
                //emitter.emit("loading", false)
            });
        },
        mounted() {
        
            $(document).bind('focus', function (e) {
                e.preventDefault();
            });
            let element = document.getElementById('app');
            let scale = 1.0;
            element.addEventListener('wheel', (ev) => {
                // This is crucial. Without it, the browser will do a full page zoom
                ev.preventDefault();

                // This is an empirically determined heuristic.
                // Unfortunately I don't know of any way to do this better.
                // Typical deltaY values from a trackpad pinch are under 1.0
                // Typical deltaY values from a mouse wheel are more than 100.
                let isPinch = Math.abs(ev.deltaY) < 50;

                if (isPinch) {
                    // This is a pinch on a trackpad
                    let factor = 1 - 0.01 * ev.deltaY;
                    scale *= factor;
                    element.innerText = `Pinch: scale is ${scale}`;
                } else {
                    // This is a mouse wheel
                    let strength = 1.4;
                    let factor = ev.deltaY < 0 ? strength : 1.0 / strength;
                    scale *= factor;
                    element.innerText = `Mouse: scale is ${scale}`;
                }
            });

        },
        doAjax(status) {
            this.isLoading = status;

        },
        show_alert(msg) {
            this.mensaje = msg;
            this.statusal = false;
            this.showmodal = true;
        },
        success(msg) {
            this.mensaje = msg;
            this.statusal = true;
            this.showmodal = true;
        },
    }
}
</script>

<style>
#app {
  font-family: 'Raleway', sans-serif!important;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    padding: 0;
    margin: 0;
}

/*
shadowc-gray-50
red
yellow
green
blockquoteindigo
purple
pink*/
/*botones sombra*/



.shadowc-gray-50{
  box-shadow: 3px 3px #f9fafb;
}
.shadowc-gray-100{
  box-shadow: 3px 3px #f3f4f6;
}
.shadowc-gray-200{
  box-shadow: 3px 3px #e5e7eb;
}
.shadowc-gray-300{
  box-shadow: 3px 3px #d1d5db;
}
.shadowc-gray-400{
  box-shadow: 3px 3px #9ca3af;
}
.shadowc-gray-500{
  box-shadow: 3px 3px #6b7280;
}
.shadowc-gray-600{
  box-shadow: 3px 3px #4b5563;
}

.shadowc-gray-700{
  box-shadow: 3px 3px #374151;
}
.shadowc-gray-800{
  box-shadow: 3px 3px #1f2937 ;
}
.shadowc-gray-900{
  box-shadow: 3px 3px #111827;
}





.shadowc-red-50{
  box-shadow: 3px 3px #fef2f2;
}
.shadowc-red-100{
  box-shadow: 3px 3px #fee2e2;
}
.shadowc-red-200{
  box-shadow: 3px 3px #fecaca;
}
.shadowc-red-300{
  box-shadow: 3px 3px #fca5a5;
}
.shadowc-red-400{
  box-shadow: 3px 3px #f87171;
}
.shadowc-red-500{
  box-shadow: 3px 3px #ef4444;
}
.shadowc-red-600{
  box-shadow: 3px 3px #dc2626;
}

.shadowc-red-700{
  box-shadow: 3px 3px #b91c1c;
}
.shadowc-red-800{
  box-shadow: 3px 3px #991b1b;
}
.shadowc-red-900{
  box-shadow: 3px 3px #7f1d1d;
}












.shadowc-yellow-50{
  box-shadow: 3px 3px #fefce8;
}
.shadowc-yellow-100{
  box-shadow: 3px 3px #fef9c3;
}
.shadowc-yellow-200{
  box-shadow: 3px 3px #fef08a;
}
.shadowc-yellow-300{
  box-shadow: 3px 3px #fde047;
}
.shadowc-yellow-400{
  box-shadow: 3px 3px #facc15;
}
.shadowc-yellow-500{
  box-shadow: 3px 3px #eab308;
}
.shadowc-yellow-600{
  box-shadow: 3px 3px #ca8a04;
}

.shadowc-yellow-700{
  box-shadow: 3px 3px #a16207;
}
.shadowc-yellow-800{
  box-shadow: 3px 3px #854d0e;
}
.shadowc-yellow-900{
  box-shadow: 3px 3px #713f12;
}
















.shadowc-green-50{
  box-shadow: 3px 3px #f0fdf4;
}
.shadowc-green-100{
  box-shadow: 3px 3px #dcfce7;
}
.shadowc-green-200{
  box-shadow: 3px 3px #bbf7d0;
}
.shadowc-green-300{
  box-shadow: 3px 3px #86efac;
}
.shadowc-green-400{
  box-shadow: 3px 3px #4ade80;
}
.shadowc-green-500{
  box-shadow: 3px 3px #22c55e;
}
.shadowc-green-600{
  box-shadow: 3px 3px #16a34a;
}

.shadowc-green-700{
  box-shadow: 3px 3px #047857;
}
.shadowc-green-800{
  box-shadow: 3px 3px #065f46;
}
.shadowc-green-900{
  box-shadow: 3px 3px #064e3b;
}


















.shadowc-blue-50{
  box-shadow: 3px 3px #eff6ff;
}
.shadowc-blue-100{
  box-shadow: 3px 3px #dbeafe;
}
.shadowc-blue-200{
  box-shadow: 3px 3px #bfdbfe;
}
.shadowc-blue-300{
  box-shadow: 3px 3px #93c5fd;
}
.shadowc-blue-400{
  box-shadow: 3px 3px #60a5fa;
}
.shadowc-blue-500{
  box-shadow: 3px 3px #3b82f6;
}
.shadowc-blue-600{
  box-shadow: 3px 3px #2563eb;
}

.shadowc-blue-700{
  box-shadow: 3px 3px #1d4ed8;
}
.shadowc-blue-800{
  box-shadow: 3px 3px #1e40af;
}
.shadowc-blue-900{
  box-shadow: 3px 3px #1e3a8a;
}



















.shadowc-indigo-50{
  box-shadow: 3px 3px #eef2ff;
}
.shadowc-indigo-100{
  box-shadow: 3px 3px #e0e7ff;
}
.shadowc-indigo-200{
  box-shadow: 3px 3px #c7d2fe;
}
.shadowc-indigo-300{
  box-shadow: 3px 3px #a5b4fc;
}
.shadowc-indigo-400{
  box-shadow: 3px 3px #818cf8;
}
.shadowc-indigo-500{
  box-shadow: 3px 3px #6366f1;
}
.shadowc-indigo-600{
  box-shadow: 3px 3px #4f46e5;
}

.shadowc-indigo-700{
  box-shadow: 3px 3px #4338ca;
}
.shadowc-indigo-800{
  box-shadow: 3px 3px #3730a3;
}
.shadowc-indigo-900{
  box-shadow: 3px 3px #312e81;
}







































.shadowc-purple-50{
  box-shadow: 3px 3px #faf5ff;
}
.shadowc-purple-100{
  box-shadow: 3px 3px #f3e8ff;
}
.shadowc-purple-200{
  box-shadow: 3px 3px #e9d5ff;
}
.shadowc-purple-300{
  box-shadow: 3px 3px #d8b4fe;
}
.shadowc-purple-400{
  box-shadow: 3px 3px #d8b4fe;
}
.shadowc-purple-500{
  box-shadow: 3px 3px #a855f7;
}
.shadowc-purple-600{
  box-shadow: 3px 3px #9333ea;
}

.shadowc-purple-700{
  box-shadow: 3px 3px #7e22ce;
}
.shadowc-purple-800{
  box-shadow: 3px 3px #6b21a8;
}
.shadowc-purple-900{
  box-shadow: 3px 3px #581c87;
}





























.shadowc-pink-50{
  box-shadow: 3px 3px #fdf2f8;
}
.shadowc-pink-100{
  box-shadow: 3px 3px #fce7f3;
}
.shadowc-pink-200{
  box-shadow: 3px 3px #fbcfe8;
}
.shadowc-pink-300{
  box-shadow: 3px 3px #f9a8d4;
}
.shadowc-pink-400{
  box-shadow: 3px 3px #f472b6;
}
.shadowc-pink-500{
  box-shadow: 3px 3px #ec4899;
}
.shadowc-pink-600{
  box-shadow: 3px 3px #db2777;
}

.shadowc-pink-700{
  box-shadow: 3px 3px #be185d;
}
.shadowc-pink-800{
  box-shadow: 3px 3px #9d174d;
}
.shadowc-pink-900{
  box-shadow: 3px 3px #831843;
}



.mobile-illustration{
  display:none!important
}
.shadow0{
  box-shadow: 0px 0px!important;
}
.shadow1 {
  box-shadow: 3px 3px;
  transition: all ease-in .05s;
}
</style>
